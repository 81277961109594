import React, { useEffect, useState } from 'react';
import '../App.css'; // You can import your CSS file here
import { FaGavel, FaLock, FaPowerOff, FaTachometerAlt, FaThumbsUp, FaUserAlt, FaUsers } from "react-icons/fa";
import { CgNotes } from "react-icons/cg";
import { Link, useNavigate } from 'react-router-dom';
import { BsBox2Fill, BsSaveFill, BsSearch } from 'react-icons/bs';
import { MdAllInbox } from 'react-icons/md';
import Swal from 'sweetalert2';
import { LOGOUT_URL } from '../Config/Constants';
import axios from 'axios';
function RecruiterSidebar() {

    const [activeLink, setActiveLink] = useState('');

    useEffect(() => {
        // Get the current URL and set the active link based on it
        setActiveLink(window.location.pathname);
    }, []);




    const navigate = useNavigate();
    

      const handleLogout = async () => {

        const token = localStorage.getItem("token")

        try {
            const Response = await axios({
                method: "post",
                url: `${LOGOUT_URL}`,
                headers: {
                    Authorization:token,
                    
                },
               
            })

            console.log("logout",Response.data);
           
        
          
          if (Response.data.success == true) {
           
            localStorage.removeItem('token');
            localStorage.removeItem("alti_type")

            navigate("/")

            Swal.fire("Success", "Logged Out successfully!!")

            setTimeout(() => {
                Swal.close();
    
            }, 1500);

          } 
        } catch (error) {
          
          console.log('Error while logging out recruiter', error);
        }
      };


    return (

        <div>
            <div class="dashboard-left">
                <ul class="dashboard-menu">
                    <li className={activeLink === '/recruiter/dashboard' ? 'active' : ''}>
                        <a href="/recruiter/dashboard">

                            <FaTachometerAlt class="tachometer"></FaTachometerAlt>
                            Dashboard
                        </a>
                    </li>
                    {/* <li className={activeLink === '/recruiter_dashboard' ? 'active' : ''}>
                        <FaTachometerAlt class="tachometer"></FaTachometerAlt>
                        <Link to="/recruiter_dashboard">Dashboard</Link>
                    </li> */}


                    <li className={activeLink === '/recruiter/profile' ? 'active' : ''}><Link to="/recruiter/profile">  <FaUsers class="tachometer"></FaUsers>Company Profile</Link></li>
                    <li className={activeLink === '/recruiter/postjobs' ? 'active' : ''}><Link to="/recruiter/postjobs">  <MdAllInbox class="tachometer"></MdAllInbox>Post Job</Link></li>
                    <li className={activeLink === '/recruiter/applications' ? 'active' : ''}><Link to="/recruiter/applications">  <CgNotes class="tachometer"></CgNotes>Manage Jobs</Link></li>
                    <li className={activeLink === '/recruiter/search_candidates' ? 'active' : ''}><Link to="/recruiter/search_candidates">  <BsSearch class="tachometer"></BsSearch>Search Candidate</Link></li>
                    <li className={activeLink === 'recruiter/changepassword' ? 'active' : ''}><Link to="/recruiter_changepassword">  <FaLock class="tachometer"></FaLock>Change Password</Link></li>

                    <li className={activeLink === '/recruiter/manageapp' ? 'active' : ''}><Link to="/recruiter/manageapp?">  <BsBox2Fill class="tachometer"></BsBox2Fill>Manage Application</Link></li>


                    <li><a class="tachometer1"  onClick={handleLogout}  ><FaPowerOff class="tachometer"></FaPowerOff>LogOut</a></li>
                </ul>
            </div>



        </div>
// onClick={() => Logout()}  
    )

}


export default RecruiterSidebar;
