import "../../App.css"; // You can import your CSS file here
import {
  FaGavel,
  FaTachometerAlt,
  FaThumbsUp,
  FaUserAlt,
  FaUsers,
} from "react-icons/fa";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import CandidateHeader from "../../Components/candidateheader";
import CandidateSidebar from "../../Components/candidateSidebar";
import Footer from "../../Components/footer";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { REGISTER_URL, USERTYPE_URL, STATE_URL } from "../../Config/Constants";

function Register() {
  const navigate = useNavigate();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  // const [states, setStates] = useState([]);
  const [formData, setFormData] = useState({
    user_name: "",
    email: "",
    phone_no: "",
    password: "",
    state_id: "",
    city_id: "",
    user_type_id: "",
    status: "",
    password_confirmation: "",
    captcha: false
  });

  const handleInputChange = (key, e) => {

    if (key == "captcha") {

      setFormData({
        ...formData,
        [key]: formData.captcha ? false : true,
      });

    }
    else {
      const value = e.target.value;

      console.log("valll", value)
      setFormData({
        ...formData,
        [key]: value,
      });
    }
  };

  const [userTypes, setUserTypes] = useState([]);
  const [errorMessages, setErrorMessages] = useState({
    password: "",
    email: "",
    user_name: "",
    user_type_id: "",
    state_id: "",
    city_id: "",
    phone_no: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${REGISTER_URL}`, formData);
      if (response.status === 200) {


        console.log("Registration successful");
        navigate("/login");
        Swal.fire("Success!", "Registration successful!", "success");

        setTimeout(() => {
          Swal.close();
        }, 1500);
      } else {
        console.error("Registration failed");
      }
    } catch (error) {
      if (error.response) {
        const responseError = error.response.data;
        if (responseError.errors) {
          setErrorMessages(responseError.errors);
        } else {
          console.error("An error occurred during registration:", error);
        }
      }
    }
  };

  useEffect(() => {
    async function fetchUserTypes() {
      try {
        const response = await axios.get(`${USERTYPE_URL}`);
        if (response.data.success) {
          setUserTypes(response.data.userTypeList);
        } else {
          console.error("Failed to fetch user types");
        }
      } catch (error) {
        console.error("An error occurred while fetching user types:", error);
      }
    }
    fetchUserTypes();
  }, []);


  useEffect(() => {
    const stateData = async () => {
      try {
        // Fetch states data
        const response = await axios({
          method: "get",
          url: `${STATE_URL}`,

        })
        setStates(response.data.states);
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };

    stateData();
  }, []);
  const handleInputChange1 = async (field, event) => {
    const value = event.target.value;
    setFormData((prevData) => ({ ...prevData, [field]: value }));
    setErrorMessages((prevErrors) => ({ ...prevErrors, [field]: "" }));

    if (field === "state_id") {
      try {
        // Fetch cities data based on selected state
        const response = await axios({
          method: "get",
          url: (` https://api.altimatixjobs.ifinitesolutions.com/api/states/${value}/cities`)
        })
        setCities(response.data);
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    }
  };
  return (
    <div>
      {/* <!-- Header Area Start --> */}
      <CandidateHeader />
      {/* <!-- Header Area End --> */}

      {/* <!-- Breadcromb Area Start --> */}
      <section className="jobguru-breadcromb-area">
        <div className="breadcromb-top section_100">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="breadcromb-box">
                  <h3>Register</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="breadcromb-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="breadcromb-box-pagin">
                  <ul>
                    <li>
                      <Link to="/">home</Link>
                    </li>

                    <li className="active-breadcromb">
                      <Link to="/register">Register</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Breadcromb Area End --> */}

      {/* <!-- Login Area Start --> */}
      <section className="jobguru-login-area section_70">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 offset-lg-4 col-sm-6 offset-sm-3">
              <div className="login-box">
                <div className="login-title">
                  <h3>Sign up</h3>
                </div>
                <form onSubmit={(e) => handleSubmit(e)}>
                  <div className="single-login-field">
                    <select
                      className="city-style1 single-login-field-User"
                      name="user_type_id"
                      value={formData.user_type_id}
                      onChange={(e) => handleInputChange("user_type_id", e)}
                    >
                      <option value="">-User Type-</option>
                      {userTypes.map((userType) => (
                        <option key={userType.id} value={userType.id}>
                          {userType.type}
                        </option>
                      ))}
                    </select>
                    {errorMessages.user_type_id && (
                      <div className="error-message">
                        {errorMessages.user_type_id}
                      </div>
                    )}
                  </div>
                  <div className="single-login-field">
                    <input
                      type="text"
                      name="user_name"
                      placeholder="Full Name"
                      value={formData.user_name}
                      onChange={(e) => handleInputChange("user_name", e)}
                    />
                  </div>
                  {errorMessages.user_name && (
                    <div className="error-message">
                      {errorMessages.user_name}
                    </div>
                  )}
                  <div className="single-login-field">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      value={formData.email}
                      onChange={(e) => handleInputChange("email", e)}
                    />
                    {errorMessages.email && (
                      <div className="error-message">{errorMessages.email}</div>
                    )}
                  </div>
                  <div className="single-login-field">
                    <input
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={formData.password}
                      onChange={(e) => handleInputChange("password", e)}
                    />
                  </div>
                  {errorMessages.password && (
                    <div className="error-message">
                      {errorMessages.password}
                    </div>
                  )}
                  <div className="single-login-field">
                    <input
                      type="password"
                      name="password_confirmation"
                      placeholder="Confirm Password"
                      value={formData.password_confirmation}
                      onChange={(e) =>
                        handleInputChange("password_confirmation", e)
                      }
                    />
                    {errorMessages.password_confirmation && (
                      <div className="error-message">
                        {errorMessages.password_confirmation}
                      </div>
                    )}
                  </div>
                  <div className="single-login-field">
                    <input
                      type="phone_no"
                      name="phone_no"
                      placeholder="Phone_no"
                      maxLength={10}
                      value={formData.phone_no}
                      onChange={(e) => handleInputChange("phone_no", e)}
                    />
                    {errorMessages.phone_no && (
                      <div className="error-message">
                        {errorMessages.phone_no}
                      </div>
                    )}
                  </div>
                  <div>
                    <select
                      className="city-style1  single-login-field-state "
                      name="state"
                      value={formData.state_id}
                      onChange={(e) => handleInputChange1("state_id", e)}
                    >
                      <option value="-Select State-" className="state-dropdown">
                        -Select State-
                      </option>

                      {states.map((state) => (
                        <option key={state.id} value={state.id}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                    {errorMessages.state_id && (
                      <div className="error-message">
                        {errorMessages.state_id}
                      </div>
                    )}
                  </div>
                  <div>
                    <select
                      className="city-style1  single-login-field-City "
                      name="city"
                      value={formData.city_id}
                      onChange={(e) => handleInputChange1("city_id", e)}
                    >
                      <option value="-Select State-" className="state-dropdown">
                        -Select City-
                      </option>

                      {cities.map((city) => (
                        <option key={city.id} value={city.id}>
                          {city.name}
                        </option>
                      ))}
                    </select>
                    {errorMessages.city_id && (
                      <div className="error-message">
                        {errorMessages.city_id}
                      </div>
                    )}
                  </div>
                  <div className="remember-row single-login-field clearfix">
                    <p className="checkbox remember">
                      <input
                        className="checkbox-spin"
                        type="checkbox"
                        id="Freelance"
                        name="status"
                        checked={formData.captcha}
                        onChange={(e) => handleInputChange("captcha", e)}
                      />

                      <label htmlFor="Freelance" className="terms-register">
                        <span></span>accept terms & condition
                      </label>
                      {errorMessages.captcha && (
                        <div className="error-message">
                          {errorMessages.captcha}
                        </div>
                      )}
                    </p>
                  </div>
                  <div className="single-login-field">
                    <button type="submit">Sign up</button>
                  </div>
                </form>
                {/* <form>
                                    <div class="">

                                        <select class='city-style1  single-login-field-User '>
                                        <option value="-User Type-">-User Type-</option>
                                            <option value="">I am jobseeker looking for job</option>
                                            <option value="">I'm an employer looking to hire</option>
                                            
                                        </select>
                                    </div>
                                    <div class="single-login-field">
                                        <input type="text" placeholder="Full Name" />
                                    </div>
                                    <div class="single-login-field">
                                        <input type="email" placeholder="Email Addresss" />
                                    </div>
                                    <div class="single-login-field">
                                        <input type="password" placeholder="Choose Password" />
                                    </div>
                                    <div class="single-login-field">
                                        <input type="password" placeholder="Confirm Password" />
                                    </div>
                                    <div class="single-login-field">
                                        <input type="text" placeholder="Mobile No." />
                                    </div>
                                    <div class="">

                                        <select class='city-style1  single-login-field-state '>
                                            <option value="-Select State-" className='state-dropdown'>-Select State-</option>
                                            <option value="Kerala">Kerala</option>
                                            <option value="Madhya Pradesh">Madhya Pradesh</option>
                                            <option value="Maharashtra">Maharashtra</option>
                                            <option value="Manipur">Manipur</option>
                                            <option value="Meghalaya">Meghalaya</option>
                                            <option value="Mizoram">Mizoram</option>
                                            <option value="Nagaland">Nagaland</option>
                                            <option value="Odisha">Odisha</option>
                                            <option value="Punjab">Punjab</option>
                                            <option value="Rajasthan">Rajasthan</option>
                                            <option value="Sikkim">Sikkim</option>
                                            <option value="Tamil Nadu">Tamil Nadu</option>
                                            <option value="Telangana">Telangana</option>
                                            <option value="Tripura">Tripura</option>
                                            <option value="Uttar Pradesh">Uttar Pradesh</option>
                                            <option value="Uttarakhand">Uttarakhand</option>
                                            <option value="West Bengal">West Bengal</option>
                                        </select>
                                    </div>

                                    <div >

                                        <select class='city-style1  single-login-field-City '>
                                            <option value="-Select State-" className='state-dropdown'>-Select City-</option>
                                            <option value="Chennai">Chennai</option>
                                            <option value="Coimbatore">Coimbatore</option>
                                            <option value="Madurai">Madurai</option>
                                            <option value="Tiruchirappalli">Tiruchirappalli (Trichy)</option>
                                            <option value="Salem">Salem</option>
                                            <option value="Tirunelveli">Tirunelveli</option>
                                            <option value="Erode">Erode</option>
                                            <option value="Vellore">Vellore</option>
                                            <option value="Thoothukudi">Thoothukudi (Tuticorin)</option>
                                            <option value="Thanjavur">Thanjavur</option>
                                        </select>
                                    </div>

                                    <div class="remember-row single-login-field clearfix">
                                        <p class="checkbox remember">
                                            <input class="checkbox-spin" type="checkbox" id="Freelance" />
                                            <label for="Freelance"><span></span>accept terms & condition</label>
                                        </p>
                                    </div>
                                    <div class="single-login-field">
                                        <button type="submit">Sign in</button>
                                    </div>
                                </form> */}
                <div className="dont_have">
                  <Link to="/login">Already have an account?</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Footer Area Start --> */}
      <Footer></Footer>
    </div>
  );
}
export default Register;
