import React, { useEffect, useState } from "react";
import "../../App.css"; // You can import your CSS file here
import {
  FaGavel,
  FaMapMarkerAlt,
  FaRegClock,
  FaSearch,
  FaTachometerAlt,
  FaThumbsUp,
  FaUserAlt,
  FaUsers,
  FaCalendarAlt,
  FaSave,
} from "react-icons/fa";
import { BsCheckLg, BsPencilSquare, BsTrash } from "react-icons/bs";
import CandidateHeader from "../../Components/candidateheader";
import CandidateSidebar from "../../Components/candidateSidebar";
import Slider from "react-slider";
import Footer from "../../Components/footer";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { JOB_TITLE_URL, SEARCHJOBLIST_URL, SEARCH_URL } from "../../Config/Constants";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";

function Candidate_SearchJobs() {




  const location = useLocation();


  console.log("location", location)
  // const org_id = location.state.state.id;



  const [values, setValues] = useState([0, 100]);

  const [localsearch, setlocalsearch] = useState(false);


  const handleChange = (newValues) => {
    setValues(newValues);
  };

  // **************url: `${SEARCH_URL}` onchange***************

  const [stateid, setStateid] = useState("");
  const [citylist, setCitylist] = useState("");
  const [categorylist, setCategorylist] = useState("");
  const [jobtypelist, setJobtypelist] = useState("");
  const [skilllist, setSkilllist] = useState("");
  const [totalpage, settotalpage] = useState(0);

  // dropdown state
  const [searchlist, setSearchlist] = useState("");
  const [page, setpage] = useState(1);

  const [statee, setStatee] = useState([]);
  const [cityy, setCityy] = useState([]);
  const [categoryy, setCategoryy] = useState([]);
  const [jobtypee, setJobtypee] = useState([]);
  const [skilll, setSkilll] = useState([]);
  const [titlelist, setTitlelist] = useState(null);

  const [jobtitlelist, setJobtitlelist] = useState([]);

  const [titlelist_show, settitlelist_show] = useState(false);


  // ***************handle to change set in select field*************************


  useEffect(() => {

    if (location.state) {

      // setStateid(location.state && location.state.state_id)
      // setSkilllist(location.state && location.state.skill_id)
      // setSearchlist(location.state && location.state.title)
      // setCategorylist(location.state && location.state.category_id)
      // alert("ff")
    }
    else {

    }


    // if (localsearch == false) {

    //   if ((stateid && location.state?.state_id) || (skilllist && location.state?.skill_id) || (searchlist && location.state?.title) || (categorylist && location.state?.category_id)) {

    //     handlesubmit(page)
    //     if (location.state.state_id) {
    //       fetchCities(location.state.state_id);

    //     }

    //   }


    // }

    // if (location.state?.state_id == '' && location.state?.skill_id == '' && location.state?.title == '' && location.state?.category_id == '') {

    //   handlesubmit(page)


    // }



  }, [stateid, skilllist, searchlist, categorylist]);


  useEffect(() => {
    if (location.state) {

      function areAllValuesEmpty(obj) {
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            // Check if the value is empty
            if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
              return false; // If any value is not empty, return false
            }
          }
        }
        return true; // All values are empty
      }


      const result = areAllValuesEmpty(location.state);
      console.log("result", result);
      if (result) {
        if (!categorylist && !searchlist && !skilllist && !stateid) {
          handlesubmit(page)

        }

      }
      else {
        if (!localsearch) {

          if (location.state?.state_id) {

            setStateid(location.state.state_id)
            handleState(location.state.state_id)

            if (stateid && location.state.state_id) {
              handlesubmit(page)
            }
          }
          if (location.state?.skill_id) {
            setSkilllist(location.state.skill_id)
            if (skilllist && location.state.skill_id) {
              handlesubmit(page)
            }
          }
          if (location.state?.title) {

            setSearchlist(location.state.title)
            if (searchlist && location.state.title) {
              handlesubmit(page)
            }
          }
          if (location.state?.category_id) {

            setCategorylist(location.state.category_id)
            if (categorylist && location.state.category_id) {
              handlesubmit(page)
            }
          }


        }

      }

    }
    else if(!categorylist && !searchlist && !skilllist && !stateid) {
      handlesubmit(page)

    }



  }, [stateid, skilllist, searchlist, categorylist])


  // console.log("categorylist", categorylist);

  const handleState = async (value, local) => {
    console.log("state id passing", value);

    if (local == "local") {
      setlocalsearch(true)
    }
    setStateid(value);

    setCitylist('')


    // fetchCities(selectedStateId);

    const token = localStorage.getItem("token");

    try {
      const response = await axios({
        method: "GET",
        url:
          " https://api.altimatixjobs.ifinitesolutions.com/api/states/" +
          value +
          "/cities",
        headers: {
          Authorization: token,
        },
      });
      console.log("response.data of city list", response.data);

      setCityy(response.data); // Set the fetched cities
    } catch (error) {
      console.log("Error fetching cities:", error.response.data);
    }
  };
  // const handleCity = (event) => {
  //   setCitylist(event.target.value);
  // };
  const handleCity = async (event) => {
    console.log("city id passing", event.target.value);

    const selectedStateId = event.target.value;

    setCitylist(selectedStateId);
  }
  const handleCategory = (event) => {
    setlocalsearch(true)
    setCategorylist(event.target.value);
  };
  const handleJobtype = (event) => {
    setJobtypelist(event.target.value);
  };
  const handleSkill = (event) => {
    setlocalsearch(true)
    setSkilllist(event.target.value);
  };
  const handleSearch = async (event) => {

    setlocalsearch(true)
    setSearchlist(event.target.value);

    if (event.target.value.length > 0) {
      settitlelist_show(true)
    }
    else {
      settitlelist_show(false)
    }

    try {
      const response = await axios({
        method: "GET",
        url: `${JOB_TITLE_URL}title=${event.target.value}`,

      });

      console.log("res", response.data)

      setJobtitlelist(response.data)


    } catch (error) {
      console.log("Error message access in dropdown:", error.response.data);
    }


  };





  const fetchCities = async (selectedStateId) => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios({
        method: "GET",
        url: ` https://api.altimatixjobs.ifinitesolutions.com/api/states/${selectedStateId}/cities`,
        headers: {
          Authorization: token,
        },
      });

      setCityy(response.data); // Set the fetched cities
    } catch (error) {
      console.log("Error fetching cities:", error.response.data);
    }
  };

  const handlesubmit = async (value) => {
    const token = localStorage.getItem("token");

    console.log(` stateid${stateid} + citylist${citylist} + categorylist${categorylist} +jobtypelist ${jobtypelist} +skilllist ${skilllist}`);

    try {
      const response = await axios({
        method: "GET",
        // url: `${SEARCH_URL}` + "search=" + searchlist,
        url:
          `${SEARCH_URL}` +
          "filters[state.id]=" +
          stateid +
          "&filters[city.id]=" +
          citylist +
          "&filters[newJobCategory.id]=" +
          categorylist +
          "&filters[jobType.id]=" +
          jobtypelist +
          "&filters[newJobSkill.id]=" +
          skilllist +
          "&search=" +
          searchlist +
          "&page=" +
          value
        ,

        headers: {
          Authorization: token,
        },
      });


      const totalPages = Math.ceil(((response.data.total) / (10)));
      console.log("totalPages", totalPages);
      settotalpage(totalPages)
      setTitlelist(response.data);
    } catch (err) {
      console.log("error meassage of tittle", err.response.data);
    }
  };

  // ***************************JobList Dropdown*********************************

  useEffect(() => {
    searchJoblist();
    // citylists();
  }, []);

  const searchJoblist = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios({
        method: "GET",
        url: `${SEARCHJOBLIST_URL}`,
        headers: {
          Authorization: token,
        },
      });

      // console.log("response of states success", response.data.states);
      // console.log("response of cities success", response.data.cities);
      // console.log("response of categories success", response.data.categories);
      // console.log("response of jobtypes success", response.data.jobtypes);
      // console.log("response of skills success", response.data.skills);

      // setStatee(response.data.states);
      // setCityy(response.data.cities);
      setCategoryy(response.data.categories);
      setJobtypee(response.data.jobtypes);
      setSkilll(response.data.skills);
      setStatee(response.data.states);
      // Set initial cities based on the first state (you can adjust this based on your requirements)
      // setCityy(response.data.cities[0].cities);
    } catch (error) {
      console.log("Error message access in dropdown:", error.response.data);
    }
  };



  const Pagination = (page) => {
    handlesubmit(page)
    setpage(page)
  }

  return (
    <div>
      {/* <!-- Header Area Start --> */}
      <CandidateHeader />
      {/* <!-- Header Area End --> */}

      {/* <!-- Breadcromb Area Start --> */}
      <section className="jobguru-breadcromb-area">
        <div className="breadcromb-top section_100">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="breadcromb-box">
                  <h3>Search Jobs</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="breadcromb-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="breadcromb-box-pagin">
                  <ul>
                    <li>
                      <Link to="/">home</Link>
                    </li>
                    {/* <li>
                      <Link to="#">candidates</Link>
                    </li> */}
                    <li className="active-breadcromb">
                      <Link to="#">Search Jobs</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Breadcromb Area End --> */}

      {/* <!-- Candidate Dashboard Area Start --> */}

      <section className="candidate-dashboard-area section_70">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-3 col-md-4 dashboard-left-border">
              <CandidateSidebar />
            </div> */}
            <div className="col-lg-12 col-md-12">
              <div className="job-grid-right">
                <div className="browse-job-head-option5">
                  <div className="row">
                    <div className="single-input5 col-sm-2 pdtop">
                      <select
                        value={stateid}
                        id="Region" onChange={(e) => handleState(e.target.value, "local")}>
                        <option value=""> - Select State -</option>
                        {statee.map((state) => (
                          <option value={state.id}>{state.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="single-input5 col-sm-2 pdtop">
                      <select
                        id="Region"
                        value={citylist}
                        // onChange={handleCity}
                        onChange={(e) => handleCity(e)}
                      >
                        <option value="">- Select City -</option>
                        {cityy.map((city) => (
                          <option key={city.id} value={city.id}>
                            {city.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="single-inputcat col-sm-2 pdtop">
                      <select
                        id="Region"
                        value={categorylist}
                        onChange={handleCategory}
                      >
                        <option value="">-Select Category-</option>
                        {categoryy.map((Category) => (
                          <option key={Category.id} value={Category.id}>
                            {Category.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {/* <div className="single-input col-sm-2 pdtop">
                      <input
                        placeholder="Job title"
                        type="text"
                        value={searchlist}
                        onChange={handleSearch}
                      />

                      {jobtitlelist.map((e, i) => (
                        <option key={i} value={e.tittle}>
                          {e.tittle}
                        </option>
                      ))}


                    </div> */}



                    <div className="single-input col-sm-2 pdtop">
                      <input
                        type="text"

                        placeholder="Job title"
                        value={searchlist}
                        onChange={handleSearch}

                        className="dropdown-input"
                      />
                      {titlelist_show && (
                        <ul className={jobtitlelist.length < 5 ? "search-list-below" : "dropdown-list"}>
                          {jobtitlelist.length > 0 ?

                            jobtitlelist.map((e, index) => (
                              <li onClick={() => (setSearchlist(e.tittle), settitlelist_show(false))} key={index} >
                                {e.tittle}
                              </li>
                            ))
                            :
                            <li style={{ textAlign: "center" }}>

                              No results found
                            </li>
                          }
                        </ul>
                      )}
                    </div>

                    <div className="single-input col-sm-2 pdtop">
                      <select
                        id="Region"
                        value={jobtypelist}
                        onChange={handleJobtype}
                      >
                        <option value="">- Job Type -</option>
                        {jobtypee.map((jobtype) => (
                          <option key={jobtype.id} value={jobtype.id}>
                            {jobtype.type}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="single-input col-sm-2 pdtop">
                      <select
                        id="Region"
                        value={skilllist}
                        onChange={handleSkill}
                      >
                        <option value="">- Skill -</option>
                        {skilll.map((skill) => (
                          <option key={skill.id} value={skill.id}>
                            {skill.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {/* <div style={{display:"flex",justifyContent:"end"}}> */}
                    <div
                      style={{ display: "flex", justifyContent: "end" }}
                      className="col pdtop"
                    >
                      <button
                        type="button"
                        onClick={() => handlesubmit(page)}
                        className="search-btn"
                      >
                        {/* <FaSearch /> */}
                        Go
                      </button>
                      {/* </div> */}
                    </div>
                  </div>

                  {/* <div className="job-browse-search">
                                        <form>
                                            <input type="search" placeholder="Search Jobs Here..." />
                                            <button type="submit"><FaSearch /> </button>
                                        </form>
                                    </div>
                                    <div className="job-browse-action">
                                        <div className="email-alerts">
                                            <input type="checkbox" className="styled" id="b_1" />
                                            <label className="styled" for="b_1">email alerts for this search</label>
                                        </div>
                                        <div className="dropdown">
                                            <button className="btn-dropdown dropdown-toggle" type="button" id="dropdowncur" data-bs-toggle="dropdown" aria-haspopup="true">Sort By</button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdowncur">
                                                <li>Newest</li>
                                                <li>Oldest</li>
                                                <li>Random</li>
                                            </ul>
                                        </div>
                                    </div> */}
                </div>
                {/* <!-- end job head --> */}

                {titlelist?.data.length > 0 ? (
                  <div className="job-sidebar-list-single">
                    {titlelist?.data.map((details) => (
                      <div className="sidebar-list-single" key={details.id}>
                        <div className="top-company-list">
                          <div className="company-list-logo">
                            <Link to="#">
                              <img src={details.company.logo} />
                            </Link>
                          </div>
                          <div className="company-list-details">
                            <div className="company-list-details1">
                              <Link to="#" className="black-text">
                                {details.tittle}
                              </Link>
                              <h4 className="Search-Jobtype">
                                {details.job_type.type}
                              </h4>
                            </div>
                            <div>
                              <p className="company-state">
                                <FaMapMarkerAlt className="map-icon"></FaMapMarkerAlt>
                                {details.state.name},{details.city.name}
                              </p>
                              <p className="open-icon">
                                <FaCalendarAlt className="clock-icon"></FaCalendarAlt>
                                {details.expire_date}
                              </p>
                              <p className="varify">
                                <FaSave className="varify-icon"></FaSave>Saved
                              </p>
                            </div>
                            <p className="jobdesc">
                              {details.job_description.slice(0, 100)}
                            </p>
                          </div>
                          <div className="company-list-btn">
                            <Link
                              className="jobguru-btn"
                              to={`/job_details/${details.id}`}
                            >
                              View Details
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="Not-found">

                    <p className="jobdesc">Not Found</p>
                  </div>


                )}

                {/* <!-- end job sidebar list --> */}
                <br></br>

                {/* {Array.from({ length: 20 }, (_, index) => (
                  <button className={success && Number(success.current_page) == index + 1 ? "no1_button_selected" : 'no1_button_unselected'} key={index} onClick={() => handlePageChange(index + 1, success && Number(success.current_page) == index + 1)}>
                    {index + 1}
                  </button>
                ))} */}

                {titlelist?.data.length > 0 ?
                  <div className="pagination-box-row">
                    <p>Page {titlelist.from} of {titlelist.to}</p>

                    <ul className="pagination">
                      {Array.from({ length: totalpage }, (_, index) => (
                        <li className={Number(titlelist.current_page) == index + 1 ? "active" : ""}>
                          <Link onClick={() => Pagination(index + 1)} >{index + 1}</Link>
                        </li>
                        // <li>
                        //   <Link to="#">2</Link>
                        // </li>
                        // <li>
                        //   <Link to="#">3</Link>
                        // </li>
                        // <li>...</li>
                        // <li>
                        //   <Link to="#">6</Link>
                        // </li>

                      ))}
                      <li>
                        <Link to="#">
                          <i className="fa fa-angle-double-right"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  : null}
                {/* <!-- end pagination --> */}
              </div>
            </div>
          </div>
        </div>
      </section >

      {/* <!-- Candidate Dashboard Area End --> */}

      {/* <!-- Footer Area Start --> */}

      <Footer></Footer>
    </div >
  );
}
export default Candidate_SearchJobs;
