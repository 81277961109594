import React, { useEffect, useState } from 'react';
import '../App.css'; // You can import your CSS file here
import { FaAngleDoubleRight, FaFacebook, FaGavel, FaLinkedinIn, FaLock, FaPowerOff, FaTachometerAlt, FaThumbsUp, FaTwitter, FaUserAlt, FaUsers } from "react-icons/fa";
import { CgNotes } from "react-icons/cg";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { TERMS_URL } from '../Config/Constants';
function Footer() {




    useEffect(() => {

        get_terms()


    }, [])

    const [terms, setTerms] = useState("")

    const get_terms = async (e) => {



        try {
            const Response = await axios({
                method: "get",
                url: `${TERMS_URL}`,

            })

            // console.log("Response", Response.data.privacypolicy.page_content)
            var parser = new DOMParser();

            // Parse the HTML content
            var doc = parser.parseFromString(Response.data.privacypolicy.page_content, 'text/html');

            // Get the text content of the first paragraph
            var firstParagraph = doc.querySelector('p');
            if (firstParagraph) {
                // Extract the first sentence from the text content
                var firstSentence = firstParagraph.textContent.match(/[^.!?]*[.!?]/)[0].trim();

                // Log the result
                console.log("First sentence of the first paragraph:", firstSentence);
                setTerms(firstSentence)
            } else {
                console.log("No <p> tag found.");
            }


            // var match = Response.data.privacypolicy.page_content.match(/<p>([\s\S]*?)<\/p>/);
            // if (match) {
            //     var content = match[1].trim(); // Trim to remove leading and trailing whitespaces
            //     setTerms(content)

            //     console.log("Content of <p> tag:", content);
            // } else {
            //     console.log("No <p> tag found or empty content.");
            // }






        }

        catch (err) {


            console.log("errr====>", err)


        }

    }





    return (

        <div>
            <footer className="jobguru-footer-area">
                <div className="footer-top section_50">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <div className="single-footer-widget">
                                    {/* <div className="footer-logo"> */}

                                    <h3>About</h3>

                                    {/* </div> */}
                                    <p dangerouslySetInnerHTML={{ __html: terms }}></p>

                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="single-footer-widget">
                                    <h3>recent post</h3>


                                    <ul className="footer-social">
                                        <li><a href="#" className="fb"> <FaFacebook></FaFacebook> </a></li>
                                        <li><a href="#" className="twitter"><FaTwitter></FaTwitter> </a></li>
                                        <li><a href="#" className="linkedin"><FaLinkedinIn></FaLinkedinIn> </a></li>

                                    </ul>


                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="single-footer-widget">
                                    <h3>company</h3>
                                    <ul>
                                        <li><Link to="/about_us"><FaAngleDoubleRight ></FaAngleDoubleRight> About us</Link></li>
                                        {/* <li><a href="#"><FaAngleDoubleRight ></FaAngleDoubleRight> Careers</a></li> */}
                                        <li><Link to="/terms_and_conditions"><FaAngleDoubleRight ></FaAngleDoubleRight> Terms of Service</Link></li>
                                        <li><Link to="/policy"><FaAngleDoubleRight ></FaAngleDoubleRight> Privacy Policy</Link></li>


                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="single-footer-widget footer-contact">
                                    <h3>Contact Info</h3>
                                    <ul>
                                        {/* <li><a href="#"><FaAngleDoubleRight ></FaAngleDoubleRight> My Profile</a></li> */}
                                        {/* <li><a href="#"><FaAngleDoubleRight ></FaAngleDoubleRight> Refer a Friend</a></li> */}
                                        <li><Link to="/contact_us"><FaAngleDoubleRight ></FaAngleDoubleRight> Contact us</Link></li>



                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="copyright-left">
                                    <p>Copyright &copy; 2016 Themescare. All Rights Reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer >



        </div>

    )

}


export default Footer;
