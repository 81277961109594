import React from 'react';
import '../../App.css'; // You can import your CSS file here
import { FaGavel, FaTachometerAlt, FaThumbsUp, FaUserAlt, FaUsers } from "react-icons/fa";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import CandidateHeader from '../../Components/candidateheader';
import CandidateSidebar from '../../Components/candidateSidebar';
import Footer from '../../Components/footer';
import { CHANGEPASSWORD_URL } from '../../Config/Constants';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import Swal from 'sweetalert2';

function Candidate_ChangePassword() {

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState({});
    const token = localStorage.getItem("token")

    const handleUpdatePassword = async (e) => {
        e.preventDefault();

        try {
            const Response = await axios({
                method: "put",
                url: `${CHANGEPASSWORD_URL}`,
                headers: {
                    Authorization: token,

                },
                data: {
                    old_password: oldPassword,
                    password: newPassword,
                    password_confirmation: confirmPassword,
                }
            })
            if (Response.data.success == true) {
            // if (Response.data.message == "Password Updated Successfully") {
                console.log('Password updated successfully:', Response.data);
                Swal.fire("Success!", "Password Updated Successfully!", "success");

                setTimeout(() => {
                    Swal.close();
                }, 1500);
            } else {
                Swal.fire("Failed!", "Update Failed!", "error");

                setTimeout(() => {
                    Swal.close();
                }, 1500);
            }


        }

        catch (err) {

            console.log('Error updating password:', err.response.data.errors);
            setError(err.response.data.errors)
        }

    };

    return (

        <div>

            {/* <!-- Header Area Start --> */}
            <CandidateHeader />
            {/* <!-- Header Area End --> */}


            {/* <!-- Breadcromb Area Start --> */}
            <section className="jobguru-breadcromb-area">
                <div className="breadcromb-top section_100">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="breadcromb-box">
                                    <h3>Change Password</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="breadcromb-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="breadcromb-box-pagin">
                                    <ul>
                                        <li><Link to="#">home</Link></li>
                                        <li><Link to="#">candidates</Link></li>
                                        <li className="active-breadcromb"><Link to="#">Change Password</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Breadcromb Area End --> */}


            {/* <!-- Candidate Dashboard Area Start --> */}
            <section className="candidate-dashboard-area section_70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-4 dashboard-left-border">
                            <CandidateSidebar />
                        </div>
                        <div className="col-lg-9 col-md-8">
                            <div className="dashboard-right">
                                <div className="change-pass manage-jobs">
                                    <div className="manage-jobs-heading">
                                        <h3>Change Password</h3>
                                    </div>
                                    <form onSubmit={handleUpdatePassword}>
                                        <p>
                                            <label >Old Password</label>
                                            <input type="password" placeholder="*******" value={oldPassword}
                                                onChange={(e) => setOldPassword(e.target.value)} />
                                            <span style={{ color: "red", fontSize: 12 }}>{error.old_password}</span>
                                        </p>
                                        <p>
                                            <label >New Password</label>
                                            <input type="password" placeholder="*******" value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)} />
                                            <span style={{ color: "red", fontSize: 12 }}>{error.password}</span>
                                        </p>
                                        <p>
                                            <label >confirm Password</label>
                                            <input type="password" placeholder="*******" value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)} />
                                            <span style={{ color: "red", fontSize: 12 }}>{error.password_confirmation}</span>
                                        </p>
                                        <p>
                                            <button type="submit">Update</button>
                                        </p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Candidate Dashboard Area End --> */}


            {/* <!-- Footer Area Start --> */}
            <Footer></Footer>






        </div>

    )




}
export default Candidate_ChangePassword;
