import React, { useEffect, useState } from "react";
import Select from "react-select";
// import { colourOptions } from '../data';
import "../../App.css"; // You can import your CSS file here
import {
  FaGavel,
  FaMinus,
  FaPlus,
  FaTachometerAlt,
  FaThumbsUp,
  FaUserAlt,
  FaUsers,
} from "react-icons/fa";
import CandidateHeader from "../../Components/candidateheader";
import CandidateSidebar from "../../Components/candidateSidebar";
import Footer from "../../Components/footer";
import { Button } from "@adobe/react-spectrum";
import RecruiterSidebar from "../../Components/recruiterSidebr";
import {
  EXPERIENCE_URL,
  JOBCATEGORY_URL,
  JOBTYPE_URL,
  SKILL_URL,
  STATE_URL,
} from "../../Config/Constants";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";

function Recruiter_PostJobs() {
  // const [error, setError] = useState({});

  const navigate = useNavigate();

  // ********************************
  // job categories
  const changeJobcategory = (e) => {
    console.log("e.target.value", e);
    setJobCategories(e);
  };

  const [jobCategories, setJobCategories] = useState([]);
  const [jobCategoriesid, setjobCategoriesid] = useState("");
  const [skill, setskill] = useState([]);
  useEffect(() => {
    const Category = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios({
          method: "get",
          url: `${JOBCATEGORY_URL}`,
          headers: {
            Authorization: token,
          },
        });

        console.log("Response category====>", response.data);

        // setJobCategories(response.data.jobs);

        const array = response.data.jobs.map((e) => ({
          label: e.name,
          value: e.id,
        }));

        setjobCategoriesid(array);
      } catch (error) {
        console.log("Error fetching job categories:", error.message);
      }
    };

    Category();
  }, []);

  // JOBTYPE

  const changeJobtype = (e) => {
    console.log("e.target.value", e);
    setjobType(e.value);
  };
  const [jobType, setjobType] = useState('');
  const [jobTypeid, setjobTypeid] = useState([]);

  useEffect(() => {
    const jobtypeList = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios({
          method: "get",
          url: `${JOBTYPE_URL}`,
          headers: {
            Authorization: token,
          },
        });

        // setjobType(response.data.jobTypes);

        console.log("Response====>", response.data);
        const array = response.data.jobTypes.map((e) => ({
          label: e.type,
          value: e.id,
        }));

        setjobTypeid(array);
      } catch (error) {
        console.log("Error fetching job categories:", error.message);
      }
    };

    jobtypeList();
  }, []);

  // Skills set

  const changeskill = (e) => {
    console.log("e.target.value", e);

    setskill(e);


  };

  const [skillid, setskillid] = useState("");

  useEffect(() => {
    const skillset = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios({
          method: "get",
          url: `${SKILL_URL}`,
          headers: {
            Authorization: token,
          },
        });

        // setskill(response.data.skills);

        console.log("Response skill====>", response.data.skills);
        const array = response.data.skills.map((e) => ({
          label: e.name,
          value: e.id,
        }));

        setskillid(array);
      } catch (error) {
        console.log("Error fetching job categories:", error.message);
      }
    };

    skillset();
  }, []);


  const [yearExperience, setyearExperience] = useState('');
  const [yearExperienceid, setyearExperienceid] = useState([]);
  // Total Year Experience
  const changeexperience = (e) => {
    console.log("e.target.value", e);
    setyearExperience(e.value);
  };



  useEffect(() => {
    const experiencess = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios({
          method: "get",
          url: `${EXPERIENCE_URL}`,
          headers: {
            Authorization: token,
          },
        });

        // setyearExperienceid(response.data.experiences);

        console.log("Response Experience====>", response.data);
        const array = response.data.experiences.map((e) => ({
          label: e.experience,
          value: e.id,
        }));

        setyearExperienceid(array);
      } catch (error) {
        console.log("Error fetching job categories:", error.message);
      }
    };

    experiencess();
  }, []);

  //Experience Level

  const [experienceLevel, setExperienceLevel] = useState("");

  const handleExperienceLevelChange = (e) => {
    setExperienceLevel(e.target.value);
  };

  // *******************************************
  const [states, setStates] = useState([]);
  const [stateid, setStatesid] = useState("");

  const [cities, setCities] = useState([]);
  const [citieid, setCitieid] = useState("");
  const [formData, setFormData] = useState({
    // state_id: "",
    // city_id: "",
  });

  const [errorMessages, setErrorMessages] = useState({});

  const handleInputChange = (key, e) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  useEffect(() => {


    stateData();
  }, []);

  const stateData = async () => {
    try {
      // Fetch states data
      const response = await axios({
        method: "get",
        url: `${STATE_URL}`,
      });
      setStates(response.data.states);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };




  const handleInputChange1 = async (field, event) => {
    const value = event.target.value;



    // setFormData((prevData) => ({ ...prevData, [field]: value }));
    // setErrorMessages((prevErrors) => ({ ...prevErrors, [field]: "" }));

    if (field === "state_id") {
      setStatesid(value);
      try {
        // Fetch cities data based on selected state
        const response = await axios({
          method: "get",
          url: ` https://api.altimatixjobs.ifinitesolutions.com/api/states/${value}/cities`,
        });
        console.error("response.data", response.data);

        setCities(response.data);
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    }
    else {
      setCitieid(value);
    }
  };
  //   *************************************
  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const [date, setdate] = useState("");
  const [requirement, setrequirement] = useState("");
  const [salary, setsalary] = useState("");
  const [hours, sethours] = useState("");
  const [academicDegree, setAcademicDegree] = useState("");

  const handletitle = (event) => {
    settitle(event.target.value);
  };
  const handledescription = (event) => {
    setdescription(event.target.value);
  };

  const handledate = (event) => {
    setdate(event);
  };
  const handlerequire = (event) => {
    setrequirement(event.target.value);
  };
  const handlesalary = (event) => {
    setsalary(event.target.value);
  };
  const handlehours = (event) => {
    sethours(event.target.value);
  };
  const handleSubmit = async () => {


    // console.log("citieid", citieid);
    // console.log("title", title);
    // console.log("description", description);
    // console.log("date", date);
    // console.log("requirement", requirement);
    // console.log("year of experince", experienceLevel);
    // console.log("academicDegree-->", academicDegree);
    // console.log("salary", salary);
    // console.log("hours", hours);

    const token = localStorage.getItem("token");


    const filtercat = skill.map((e) => e.value)
    const filter = jobCategories.map((e) => e.value)

    console.log("filtercat====>", filtercat);


    var body = {
      state_id: stateid,
      city_id: citieid,
      tittle: title,
      job_description: description,
      requirement: requirement,
      job_type_id: jobType,
      expire_date: moment(date).format('MM/DD/YYYY'),
      experience_level: experienceLevel,
      experience_id: yearExperience,
      academic_degree: academicDegree,
      working_hour: hours,
      job_ids: filter,
      skill_ids: filtercat,
      // company_id: "",
      salary: salary,
    };
    console.log("body", body);

    try {
      const Response = await axios({
        method: "POST",
        url: " https://api.altimatixjobs.ifinitesolutions.com/api/recruiter/newJobs",
        data: body,
        headers: {
          Authorization: token,
        },
      });
      // console.log("API Response:", Response.data);
      console.log("Response ===>", Response.data);
      setErrorMessages({})
      setStatesid("")
      setCitieid("")
      settitle("")
      setdescription("")
      sethours("")
      setrequirement("")
      setjobType('')
      setdate("")
      setExperienceLevel("")
      setyearExperience("")
      setAcademicDegree("")
      setJobCategories([])
      setskill([])
      setsalary("")
      // navigate("/recruiter/postjobs");
      // window.location.reload()
      Swal.fire({
        icon: 'success',
        title: 'Job Posted Successfully',
        showConfirmButton: false,
        timer: 1500, // Close after 1.5 seconds
      });

    } catch (err) {
      console.log("Error Response ===>", err.response.data);
      if (err.response.data.errors) {
        setErrorMessages(err.response.data.errors)
      }
    }
  };



  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   try {
  //     // Make a POST request to your API
  //     const response = await axios.post(
  //       " https://api.altimatixjobs.ifinitesolutions.com/api/recruiter/newJobs",
  //       formData
  //     );

  //     // Handle the response (e.g., show a success message)
  //     console.log("Post request successful:", response.data);
  //   } catch (error) {
  //     // Handle errors (e.g., show an error message)
  //     console.error("Error making post request:", error);
  //   }
  // };

  return (
    <div>
      {/* <!-- Header Area Start --> */}
      <CandidateHeader />
      {/* <!-- Header Area End --> */}

      {/* <!-- Breadcromb Area Start --> */}
      <section className="jobguru-breadcromb-area">
        <div className="breadcromb-top section_100">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="breadcromb-box">
                  <h3>Post Jobs</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="breadcromb-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="breadcromb-box-pagin">
                  <ul>
                    <li>
                      <a href="#">home</a>
                    </li>
                    <li>
                      <a href="#">Recruiters</a>
                    </li>
                    <li className="active-breadcromb">
                      <a href="#">Post Jobs</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Breadcromb Area End --> */}

      {/* <!-- Candidate Dashboard Area Start --> */}
      <section className="candidate-dashboard-area section_70">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4 dashboard-left-border">
              <RecruiterSidebar />
            </div>
            <div className="col-lg-9 col-md-8">
              <div className="dashboard-right">
                <div className="candidate-profile">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="submit-resume-box-custom">
                        <form>
                          <div className="resume-box">
                            {/* <div className="single-resume-feild resume-avatar">
                                                            <div className="resume-image">
                                                                <img src={require("../../img/resume-avatar.jpg")} alt="resume avatar" />
                                                                <div className="resume-avatar-hover">
                                                                    <div className="resume-avatar-upload">
                                                                        <p>
                                                                            <i className="fa fa-upload"></i>
                                                                            upload
                                                                        </p>
                                                                        <input type="file" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}
                            {/* <h3>General Information</h3> */}

                            <div className="single-resume-feild feild-flex-2">
                              <div className="single-input">
                                <label>State:</label>
                                <select
                                  name="state"
                                  value={formData.state_id}
                                  onChange={(e) =>
                                    handleInputChange1("state_id", e)
                                  }
                                >
                                  <option
                                    value="-Select State-"
                                    className="state-dropdown"
                                  >
                                    -Select State-
                                  </option>

                                  {states.map((state) => (
                                    <option key={state.id} value={state.id}>
                                      {state.name}
                                    </option>
                                  ))}
                                </select>
                                {errorMessages.state_id && (
                                  <div className="error-message">
                                    {errorMessages.state_id}
                                  </div>
                                )}
                              </div>
                              <div className="single-input">
                                <label>City:</label>
                                <select
                                  className="city-style1"
                                  name="city"
                                  value={formData.city_id}
                                  onChange={(e) =>
                                    handleInputChange1("city_id", e)
                                  }
                                >
                                  <option
                                    value="-Select State-"
                                    className="state-dropdown"
                                  >
                                    -Select City-
                                  </option>

                                  {cities.map((city) => (
                                    <option key={city.id} value={city.id}>
                                      {city.name}
                                    </option>
                                  ))}
                                </select>
                                {errorMessages.city_id && (
                                  <div className="error-message">
                                    {errorMessages.city_id}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="single-resume-feild feild-flex-2">
                              <div className="single-input">
                                <label>Job title</label>
                                <input
                                  type="text"
                                  placeholder=""
                                  value={title}
                                  onChange={handletitle}
                                />
                                {errorMessages.tittle && (
                                  <div className="error-message">
                                    {errorMessages.tittle}
                                  </div>
                                )}
                              </div>
                              <div className="single-input">
                                <label>Skill Required</label>
                                <Select
                                  className="city-style1"
                                  placeholder="[-Select Skill-]"
                                  isMulti
                                  value={skill}
                                  onChange={(e) => changeskill(e)}
                                  options={skillid}
                                />
                                {errorMessages.skill_ids && (
                                  <div className="error-message">
                                    {errorMessages.skill_ids}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="single-resume-feild feild-flex-2">
                              <div className="single-input">
                                <label>Job Category</label>
                                {/* <select className="city-style1">
                                  <option value="">Select Some Options</option>
                                  {jobCategories.map((job) => (
                                    <option key={job.id} value={job.id}>
                                      {job.name}
                                    </option>
                                  ))}
                                </select> */}
                                <Select
                                  className="city-style1"
                                  placeholder="[-Select Job Category-]"
                                  isMulti
                                  value={jobCategories}
                                  onChange={(e) => changeJobcategory(e)}
                                  options={jobCategoriesid}
                                />
                                {errorMessages.job_ids && (
                                  <div className="error-message">
                                    {errorMessages.job_ids}
                                  </div>
                                )}
                              </div>
                              <div className="single-input">
                                <label>Job Type </label>
                                <Select
                                  className="city-style1"
                                  placeholder="[-Select Job Type-]"
                                  // isMulti
                                  value={jobTypeid.find(option => option.value === jobType)}
                                  onChange={(e) => changeJobtype(e)}
                                  options={jobTypeid}
                                />
                                {errorMessages.job_type_id && (
                                  <div className="error-message">
                                    {errorMessages.job_type_id}
                                  </div>
                                )}
                                {/* <option value="">Select Some Options</option> */}
                                {/* {jobType.map((jobs) => (
                                    <option key={jobs.id} value={jobs.id}>
                                      {jobs.type}
                                    </option>
                                  ))} */}
                                {/* </select> */}
                              </div>
                            </div>
                            <div className="single-resume-feild ">
                              <div className="single-input">
                                <label>Job Description</label>
                                <textarea
                                  placeholder="Write Here..."
                                  value={description}
                                  onChange={handledescription}
                                ></textarea>
                                {errorMessages.job_description && (
                                  <div className="error-message">
                                    {errorMessages.job_description}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="single-resume-feild ">
                              <div className="single-input">
                                <label>Personality Requirements</label>
                                <textarea
                                  value={requirement}
                                  onChange={handlerequire}
                                  placeholder="Write Here..."
                                ></textarea>
                                {errorMessages.requirement && (
                                  <div className="error-message">
                                    {errorMessages.requirement}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="single-resume-feild feild-flex-2">
                            <div className="single-input">
                              <label>Expired Date</label>
                              {/* <input
                                type="date"
                                id="name"
                                value={date}
                                onChange={handledate}
                              /> */}

                              <DatePicker
                                selected={date}
                                onChange={(e) => handledate(e)}
                                dateFormat="MM/dd/yyyy" // Set your desired date format
                                placeholderText="MM/DD/YYYY" // Placeholder text
                              />




                              {errorMessages.expire_date && (
                                <div className="error-message">
                                  {errorMessages.expire_date[0]}
                                </div>
                              )}
                            </div>


                            <div className="single-input">
                              <label>Salary Offer </label>
                              <input
                                type="text"
                                id="name"
                                placeholder="eg: $ 3000 - $ 5000"
                                value={salary}
                                onChange={handlesalary}
                              />
                              {/* <p></p> */}
                              {errorMessages.salary && (
                                <div className="error-message">
                                  {errorMessages.salary}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="single-resume-feild feild-flex-2">
                            <div className="single-input">
                              <label>Experience Level</label>
                              <div className="gender">
                                <div className="form-group form-radio">
                                  <input
                                    id="JuniorRadio"
                                    name="experienceLevel"
                                    type="radio"
                                    value="Junior"
                                    checked={experienceLevel=="Junior"?true:false}
                                    onChange={handleExperienceLevelChange}
                                  />
                                  <label
                                    htmlFor="JuniorRadio"
                                    className="inline control-label"
                                  >
                                    Junior
                                  </label>
                                </div>
                                <div className="form-group form-radio">
                                  <input
                                    id="seniorRadio"
                                    name="experienceLevel"
                                    type="radio"
                                    value="Senior"
                                    checked={experienceLevel=="Senior"?true:false}

                                    onChange={handleExperienceLevelChange}
                                  />
                                  <label
                                    htmlFor="seniorRadio"
                                    className="inline control-label"
                                  >
                                    Senior
                                  </label>
                                </div>
                                <div className="form-group form-radio">
                                  <input
                                    id="ManagerRadio"
                                    name="experienceLevel"
                                    type="radio"
                                    value="Manager"
                                    checked={experienceLevel=="Manager"?true:false}

                                    onChange={handleExperienceLevelChange}
                                  />
                                  <label
                                    htmlFor="ManagerRadio"
                                    className="inline control-label"
                                  >
                                    Manager
                                  </label>
                                </div>
                              </div>

                              {errorMessages.experience_level && (
                                <div className="error-message">
                                  {errorMessages.experience_level}
                                </div>
                              )}
                            </div>
                            <div className="single-input">
                              <label>Total Years Experience</label>
                              {/* <select className="city-style1">
                                <option value="">-Select Year-</option>
                                {yearExperience.map((exper) => (
                                  <option key={exper.id} value={exper.id}>
                                    {exper.experience}
                                  </option>
                                ))}
                              </select> */}
                              <Select
                                className="city-style1"
                                placeholder="[-Select Years Experience-]"
                                // isMulti
                                // value={yearExperience}
                                value={yearExperienceid.find(option => option.value === yearExperience)}
                                onChange={(e) => changeexperience(e)}
                                options={yearExperienceid}
                              />
                              {errorMessages.experience_id && (
                                <div className="error-message">
                                  {errorMessages.experience_id}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="single-resume-feild feild-flex-2">
                            <div className="single-input">
                              <label>Academic Degree</label>
                              <div className="gender">
                                <div className="form-group form-radio">
                                  <input
                                    className="single-input-radio-btn"
                                    id="AssociateRadio"
                                    name="academicDegree"
                                    type="radio"
                                    value="Associate Degree"
                                    checked={academicDegree == 'Associate Degree' ? true : false}
                                    onChange={() =>
                                      setAcademicDegree("Associate Degree")
                                    }
                                  />
                                  <label
                                    htmlFor="AssociateRadio"
                                    className="inline control-label"
                                  >
                                    Associate Degree
                                  </label>
                                </div>

                                <div className="form-group form-radio">
                                  <input
                                    className="single-input-radio-btn"
                                    id="MasterRadio"
                                    name="academicDegree"
                                    type="radio"
                                    value="Master's Degree"
                                    checked={academicDegree == "Master's Degree" ? true : false}
                                    onChange={() =>
                                      setAcademicDegree("Master's Degree")
                                    }
                                  />
                                  <label
                                    htmlFor="MasterRadio"
                                    className="inline control-label"
                                  >
                                    Master's Degree
                                  </label>
                                </div>
                              </div>
                              <div className="gender">
                                <div className="form-group form-radio">
                                  <input
                                    className="single-input-radio-btn"
                                    id="BachelorRadio"
                                    name="academicDegree"
                                    type="radio"
                                    value="Bachelor's Degree"
                                    checked={academicDegree == "Bachelor's Degree" ? true : false}

                                    onChange={() =>
                                      setAcademicDegree("Bachelor's Degree")
                                    }
                                  />
                                  <label
                                    htmlFor="BachelorRadio"
                                    className="inline control-label"
                                  >
                                    Bachelor's Degree
                                  </label>
                                </div>

                                <div className="form-group form-radio">
                                  <input
                                    className="single-input-radio-btn"
                                    id="DoctoralRadio"
                                    name="academicDegree"
                                    type="radio"
                                    value="Doctoral Degree"
                                    checked={academicDegree == "Doctoral Degree" ? true : false}

                                    onChange={() =>
                                      setAcademicDegree("Doctoral Degree")
                                    }
                                  />
                                  <label
                                    htmlFor="DoctoralRadio"
                                    className="inline control-label"
                                  >
                                    Doctoral Degree
                                  </label>
                                </div>
                              </div>

                              {errorMessages.academic_degree && (
                                <div className="error-message">
                                  {errorMessages.academic_degree}
                                </div>
                              )}
                            </div>

                            <div className="single-input">
                              <label>Working Hours</label>
                              <input
                                type="text"
                                placeholder="eg: 8hours/day"
                                value={hours}
                                onChange={handlehours}
                              />

                              {errorMessages.working_hour && (
                                <div className="error-message">
                                  {errorMessages.working_hour}
                                </div>
                              )}
                            </div>

                          </div>

                          {/* <div className="resume-box">
                                                        <h3>Skills & Portfolio</h3>
                                                        <div className="single-resume-feild ">
                                                            <div className="single-input">
                                                                <label for="skill">Skills:</label>
                                                                <input type="text" placeholder="Comma separate a list of relevant skills" id="skill" />
                                                            </div>
                                                        </div>
                                                        <div className="single-resume-feild feild-flex-2">
                                                            <div className="single-input">
                                                                <label for="Portfolio">Portfolio:</label>
                                                                <input type="text" placeholder="Portfolio Demo URL" id="Portfolio" />
                                                            </div>
                                                            <div className="single-input">
                                                                <label for="w_screen">Works Screenshot</label>
                                                                <div className="product-upload">
                                                                    <p>
                                                                        <i className="fa fa-upload"></i>
                                                                        Max file size is 3MB,Suitable files are .jpg & .png
                                                                    </p>
                                                                    <input type="file" id="w_screen" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                          <div className="submit-resume">
                            <button
                              onClick={() => handleSubmit()}
                              type="button"
                            >
                              Save Resume
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Candidate Dashboard Area End --> */}

      {/* <!-- Footer Area Start --> */}

      <Footer></Footer>
    </div>
  );
}
export default Recruiter_PostJobs;
