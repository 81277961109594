import React, { useEffect, useState } from "react";
import "../../App.css"; // You can import your CSS file here
import {
  FaGavel,
  FaTachometerAlt,
  FaThumbsUp,
  FaUserAlt,
  FaUsers,
} from "react-icons/fa";
import CandidateHeader from "../../Components/candidateheader";
import CandidateSidebar from "../../Components/candidateSidebar";
import Footer from "../../Components/footer";
import { BsEyeFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { CANDIAPPLICATION_URL } from "../../Config/Constants";
import axios from "axios";
import moment from "moment/moment";

function Candidate_Applications() {


  const [applicationList, setApplicationList] = useState(null);
  const [page, setPage] = useState(1);




  useEffect(() => {
    fetchData(page);
  }, []);

  const fetchData = async (value) => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios({
        method: "get",
        url: `${CANDIAPPLICATION_URL}page=` + value,
        headers: {
          Authorization: token,
        },
      });
      console.log("response of applicationList", response.data.data);
      setApplicationList(response.data);
    } catch (err) {
      console.log("Error fetching data:", err.response.data);
    }
  };

  const Pagination = (page) => {
    fetchData(page)
    setPage(page)
  }



  return (
    <div>
      {/* <!-- Header Area Start --> */}
      <CandidateHeader />
      {/* <!-- Header Area End --> */}

      {/* <!-- Breadcromb Area Start --> */}
      <section className="jobguru-breadcromb-area">
        <div className="breadcromb-top section_100">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="breadcromb-box">
                  <h3>Applications</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="breadcromb-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="breadcromb-box-pagin">
                  <ul>
                    <li>
                      <Link to="#">home</Link>
                    </li>
                    <li>
                      <Link to="#">candidates</Link>
                    </li>
                    <li className="active-breadcromb">
                      <Link to="#">Applications</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Breadcromb Area End --> */}

      {/* <!-- Candidate Dashboard Area Start --> */}
      <section className="candidate-dashboard-area section_70">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4 dashboard-left-border">
              <CandidateSidebar />
            </div>
            <div className="col-lg-9 col-md-8">
              <div className="dashboard-right">
                <div className="manage-jobs">
                  <div className="manage-jobs-heading">
                    <h3>My Job Listing</h3>
                  </div>
                  <div className="single-manage-jobs table-responsive">
                    <table className="table">
                      <thead>
                        <tr style={{ backgroundColor: "aliceblue" }}>
                          <th>Title</th>
                          <th>Posted on </th>
                          <th>Expiring on </th>
                          <th>Status</th>
                          <th>action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {applicationList?.data.map((application) => (
                          <tr key={application.id}>
                            <td className="manage-jobs-title">

                              <Link to={`/job_details/${application.new_job.id}`}>
                                {application.new_job.tittle}
                              </Link>
                            </td>
                            <td className="table-date">
                              {/* {application.created_at} */}
                              {moment(application.created_at).format('MM-DD-YYYY')}

                            </td>
                            <td className="table-date">
                              {moment(application.new_job.expire_date).format('MM-DD-YYYY')}
                              {/* {application.new_job.expire_date} */}
                            </td>
                            <td>
                              <span className="pending">
                                {application.status}
                              </span>
                            </td>
                            <td className="action">
                              <Link
                                to={`/job_details/${application.new_job.id}`}
                                className="action-edit"
                              >
                                <BsEyeFill></BsEyeFill>
                              </Link>
                            </td>
                          </tr>

                          // <tr>
                          //     <td className="manage-jobs-title"><Link to="/job_details">Full Stack PHP Developer </Link></td>
                          //     <td className="table-date">28 June, 2018</td>
                          //     <td className="table-date">10 July, 2018</td>
                          //     <td><span className="expired">Expired</span></td>
                          //     <td className="action">

                          //         <Link to="/job_details" className="action-edit"><BsEyeFill></BsEyeFill></Link>
                          //     </td>
                          // </tr>
                        ))}
                      </tbody>



                    </table>
                    {applicationList?.data.length == 0 ?
                      <tbody className="Not-found">

                        <td className="jobdesc">No Data Found</td>
                      </tbody>
                      :

                      <div className="pagination-box-row">
                        <p>Page {applicationList?.from} of {applicationList?.to}</p>

                        <ul className="pagination">
                          {Array.from({ length: Math.ceil(((applicationList?.total) / (10))) }, (_, index) => (
                            <li className={Number(applicationList?.current_page) == index + 1 ? "active" : ""}>
                              <Link
                                onClick={() => Pagination(index + 1)}
                              >{index + 1}</Link>
                            </li>

                          ))}
                          <li>

                          </li>
                        </ul>
                      </div>

                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Candidate Dashboard Area End --> */}

      {/* <!-- Footer Area Start --> */}

      <Footer></Footer>
    </div>
  );
}
export default Candidate_Applications;
