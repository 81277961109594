import React, { useEffect, useState } from 'react';
import '../App.css'; // You can import your CSS file here
import { FaGavel, FaLock, FaPowerOff, FaTachometerAlt, FaThumbsUp, FaUserAlt, FaUsers } from "react-icons/fa";
import { CgNotes } from "react-icons/cg";
import { Link, useNavigate } from 'react-router-dom';
import { BsSaveFill, BsSearch } from 'react-icons/bs';
import axios from 'axios';
import { LOGOUT_URL } from '../Config/Constants';
import Swal from 'sweetalert2';
function CandidateSidebar() {

    const [activeLink, setActiveLink] = useState('');

    useEffect(() => {
        // Get the current URL and set the active link based on it
        setActiveLink(window.location.pathname);
    }, []);

    const navigate = useNavigate();
    
    

      const handleLogout = async () => {

        // const token = localStorage.getItem("token")

        // try {
        //     const Response = await axios({
        //         method: "post",
        //         url: `${LOGOUT_URL}`,
        //         headers: {
        //             Authorization:token,
                    
        //         },
               
        //     })

        //     console.log("logout",Response.data);
           
        
          
        //   if (Response.data.success == true) {
           
            localStorage.removeItem('token');
            localStorage.removeItem("alti_type")

            navigate("/")

            Swal.fire("Success", "Logged Out successfully!!")

            setTimeout(() => {
                Swal.close();
    
            }, 1500);

        //   } 
        // } catch (error) {
          
        //   console.log('Error while logging out Candidate', error);
        // }
      };

    return (

        <div>
            <div class="dashboard-left">
                <ul class="dashboard-menu">
                    <li className={activeLink === '/candidate_dashboard' ? 'active' : ''}>
                        <Link to="/candidate_dashboard">
                
                            <FaTachometerAlt class="tachometer"></FaTachometerAlt>
                            Dashboard
                        </Link>
                    </li>
                    {/* <li className={activeLink === 'candidate_dashboard' ? 'active' : ''}>
                        <FaTachometerAlt class="tachometer"></FaTachometerAlt>
                        <Link to="candidate_dashboard">Dashboard</Link>
                    </li> */}


                    <li className={activeLink === '/candidate_profile' ? 'active' : ''}><Link to="/candidate_profile">  <FaUsers class="tachometer"></FaUsers>My Profile</Link></li>
                    <li className={activeLink === '/candidate_upload_resume' ? 'active' : ''}><Link to="/candidate_upload_resume">  <CgNotes class="tachometer"></CgNotes>Upload Resume</Link></li>
                    <li className={activeLink === '/candidate_applications' ? 'active' : ''}><Link to="/candidate_applications">  <CgNotes class="tachometer"></CgNotes>Applications</Link></li>
                    <li className={activeLink === '/candidate_changepassword' ? 'active' : ''}><Link to="/candidate_changepassword">  <FaLock class="tachometer"></FaLock>Change Password</Link></li>

                    <li className={activeLink === '/search_jobs' ? 'active' : ''}><Link to="/search_jobs">  <BsSearch class="tachometer"></BsSearch>Search Jobs</Link></li>
                    <li className={activeLink === '/candidate_saved_jobs' ? 'active' : ''}><Link to="/candidate_saved_jobs">  <BsSaveFill class="tachometer"></BsSaveFill>Saved Jobs</Link></li>


                    <li><a className="tachometer1" onClick={handleLogout} ><FaPowerOff className="tachometer"></FaPowerOff>LogOut</a></li>
                </ul>
            </div>



        </div>

    )

}


export default CandidateSidebar;
