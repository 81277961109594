import React, { useState } from "react";
import "../../App.css"; // You can import your CSS file here
import {
  FaGavel,
  FaMinus,
  FaPlus,
  FaTachometerAlt,
  FaThumbsUp,
  FaUserAlt,
  FaUsers,
} from "react-icons/fa";
import CandidateHeader from "../../Components/candidateheader";
import CandidateSidebar from "../../Components/candidateSidebar";
import Footer from "../../Components/footer";
import { Button } from "@adobe/react-spectrum";
import {
  CITIRES_URL,
  STATE_URL,
  UPLOADRESUME_URL,
} from "../../Config/Constants";
import { useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Select from "react-select";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
function Candidate_UpResume() {
  const [skilldata, setSkillData] = useState([]);
  const [catedatas, setCateDatas] = useState([]);
  const [jobcategoryy, setJobcategoryy] = useState([]);
  const [skillss, setSkillss] = useState([]);

  const [selectedJobIds, setSelectedJobIds] = useState([]);
  const [selectedSkillIds, setSelectedSkillIds] = useState([]);

  const changeJobcategory = (e) => {

    //

    // console.log("filterdata job", filterdata);

    setJobcategoryy(e);

  };

  const changeskills = (e) => {

    // const filterdata = e.map((e) => e.value)
    // console.log("filterdata skill", filterdata);
    setSkillss(e);
  };

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [formData, setFormData] = useState({});
  const [errorMessages, setErrorMessages] = useState({
    state_id: "",
    city_id: "",
  });
  useEffect(() => {
    const stateData = async () => {
      try {
        // Fetch states data
        const response = await axios({
          method: "get",
          url: `${STATE_URL}`,
        });
        setStates(response.data.states);
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };

    stateData();

    profileGet();
  }, []);
  const handleInputChange1 = async (field, event) => {
    const value = event;
    setUploadResumee((prevData) => ({ ...prevData, [field]: value }));
    // setErrorMessages((prevErrors) => ({ ...prevErrors, [field]: "" }));

    if (field === "state_id") {
      try {
        // Fetch cities data based on selected state
        const response = await axios({
          method: "get",
          url: ` https://api.altimatixjobs.ifinitesolutions.com/api/states/${value}/cities`,
        });
        setCities(response.data);
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    }
  };
  const [educations, setEducations] = useState([
    {
      id: null,
      degree: "",
      institude: "",
      addtion_information: "",
      from: "",
      to: "",
    },
  ]);

  const [experiences, setExperiences] = useState([
    {
      id: null,
      job_post: "",
      company: "",
      additonal_informatiion: "",
      from: "",
      to: "",
    },
  ]);

  const [error, setError] = useState({});
  const [ResumeInput, setResumeInput] = useState("")

  const [uploadResumee, setUploadResumee] = useState({
    profesional_title: "",
    language: "",
    qualification: "",
    total_year_experience: "",
    category: "",
    skill_required: "",
    expected_job_level: "",
    state_id: "",
    city_id: "",
    // addtion_information: '',
    // institude: '',
    description: "",
    // from: '',
    // to: '',
    job_post: "",
    // degree: '',
    additonal_informatiion: "",
    company: "",
    resume: "",
    job_ids: "",
    skill_ids: "",
  });

  const handleExpericene = (name, value, index) => {
    // Log the entered data to the console



    var data = [...experiences];

    if (name == "job_post") {
      data[index].job_post = value;
    } else if (name == "company") {
      data[index].company = value;
    } else if (name == "from") {

      data[index].from = value;

    } else if (name == "to") {

      data[index].to = value;
    } else {
      data[index].additonal_informatiion = value;
    }
    console.log("data data:", data);

    setExperiences(data);
    // Update the state with the entered data
    // setInputValue(e.target.value);
  };
  // console.log("educations data:", educations);

  const handleDegreeChange = (name, value, index) => {
    // Log the entered data to the console



    // console.log("Entered data:", value);

    var data = [...educations];

    if (name == "degree") {
      data[index].degree = value;
    } else if (name == "institude") {
      data[index].institude = value;
    } else if (name == "from") {

      data[index].from = value;
    } else if (name == "to") {

      data[index].to = value;
    } else {
      data[index].addtion_information = value;
    }

    setEducations(data);





    // Update the state with the entered data
    // setInputValue(e.target.value);
  };

  //   const [states, setStates] = useState([]);
  //   const [cities, setCities] = useState([]);
  //   const [filteredCities, setFilteredCities] = useState([]);

  const handleInputChange = (key, e) => {
    if (key == "resume") {
      // setUploadResumee({
      //   ...uploadResumee,
      //   [key]: e.target.files[0],
      // });
      setResumeInput(e.target.files[0])
    } else {
      setUploadResumee({
        ...uploadResumee,
        [key]: e.target.value,
      });
    }
    // if (key === "state_id") {
    //   // Filter cities based on the selected state
    //   const filteredCities = cities.filter((city) => city.state_id === value);
    //   setFilteredCities(filteredCities);
    // }
  };

  //   useEffect(() => {
  //     profileGet();

  //     fetchCities();
  //   }, []);

  //   const fetchCities = async () => {
  //     const token = localStorage.getItem("token");

  //     try {
  //       const Response = await axios({
  //         method: "get",
  //         url: `${CITIRES_URL}`,
  //         headers: {
  //           Authorization: token,
  //           "content-type": "multipart/form-data",
  //         },
  //       });

  //       setCities(Response.data);
  //     } catch (error) {
  //       console.error("Error fetching cities:", error);
  //     }
  //   };

  const profileGet = async () => {
    const token = localStorage.getItem("token");

    try {
      const Response = await axios({
        method: "get",
        url: `${UPLOADRESUME_URL}/edit`,
        headers: {
          Authorization: token,
          "content-type": "multipart/form-data",
        },
      });
      // console.log("requirements skills====>", Response.data.skills);
      // setData(Response.data.skills);
      // setDatas(Response.data.categories);
      console.log("Response.data.candidate====>", Response.data.candidate.experiences
      );

      // setExperiences(Response.data.candidate.experiences)

      const educations = Response.data.candidate.educations.map((e) => ({
        id: e.id,
        degree: e.degree,
        institude: e.institude,
        addtion_information: e.addtion_information,
        from: new Date(e.from),
        to: new Date(e.to),
      }));

      setEducations(educations)

      const experiences = Response.data.candidate.experiences.map((e) => ({
        id: e.id,
        company: e.company,
        job_post: e.job_post,
        additonal_informatiion: e.additonal_informatiion,
        from: new Date(e.from),
        to: new Date(e.to),
      }));
      setExperiences(experiences)

      // console.log("Response.educations", educations);


      const sk = Response.data.candidate.skills.map((e) => ({
        label: e.name,
        value: e.id,
      }));


      setSkillss(sk);

      const cat = Response.data.candidate.categories.map((e) => ({
        label: e.name,
        value: e.id,
      }));

      setJobcategoryy(cat);


      const modifiedData = Object.fromEntries(
        Object.entries(Response.data.candidate).map(([key, value]) => [key, value === null ? '' : value])
      );

      setUploadResumee(modifiedData);

      const array = Response.data.skills.map((e) => ({
        label: e.name,
        value: e.id,
      }));
      console.log("array", array);

      setSkillData(array);

      const array1 = Response.data.categories.map((e) => ({
        label: e.name,
        value: e.id,
      }));

      setCateDatas(array1);
      if (Response.data.candidate.state_id) {
        handleInputChange1("state_id", Response.data.candidate.state_id)

      }

      //   setStates(Response.data.states);
      //   console.log(Response.data.states);
      //   setCities(Response.data.citys);

      //   if (Response.data.state_id) {
      //     const filteredCities = cities.filter(
      //       (city) => city.state_id === Response.data.state_id
      //     );
      //     setFilteredCities(filteredCities);
      //   }
    } catch (err) {
      console.log("errr====>", err);
    }
  };
  // skill job skill1 skill

  console.log(" experiencesexperiences", experiences);
  const handleSubmit = async (e) => {
    const token = localStorage.getItem("token");

    // console.log("skillss....", skillss);
    // console.log("jobcategoryy....", jobcategoryy.length);

    const filtercat = jobcategoryy.map((e) => e.value)
    const filterskill = skillss.map((e) => e.value)



    const educations_filter = educations.map((e) => ({
      id: e.id,
      degree: e.degree,
      institude: e.institude,
      addtion_information: e.addtion_information,
      from: moment(e.from).format('MM/DD/YYYY'),
      to: moment(e.to).format('MM/DD/YYYY'),
    }));

    // console.log(" educations", educations);

    const experiences_filter = experiences.map((e) => ({
      id: e.id,
      company: e.company,
      job_post: e.job_post,
      additonal_informatiion: e.additonal_informatiion,
      from: moment(e.from).format('MM/DD/YYYY'),
      to: moment(e.to).format('MM/DD/YYYY'),
    }));
    // console.log(" uploadResumee.resume", uploadResumee.resume);
    console.log(" experiences_filter", experiences_filter);
    const formData = new FormData();

    formData.append("profesional_title", uploadResumee.profesional_title);
    formData.append("language", uploadResumee.language);
    formData.append("qualification", uploadResumee.qualification);
    formData.append(
      "total_year_experience",
      uploadResumee.total_year_experience
    );
    formData.append("job_ids", JSON.stringify(filtercat));
    formData.append("skill_ids", JSON.stringify(filterskill));
    formData.append("expected_job_level", uploadResumee.expected_job_level);
    formData.append("state_id", uploadResumee.state_id);
    formData.append("city_id", uploadResumee.city_id);

    formData.append("description", uploadResumee.description);

    formData.append("educations", JSON.stringify(educations_filter));
    formData.append("experiences", JSON.stringify(experiences_filter));
    formData.append("job_post", uploadResumee.job_post);
    formData.append(
      "additonal_informatiion",
      uploadResumee.additonal_informatiion
    );
    formData.append("company", uploadResumee.company);
    formData.append("resume", ResumeInput);

    formData.append("_method", "PUT");


    console.log("formData", formData);


    try {
      const Response = await axios({
        method: "POST",
        url: `${UPLOADRESUME_URL}`,
        headers: {
          Authorization: token,
          "content-type": "multipart/form-data",
        },
        data: formData,
      });

      console.log("fgghfghfgh", Response.data);

      console.log("Updated successfully");

      Swal.fire("Success", "Updated successfully!!");
      setError({});
      setTimeout(() => {
        Swal.close();
      }, 1500);

      profileGet();
    } catch (err) {
      console.log("errr====>", err.response.data);
      if (err.response.status == 422) {
        setError(err.response.data.errors);
      }
      // else {
      //   alert("Server Error")
      // }

    }
  };

  // Above is API fetch

  const addEducation = () => {
    const newEducation = {
      id: null,
      degree: "",
      institude: "",
      addtion_information: "",
      from: "",
      to: "",
    };

    setEducations([...educations, newEducation]);
  };

  const removeEducation = (index) => {
    const updatedEducations = [...educations];
    updatedEducations.splice(index, 1);
    setEducations(updatedEducations);
  };

  const addExperience = () => {
    const newExperience = {
      id: null,
      job_post: "",
      company: "",
      additonal_informatiion: "",
      from: "",
      to: "",

    };
    setExperiences([...experiences, newExperience]);
  };

  const removeExperience = (index) => {
    const updatedExperiences = [...experiences];
    updatedExperiences.splice(index, 1);
    setExperiences(updatedExperiences);
  };

  return (
    <div>
      {/* <!-- Header Area Start --> */}
      <CandidateHeader />
      {/* <!-- Header Area End --> */}

      {/* <!-- Breadcromb Area Start --> */}
      <section className="jobguru-breadcromb-area">
        <div className="breadcromb-top section_100">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="breadcromb-box">
                  <h3>Upload Resume</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="breadcromb-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="breadcromb-box-pagin">
                  <ul>
                    <li>
                      <Link to="#">home</Link>
                    </li>
                    <li>
                      <Link to="#">candidates</Link>
                    </li>
                    <li className="active-breadcromb">
                      <Link to="#">Upload Resume</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Breadcromb Area End --> */}

      {/* <!-- Candidate Dashboard Area Start --> */}
      <section className="candidate-dashboard-area section_70">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4 dashboard-left-border">
              <CandidateSidebar />
            </div>
            <div className="col-lg-9 col-md-8">
              <div className="dashboard-right">
                <div className="candidate-profile">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="submit-resume-box">
                        <form>
                          <div className="resume-box">
                            <div className="single-resume-feild resume-avatar">
                              {/* <div className="resume-image">
                                                                <img src={require("../../img/resume-avatar.jpg")} alt="resume avatar" />
                                                                <div className="resume-avatar-hover">
                                                                    <div className="resume-avatar-upload">
                                                                        <p>
                                                                            <i className="fa fa-upload"></i>
                                                                            upload
                                                                        </p>
                                                                        <input type="file" />
                                                                    </div>
                                                                </div>
                                                            </div> */}
                            </div>
                            <h3>General Information</h3>
                            <div className="single-resume-feild feild-flex-2">
                              <div className="single-input">
                                <label>Professional title:</label>
                                <input
                                  type="text"
                                  placeholder="e.g Web Developer"
                                  name="profesional_title"
                                  value={uploadResumee.profesional_title}
                                  onChange={(e) =>
                                    handleInputChange("profesional_title", e)
                                  }
                                />
                                <span style={{ color: "red", fontSize: 13 }}>
                                  {error.profesional_title}
                                </span>
                              </div>
                              <div className="single-input">
                                <label>Language:</label>
                                <input
                                  type="text"
                                  placeholder="eg: Java"
                                  name="language"
                                  value={uploadResumee.language}
                                  onChange={(e) =>
                                    handleInputChange("language", e)
                                  }
                                />
                                <span style={{ color: "red", fontSize: 13 }}>
                                  {error.language}
                                </span>
                              </div>
                            </div>
                            <div className="single-resume-feild feild-flex-2">
                              <div className="single-input">
                                <label>Qualifcation:</label>
                                <input
                                  type="text"
                                  placeholder="e.g BE"
                                  name="qualification"
                                  value={uploadResumee.qualification}
                                  onChange={(e) =>
                                    handleInputChange("qualification", e)
                                  }
                                />
                                <span style={{ color: "red", fontSize: 13 }}>
                                  {error.qualification}
                                </span>
                              </div>
                              <div className="single-input">
                                <label>Total Year Experience:</label>
                                <input
                                  type="text"
                                  placeholder="e.g 1 ,2"
                                  name="total_year_experience"
                                  value={uploadResumee.total_year_experience}
                                  onChange={(e) =>
                                    handleInputChange(
                                      "total_year_experience",
                                      e
                                    )
                                  }
                                />
                                <span style={{ color: "red", fontSize: 13 }}>
                                  {error.total_year_experience}
                                </span>
                              </div>
                            </div>

                            <div className="single-resume-feild feild-flex-2">
                              <div className="single-input">
                                <label>Category:</label>

                                <Select
                                  className="city-style1"
                                  placeholder="[-Select Category-]"
                                  isMulti
                                  value={jobcategoryy}
                                  onChange={(e) => changeJobcategory(e)}
                                  options={catedatas}
                                />

                                <span style={{ color: "red", fontSize: 13 }}>
                                  {error.job_ids}
                                </span>
                              </div>
                              <div className="single-input">
                                <label>Skill Required:</label>

                                <Select
                                  className="city-style1"
                                  placeholder="[-Select Skill-]"
                                  isMulti
                                  value={skillss}
                                  onChange={(e) => changeskills(e)}
                                  options={skilldata}
                                />

                                <span style={{ color: "red", fontSize: 13 }}>
                                  {error.skill_ids}
                                </span>
                              </div>
                            </div>
                            <div className="single-resume-feild feild-flex-2">
                              <div className="single-input">
                                <label>Expected Job level:</label>
                                <input
                                  type="text"
                                  placeholder="e.g Junior"
                                  name="expected_job_level"
                                  value={uploadResumee.expected_job_level}
                                  onChange={(e) =>
                                    handleInputChange("expected_job_level", e)
                                  }
                                />
                                <span style={{ color: "red", fontSize: 13 }}>
                                  {error.expected_job_level}
                                </span>
                              </div>

                              <div className="single-input">
                                <label>State:</label>
                                <select
                                  name="state"
                                  value={uploadResumee.state_id}
                                  onChange={(e) =>
                                    handleInputChange1("state_id", e.target.value)
                                  }
                                >
                                  <option
                                    value="-Select State-"
                                    className="state-dropdown"
                                  >
                                    -Select State-
                                  </option>

                                  {states.map((state) => (
                                    <option key={state.id} value={state.id}>
                                      {state.name}
                                    </option>
                                  ))}
                                </select>
                                {errorMessages.password && (
                                  <div className="error-message">
                                    {errorMessages.state_id}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="single-resume-feild feild-flex-2">
                              <div className="single-input">
                                <label>City:</label>
                                <select
                                  className="city-style1"
                                  name="city"
                                  value={uploadResumee.city_id}
                                  onChange={(e) =>
                                    handleInputChange1("city_id", e.target.value)
                                  }
                                >
                                  <option
                                    value="-Select State-"
                                    className="state-dropdown"
                                  >
                                    -Select City-
                                  </option>

                                  {cities.map((city) => (
                                    <option key={city.id} value={city.id}>
                                      {city.name}
                                    </option>
                                  ))}
                                </select>
                                {errorMessages.password && (
                                  <div className="error-message">
                                    {errorMessages.city_id}
                                  </div>
                                )}
                              </div>
                              <div className="single-input">
                                <label>Upload Resume:</label>
                                <input
                                  type="file"
                                  onChange={(e) =>
                                    handleInputChange("resume", e)
                                  }
                                />
                                <span style={{ color: "red", fontSize: 13 }}>
                                  {error.resume}
                                </span>
                              </div>
                            </div>
                            <div className="single-resume-feild">
                              <div className="single-input">
                                <label>Introduce Yourself:</label>
                                <textarea
                                  placeholder="Write Your Bio Here..."
                                  name="description"
                                  value={uploadResumee.description}
                                  onChange={(e) =>
                                    handleInputChange("description", e)
                                  }
                                ></textarea>
                                <span style={{ color: "red", fontSize: 13 }}>
                                  {error.description}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="resume-box">
                            <h3>Education</h3>
                            {educations.map((education, index) => (
                              <div>
                                {educations.length > 1 ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "end",
                                    }}
                                  >
                                    <div
                                      variant="contained"
                                      color="primary"
                                      className="minus-button"
                                      onClick={() => removeEducation(index)}
                                    >
                                      <FaMinus />
                                    </div>
                                  </div>
                                ) : null}
                                <div className="single-resume-feild ">
                                  <div className="single-input">
                                    <label>Degree:</label>
                                    <input
                                      type="text"
                                      placeholder="Degree's Title"
                                      name="degree"
                                      value={education.degree}
                                      onChange={(e) =>
                                        handleDegreeChange("degree", e.target.value, index)
                                      }
                                    />
                                    <span style={{ color: "red", fontSize: 13 }}>
                                      {error[`educations.${index}.degree`]}
                                    </span>
                                  </div>
                                </div>
                                <div className="single-resume-feild feild-flex-2">
                                  <div className="single-input">
                                    <label>From:</label>
                                    {/* <input
                                      type="date"
                                      placeholder="02.10.2015"
                                      name="from"
                                      onChange={(e) =>
                                        handleDegreeChange(e, index)
                                      }
                                      className="datepicker"
                                    /> */}
                                    <DatePicker
                                      selected={education.from}
                                      onChange={(e) => handleDegreeChange("from", e, index)}
                                      dateFormat="MM/dd/yyyy" // Set your desired date format
                                      placeholderText="MM/DD/YYYY" // Placeholder text
                                    />
                                    <span style={{ color: "red", fontSize: 13 }}>
                                      {error[`educations.${index}.from`]}
                                    </span>
                                  </div>
                                  <div className="single-input">
                                    <label>To:</label>
                                    {/* <input
                                      type="date"
                                      placeholder="06.11.2017"
                                      name="to"
                                      onChange={(e) =>
                                        handleDegreeChange(e, index)
                                      }
                                      className="datepicker"
                                    /> */}
                                    <DatePicker
                                      selected={education.to}
                                      onChange={(e) => handleDegreeChange("to", e, index)}
                                      dateFormat="MM/dd/yyyy" // Set your desired date format
                                      placeholderText="MM/DD/YYYY" // Placeholder text
                                    />
                                    <span style={{ color: "red", fontSize: 13 }}>
                                      {error[`educations.${index}.to`]}
                                    </span>
                                  </div>
                                </div>
                                <div className="single-resume-feild ">
                                  <div className="single-input">
                                    <label> Institute:</label>
                                    <input
                                      type="text"
                                      placeholder="Institution Name"
                                      name="institude"
                                      value={education.institude}
                                      onChange={(e) => handleDegreeChange("institude", e.target.value, index)}

                                    />
                                    <span style={{ color: "red", fontSize: 13 }}>
                                      {error[`educations.${index}.institude`]}
                                    </span>
                                  </div>
                                </div>
                                <div className="single-resume-feild ">
                                  <div className="single-input">
                                    <label>
                                      Additional Information:{" "}
                                      {/* <span>(optional)</span> */}
                                    </label>
                                    <textarea
                                      placeholder="Education Experience Brief"
                                      name="addtion_information"
                                      value={education.addtion_information}
                                      onChange={(e) => handleDegreeChange("addtion_information", e.target.value, index)}

                                    ></textarea>
                                    <span style={{ color: "red", fontSize: 13 }}>
                                      {error[`educations.${index}.addtion_information`]}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}

                            <div
                              variant="contained"
                              color="primary"
                              className="add-button"
                              onClick={addEducation}
                            >
                              <FaPlus />

                              <span style={{ marginLeft: "8px" }}>
                                Add Education
                              </span>
                            </div>
                          </div>

                          <div className="resume-box">
                            <h3>Work Experience</h3>

                            {experiences.map((experience, index) => (
                              <div>
                                {experiences.length > 1 ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "end",
                                    }}
                                  >
                                    <div
                                      variant="contained"
                                      color="primary"
                                      className="minus-button"
                                      onClick={() => removeExperience(index)}
                                    >
                                      <FaMinus />
                                    </div>
                                  </div>
                                ) : null}

                                <div className="single-resume-feild ">
                                  <div className="single-input">
                                    <label>Job Post:</label>
                                    <input
                                      type="text"
                                      placeholder="Job Post Title"
                                      name="job_post"
                                      onChange={(e) => handleExpericene("job_post", e.target.value, index)}
                                      value={experience.job_post}
                                    />
                                    <span style={{ color: "red", fontSize: 13 }}>
                                      {error[`experiences.${index}.job_post`]}
                                    </span>
                                  </div>
                                </div>
                                <div className="single-resume-feild feild-flex-2">
                                  <div className="single-input">
                                    <label>From:</label>
                                    {/* <input
                                      type="date"
                                      placeholder="02-10-2015"
                                      className="datepicker"
                                      name="from"
                                      onChange={(e) =>
                                        handleExpericene(e, index)
                                      }
                                    /> */}
                                    <DatePicker
                                      selected={experience.from}
                                      onChange={(e) => handleExpericene("from", e, index)}
                                      dateFormat="MM/dd/yyyy" // Set your desired date format
                                      placeholderText="MM/DD/YYYY" // Placeholder text

                                    />
                                    <span style={{ color: "red", fontSize: 13 }}>
                                      {error[`experiences.${index}.from`]}
                                    </span>

                                  </div>
                                  <div className="single-input">
                                    <label>To:</label>
                                    {/* <input
                                      type="date"
                                      placeholder="06-11-2017"
                                      className="datepicker"
                                      name="to"
                                      onChange={(e) =>
                                        handleExpericene(e, index)
                                      }
                                    /> */}
                                    <DatePicker
                                      selected={experience.to}
                                      onChange={(e) => handleExpericene("to", e, index)}
                                      dateFormat="MM/dd/yyyy" // Set your desired date format
                                      placeholderText="MM/DD/YYYY" // Placeholder text
                                    />
                                    <span style={{ color: "red", fontSize: 13 }}>
                                      {error[`experiences.${index}.to`]}
                                    </span>
                                  </div>
                                </div>
                                <div className="single-resume-feild ">
                                  <div className="single-input">
                                    <label> Company:</label>
                                    <input
                                      type="text"
                                      placeholder="Company Name"
                                      name="company"
                                      onChange={(e) => handleExpericene("company", e.target.value, index)}
                                      value={experience.company}

                                    />
                                    <span style={{ color: "red", fontSize: 13 }}>
                                      {error[`experiences.${index}.company`]}
                                    </span>
                                  </div>
                                </div>
                                <div className="single-resume-feild ">
                                  <div className="single-input">
                                    <label>
                                      Additional Information:{" "}
                                      {/* <span>(optional)</span> */}
                                    </label>
                                    <textarea
                                      placeholder="Work Experience Brief"
                                      name="additonal_informatiion"
                                      onChange={(e) => handleExpericene("additonal_informatiion", e.target.value, index)}
                                      value={experience.additonal_informatiion}
                                    ></textarea>
                                    <span style={{ color: "red", fontSize: 13 }}>
                                      {error[`experiences.${index}.additonal_informatiion`]}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))}

                            <div
                              variant="contained"
                              color="primary"
                              className="add-button"
                              onClick={addExperience}
                            >
                              <FaPlus />

                              <span style={{ marginLeft: "8px" }}>
                                Add Experience
                              </span>
                            </div>
                          </div>
                          {/* <div class="resume-box">
                                                        <h3>Skills & Portfolio</h3>
                                                        <div class="single-resume-feild ">
                                                            <div class="single-input">
                                                                <label for="skill">Skills:</label>
                                                                <input type="text" placeholder="Comma separate a list of relevant skills" id="skill" />
                                                            </div>
                                                        </div>
                                                        <div class="single-resume-feild feild-flex-2">
                                                            <div class="single-input">
                                                                <label for="Portfolio">Portfolio:</label>
                                                                <input type="text" placeholder="Portfolio Demo URL" id="Portfolio" />
                                                            </div>
                                                            <div class="single-input">
                                                                <label for="w_screen">Works Screenshot</label>
                                                                <div class="product-upload">
                                                                    <p>
                                                                        <i class="fa fa-upload"></i>
                                                                        Max file size is 3MB,Suitable files are .jpg & .png
                                                                    </p>
                                                                    <input type="file" id="w_screen" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                        </form>
                        <div className="submit-resume">
                          <button onClick={() => handleSubmit()}>
                            Save Resume
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Candidate Dashboard Area End --> */}

      {/* <!-- Footer Area Start --> */}

      <Footer></Footer>
    </div>
  );
}
export default Candidate_UpResume;
