import React, { useEffect, useState } from 'react';
import '../../App.css'; // You can import your CSS file here
import { FaGavel, FaMapMarkerAlt, FaSearch, FaTachometerAlt, FaThumbsUp, FaUserAlt, FaUsers } from "react-icons/fa";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import CandidateHeader from '../../Components/candidateheader';
import CandidateSidebar from '../../Components/candidateSidebar';
import Slider from 'react-slider';
import Footer from '../../Components/footer';
import { MdOutlineCategory } from 'react-icons/md';
import { IoMdCloudDownload } from "react-icons/io";
import { Link, useLocation } from 'react-router-dom';
import { CgNotes } from 'react-icons/cg';
import RecruiterSidebar from '../../Components/recruiterSidebr';
import { APPLICATION_UPDATE_URL, APPLICATION_URL, MANAGEJOBS_URL, SEARCH_APPLICATION, SEARCH_CANDI_URL } from '../../Config/Constants';
import axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import { BiCloudDownload } from 'react-icons/bi';

function Recruiter_ManageApp() {

    const [values, setValues] = useState([0, 100]);

    const handleChange = (newValues) => {
        setValues(newValues);
    };

    const location = useLocation();


    // const jobId = new URLSearchParams(location.search).get('job_id');
    // console.log("jobId",jobId)

    const [ApplicationList, setApplicationlist] = useState(null)
    const [status, setStatus] = useState("")


    const [title, setTitle] = useState("")
    const [statusset, setStatusset] = useState("")

    const [jobid, setJobid] = useState("")

    const [titleList, setTitleList] = useState([])

    const [page, setPage] = useState(1);
    useEffect(() => {


        GetStatuslist()

      

        if (location.state?.job_id) {
            console.log("location", location.state)
            setJobid(location.state.job_id)
            if (jobid && location.state.job_id) {
                JobListApi(page);
            }

        }
        else {
            JobListApi(page);
        }

    }, [jobid]);

    const JobListApi = async (value) => {
        const token = localStorage.getItem("token");
        try {
            const response = await axios({
                method: "get",
                url: `${APPLICATION_URL}page=` + value + "&filters[newJob.tittle]=" + title + "&filters[status]=" + statusset + "&new_job_id=" + jobid,
                headers: {
                    Authorization: token,
                },
            });


            console.log("Response", response.data);
            setApplicationlist(response.data)

        } catch (error) {
            console.log("Error fetching job:", error.response);
        }
    };

    const GetStatuslist = async (value) => {
        const token = localStorage.getItem("token");
        try {
            const response = await axios({
                method: "get",
                url: `${SEARCH_APPLICATION}`,
                headers: {
                    Authorization: token,
                },
            });


            console.log("Response ====>", response.data.newJob);
            setTitleList(response.data.newJob)

        } catch (error) {
            console.log("Error fetching job:", error.message);
        }
    };




    const StatusUpdate = async (value, id) => {

        Swal.fire({
            title: "Confirmation",
            text: `Are you sure want to ${value} this candidate?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
        }).then(async (result) => {
            if (result.isConfirmed) {

                setStatus(value)
                const token = localStorage.getItem("token");
                try {
                    const response = await axios({
                        method: "PUT",
                        url: `${APPLICATION_UPDATE_URL}` + id,
                        headers: {
                            Authorization: token,
                        },
                        data: {
                            "status": value
                        }
                    });


                    JobListApi(page);
                    Swal.fire({
                        title: "Success",
                        text: "Status updated successfully",
                        icon: "success",
                        timer: 2000, // Time in milliseconds
                        showConfirmButton: false,

                    });



                } catch (error) {
                    console.log("Error fetching job:", error.response.data);
                }

            }
        })


    };
    const Pagination = (page) => {
        JobListApi(page)
        setPage(page)
    }







    const ResumeDownload = async (id) => {

        const token = localStorage.getItem("token");
        console.log("id", id);


        try {
            const response = await axios({
                method: "GET",
                url: `${SEARCH_CANDI_URL}` + id + "/resume",
                headers: {
                    Authorization: token,
                },
                responseType: 'blob',

            });

            console.log("Response", response.data);

            saveAs(response.data, 'downloaded_resume.pdf');

            Swal.fire({
                title: "Success",
                text: "Resume Downloaded successfully",
                icon: "success",
                timer: 2000, // Time in milliseconds
                showConfirmButton: false,

            });



        } catch (error) {
            console.log("Error fetching job:", error.response.data);
            Swal.fire({
                title: "Error",
                text: "There's something wrong with this resume.",
                icon: "error",
                timer: 2000, // Time in milliseconds
                showConfirmButton: false,

            });
        }

    }


    return (

        <div>

            {/* <!-- Header Area Start --> */}
            {/* <RecruiterSidebar/> */}
            {/* <!-- Header Area End --> */}

            {/* <div className="col-lg-3 col-md-4 dashboard-left-border">
                            <RecruiterSidebar />
                        </div> */}
            {/* <!-- Breadcromb Area Start --> */}
            <section class="jobguru-breadcromb-area">
                <div class="breadcromb-top section_100">

                    <div class="container">
                        <div class="row">

                            <div class="col-md-12">
                                <div class="breadcromb-box">

                                    <h3>Manage Applications</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="breadcromb-bottom">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="breadcromb-box-pagin">
                                    <ul>
                                        <li><Link to="/">home</Link></li>
                                        <li><Link to="/recruiter_dashboard">Recruiter</Link></li>
                                        <li class="active-breadcromb"><Link to="#">Manage Applications</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Breadcromb Area End --> */}


            {/* <!-- Candidate Dashboard Area Start --> */}
            <section class="candidate-dashboard-area section_70">


                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 col-md-4 dashboard-left-border">
                            <RecruiterSidebar />
                        </div>
                        <div class="col-lg-9 col-md-8">
                            <div class="job-grid-right">
                                <div class="browse-job-head-option">

                                    <div className='row'>
                                        <div class="single-input col-sm-5 pdtop">

                                            <select onChange={(e) => setTitle(e.target.value)} >
                                                <option value=""> - Select Title -</option>
                                                {titleList.map((e, i) => (
                                                    < option value={e.tittle}>{e.tittle}</option>
                                                ))
                                                }

                                            </select>
                                        </div>
                                        <div class="single-input col-sm-5 pdtop ">
                                            <select onChange={(e) => setStatusset(e.target.value)}>
                                                <option value="">- Select Status -</option>
                                                <option value="Pending">Pending</option>
                                                <option value="Shortlist">Shortlist</option>
                                                <option value="Rejected">Rejected</option>


                                            </select>
                                        </div>



                                        <div class="single-input col-sm-2 pdtop">
                                            <button onClick={() => JobListApi(page)} className='search-btn-rec' >
                                                {/* <FaSearch /> */}
                                                Go
                                            </button>
                                        </div>

                                        {/* <div style={{ display: "flex", justifyContent: "end" }} className='col pdtop'>
                                            <button className='search-btn' >
                                                Go
                                            </button>
                                      
                                        </div> */}
                                    </div>



                                    {/* <div class="job-browse-search">
                                        <form>
                                            <input type="search" placeholder="Search Jobs Here..." />
                                            <button type="submit"><FaSearch /> </button>
                                        </form>
                                    </div>
                                    <div class="job-browse-action">
                                        <div class="email-alerts">
                                            <input type="checkbox" class="styled" id="b_1" />
                                            <label class="styled" for="b_1">email alerts for this search</label>
                                        </div>
                                        <div class="dropdown">
                                            <button class="btn-dropdown dropdown-toggle" type="button" id="dropdowncur" data-bs-toggle="dropdown" aria-haspopup="true">Sort By</button>
                                            <ul class="dropdown-menu" aria-labelledby="dropdowncur">
                                                <li>Newest</li>
                                                <li>Oldest</li>
                                                <li>Random</li>
                                            </ul>
                                        </div>
                                    </div> */}
                                </div>
                                {/* <!-- end job head --> */}
                                <div class="single-manage-jobs table-responsive backg">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Candidate</th>
                                                <th>Title</th>
                                                <th>Applied Date </th>
                                                <th>Expired Date </th>
                                                <th style={{ textAlign: "center" }}>Location </th>
                                                {/* <th style={{ textAlign: "center" }}>Applications</th> */}
                                                <th>Status</th>
                                                <th>CV</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ApplicationList?.data.map((e, i) => (
                                                <tr>
                                                    <td class="manage-jobs-title"><Link to={`/recruiter_candidatedetails/${e.user.id}`}>{e.user.user_name}</Link></td>

                                                    <td class="manage-jobs-title"  > <Link to={`/job_details/${e.new_job.id}`}>{e.new_job.tittle} </Link></td>
                                                    <td class="table-date"> {moment(e.new_job.created_at).format('MM-DD-YYYY')}</td>
                                                    <td class="table-date">{e.new_job.expire_date}</td>
                                                    <td style={{ textAlign: "center" }} class="table-date" >< FaMapMarkerAlt ></FaMapMarkerAlt> Chicago, Michigan</td>
                                                    {/* <td style={{ textAlign: "center" }} class="table-date">-</td> */}
                                                    <td>

                                                        {e.status == "Pending" ?
                                                            <div class="application col-sm-12 pdtop ">
                                                                <select value={status} onChange={(element) => StatusUpdate(element.target.value, e.id)} id="Region">
                                                                    <option value="">Pending</option>
                                                                    <option value="Shortlist">Shortlist</option>
                                                                    <option value="Rejected">Rejected</option>

                                                                </select>
                                                            </div>
                                                            :
                                                            <span class={e.status == "Rejected" ? "application_reject" : "application_shortlist"}>{e.status}</span>
                                                        }

                                                    </td>
                                                    <td class="action">
                                                        <Link onClick={() => ResumeDownload(e.user.id)} class="action-edit"><BiCloudDownload size={20}></BiCloudDownload></Link>
                                                    </td>
                                                </tr>
                                            ))
                                            }
                                        </tbody>
                                    </table>

                                </div>
                                {/* <!-- end job sidebar list --> */}
                                {ApplicationList?.data.length == 0 ?
                                    <tbody className="Not-found">

                                        <td className="jobdesc">No Data Found</td>
                                    </tbody>
                                    :

                                    <div className="pagination-box-row">
                                        <p>Page {ApplicationList?.from} of {ApplicationList?.to}</p>

                                        <ul className="pagination">
                                            {Array.from({ length: Math.ceil(((ApplicationList?.total) / (10))) }, (_, index) => (
                                                <li className={Number(ApplicationList?.current_page) == index + 1 ? "active" : ""}>
                                                    <Link
                                                        onClick={() => Pagination(index + 1)}
                                                    >{index + 1}</Link>
                                                </li>

                                            ))}
                                            <li>

                                            </li>
                                        </ul>
                                    </div>

                                }
                                {/* <!-- end pagination --> */}
                            </div>
                        </div>
                    </div>
                </div>



            </section >
            {/* <!-- Candidate Dashboard Area End --> */}


            {/* <!-- Footer Area Start --> */}


            <Footer></Footer>






        </div >

    )




}
export default Recruiter_ManageApp;
