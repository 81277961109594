import React from 'react';
import '../../App.css';
import { FaGavel, FaTachometerAlt, FaThumbsUp, FaUserAlt, FaUsers } from "react-icons/fa";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import CandidateHeader from '../../Components/candidateheader';
import CandidateSidebar from '../../Components/candidateSidebar';
import Footer from '../../Components/footer';
import { useState } from 'react';
import axios from 'axios';
import { Link, useHistory, useNavigate } from 'react-router-dom';
import { Alert } from '@mui/material';
import Swal from 'sweetalert2';
import { LOGIN_URL } from '../../Config/Constants';

// import { Link } from 'react-router-dom';
// 
// const history = useHistory();



function Login() {


    const navigate = useNavigate();

    const [error, setError] = useState({});


    const handleInputChange = (e) => {


        const { name, value } = e.target;


        setUser({
            ...user,
            [name]: value
        });
    };


    const [user, setUser] = useState({
        email: '',
        password: ''
    });

    const [loginResponse, setLoginResponse] = useState(null);
    // const [errorMessages, setErrorMessages] = useState({ password: '', email: '' });




    const handleSubmit = async (e) => {

        // console.log('display mail', user);

        //     e.preventDefault();
        //     try {
        //         const response = await axios.post('http://192.168.1.13:8000/api/userlogin', user);
        //         const data = response.data;
        //         console.log('display mail', user.email);
        //         setLoginResponse(data);
        //         console.log('data.type', data);

        //         if (data.success == true) {

        //             if (data.type == 'Recruiter') {

        //                 navigate('/recruiter/dashboard');
        //                 setTimeout(function () {
        //                     alert("Recruiter Logged in successfully");
        //                 }, 1500);

        //             }
        //             else {

        //                 navigate('/candidate/dashboard');

        //                 setTimeout(function () {
        //                     alert("Candidate Logged in successfully");
        //                 }, 1500);


        //             }



        //         }


        //     } 
        //     catch (error) {
        //         if (error.response) {
        //             const responseError = error.response.data.type;
        //             if (responseError.errors) {
        //                 setErrorMessages(responseError.errors);
        //             } else {
        //                 console.error('An error occurred during registration:', error);
        //             }
        //         }
        //     }
        // };

        // 





        try {
            const Response = await axios({
                method: "POST",
                url: `${LOGIN_URL}`,
                data: user
            })

            console.log("Response", Response.data)


            localStorage.setItem("token", Response.data.token)


            localStorage.setItem("alti_type", Response.data.type)

            const data = Response.data;




            if (data.type == 'Recruiter') {

                navigate('/recruiter/dashboard');
                Swal.fire("Success!", "Recruiter logged in Sucessfully!", "success")

                setTimeout(() => {
                    Swal.close();

                }, 1500);

            }
            else {

                navigate('/candidate_dashboard');

                Swal.fire("Success!", "Candidate logged in Sucessfully!", "success")

                setTimeout(() => {
                    Swal.close();

                }, 1500);



            }



        }

        catch (err) {


            console.log("errr====>", err.response.data.errors)

            setError(err.response.data.errors)
        }

    }





    return (

        <div>


            {/* <!-- Header Area Start --> */}
            <CandidateHeader />
            {/* <!-- Header Area End --> */}


            {/* <!-- Breadcromb Area Start --> */}
            <section className="jobguru-breadcromb-area">
                <div className="breadcromb-top section_100">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="breadcromb-box">
                                    <h3>Login</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="breadcromb-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="breadcromb-box-pagin">
                                    <ul>
                                        <li><Link to="/">home</Link></li>
                                        {/* <li><a href="#">pages</a></li> */}
                                        <li className="active-breadcromb"><Link to="/login">Login</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Breadcromb Area End --> */}


            {/* <!-- Login Area Start --> */}
            <section className="jobguru-login-area section_70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 offset-lg-4 col-sm-6 offset-sm-3">
                            <div className="login-box">
                                <div className="login-title">
                                    <h3>Sign in</h3>
                                </div>
                                <form >
                                    <div className="single-login-field">
                                        <input
                                            type="text"
                                            name="email"
                                            placeholder="Email Address"
                                            value={user.email}
                                            onChange={handleInputChange}
                                        />
                                        {/* {errorMessages.email && (
                                            <div className="error-message">{errorMessages.email}</div>
                                        )} */}

                                        <span style={{ color: "red", fontSize: 13 }}>{error.email}</span>
                                    </div>
                                    <div className="single-login-field">
                                        <input
                                            type="password"
                                            name="password"
                                            placeholder="Password"
                                            value={user.password}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        {/* {errorMessages.email && (
                                            <div className="error-message">{errorMessages.password}</div>
                                        )} */}

                                        <span style={{ color: "red", fontSize: 13 }}>{error.password}</span>
                                    </div>
                                    <div class="remember-row single-login-field clearfix">
                                        <p class="checkbox remember">
                                            <input class="checkbox-spin" type="checkbox"  />

                                            <Link to="/Forgot/Forgotpass" class="lost-pass1">forgot password?</Link>
                                        </p>

                                    </div>

                                    <div onClick={() => handleSubmit()} className="single-login-field">
                                        <button type='button' >Sign in</button>
                                    </div>



                                </form>





                                {/* {loginResponse && (
                                    <div className="login-response">
                                        <p>{loginResponse.message}</p>
                                    </div>
                                )} */}
                                {/* <form>
                                    <div class="single-login-field">
                                        <input type="email" placeholder="Email Address" />
                                    </div>
                                    <div class="single-login-field">
                                        <input type="password" placeholder="Password" />
                                    </div>
                                    
                                    <div class="single-login-field">
                                        <button type="submit">Sign in</button>
                                    </div>
                                </form> */}
                                <div className="dont_have">
                                    <Link to="/register">Don't have an account?</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* <!-- Footer Area Start --> */}
            <Footer></Footer>






        </div>

    )




}
export default Login;
