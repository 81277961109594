import React, { useState } from "react";
import "../../App.css"; // You can import your CSS file here
import {
  FaGavel,
  FaMapMarkerAlt,
  FaRegClock,
  FaSearch,
  FaTachometerAlt,
  FaThumbsUp,
  FaUserAlt,
  FaUsers,
  FaCalendarAlt
} from "react-icons/fa";
import { BsCheckLg, BsPencilSquare, BsTrash } from "react-icons/bs";
import CandidateHeader from "../../Components/candidateheader";
import CandidateSidebar from "../../Components/candidateSidebar";
import Slider from "react-slider";
import Footer from "../../Components/footer";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { CANDISAVEDJOB_URL, JOBSAVED_URL } from "../../Config/Constants";

function Candidate_SavedJobs() {
  const [values, setValues] = useState([0, 100]);

  const handleChange = (newValues) => {
    setValues(newValues);
  };
  const [page, setPage] = useState(1);

  const [savejob, setSavejob] = useState(null);

  useEffect(() => {
    ApplicationData(page);

  }, []);

  const ApplicationData = async (value) => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios({
        method: "get",
        url: `${CANDISAVEDJOB_URL}page=` + value,
        headers: {
          Authorization: token,
        },
      });
      console.log("response of savedjob", response.data.data);
      setSavejob(response.data);
    } catch (err) {
      console.log("Error fetching data:", err.response.data);
    }
  };


  const fetchData1 = async (id) => {
    const token = localStorage.getItem("token");

    try {
      const Response = await axios({
        method: "post",
        url: `${JOBSAVED_URL}` + id,
        headers: {
          Authorization: token,

        },
      });


      console.log(Response.data);
      ApplicationData(page);


    } catch (err) {
      console.log('Error message:', err.response.data);
    }
  };

  const Pagination = (page) => {
    ApplicationData(page)
    setPage(page)
  }






  return (
    <div>
      {/* <!-- Header Area Start --> */}
      <CandidateHeader />
      {/* <!-- Header Area End --> */}

      {/* <!-- Breadcromb Area Start --> */}
      <section className="jobguru-breadcromb-area">
        <div className="breadcromb-top section_100">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="breadcromb-box">
                  <h3>Saved Jobs</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="breadcromb-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="breadcromb-box-pagin">
                  <ul>
                    <li>
                      <Link to="#">home</Link>
                    </li>
                    <li>
                      <Link to="#">candidates</Link>
                    </li>
                    <li className="active-breadcromb">
                      <Link to="#">Saved Jobs</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Breadcromb Area End --> */}

      {/* <!-- Candidate Dashboard Area Start --> */}

      <section className="candidate-dashboard-area section_70">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4 dashboard-left-border">
              <CandidateSidebar />
            </div>
            <div className="col-lg-9 col-md-8">
              <div className="job-grid-right">
                {/* <!-- end job head --> */}
                <div className="job-sidebar-list-singl">
                  {savejob?.data.map((save) => (
                    <div className="sidebar-list-single" key={save.id}>
                      <div className="top-company-list">
                        <div className="company-list-logo">
                          <Link to="#">
                            <img
                              src={save.company.logo}
                              alt="company list 1"
                            />
                          </Link>
                        </div>
                        <div className="company-list-details">
                          <h3>
                            <Link to="#">{save.tittle}</Link>
                          </h3>
                          <p className="company-state">
                            <FaMapMarkerAlt className="map-icon"></FaMapMarkerAlt>{" "}
                            {save.state.name},{save.city.name}
                          </p>
                          <p className="open-icon">
                            <FaCalendarAlt className="clock-icon"></FaCalendarAlt>
                            {save.expire_date}
                          </p>
                          <p className="varify">
                            <BsCheckLg className="varify-icon"></BsCheckLg>Fixed
                            price : $1200-$2000
                          </p>

                          <p className="jobdesc">{save.job_description.slice(0, 100)

                          }</p>

                        </div>

                        <div className="company-list-btn">
                          <Link
                            className="jobguru-btn1"

                            to={`/job_details/${save.id}`}
                          >
                            View Details

                          </Link>
                          <Link
                            className="jobguru-btn1"

                            onClick={() => fetchData1(save.id)}
                          >
                            Remove
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* <!-- end sidebar single list --> */}
                </div>
                {savejob?.data.length == 0 ?
                  <div className="Not-found">

                    <p className="jobdesc">Not Found</p>
                  </div>
                  :

                  <div className="pagination-box-row">
                    <p>Page {savejob?.from} of {savejob?.to}</p>

                    <ul className="pagination">
                      {Array.from({ length: Math.ceil(((savejob?.total) / (10))) }, (_, index) => (
                        <li className={Number(savejob?.current_page) == index + 1 ? "active" : ""}>
                          <Link
                            onClick={() => Pagination(index + 1)}
                          >{index + 1}</Link>
                        </li>

                      ))}
                      <li>

                      </li>
                    </ul>
                  </div>
                }
                {/* <!-- end pagination --> */}
              </div>
            </div>
          </div>
        </div>
      </section >

      {/* <!-- Candidate Dashboard Area End --> */}

      {/* <!-- Footer Area Start --> */}

      <Footer></Footer>
    </div >
  );
}
export default Candidate_SavedJobs;
