import React, { useState } from "react";
import "../../App.css"; // You can import your CSS file here
import {
  FaGavel,
  FaTachometerAlt,
  FaThumbsUp,
  FaUserAlt,
  FaUsers,
} from "react-icons/fa";
import CandidateHeader from "../../Components/candidateheader";
import CandidateSidebar from "../../Components/candidateSidebar";
import Footer from "../../Components/footer";

import { useEffect } from "react";
import axios from "axios";
// import { Alert } from '@mui/material';
import Alert from "react-bootstrap/Alert";
import { PROFILE_URL } from "../../Config/Constants";
import Swal from "sweetalert2";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
function Candidate_Profile() {
  const [error, setError] = useState({});

  const [onImageUpload, setonImageUpload] = useState("");

  // const [radio, setRadio] = useState("")

  const [profileinputdata, setProfileinputdata] = useState({
    user_name: "",
    d_o_b: "",
    gender: "",
    phone_no: "",
    address: "",
    email: "",
    current_company: "",
    current_job: "",
    twitter: "",
    facebook: "",
    instagram: "",
    linkedin: "",
    image: "",

  });
  const [date_format, setdate_format] = useState("")

  const handleInputChange = (key, e) => {

   

    if (key == "d_o_b") {

      var date = moment(e).format('MM-DD-YYYY')

      setProfileinputdata({
        ...profileinputdata,
        [key]: date,
      });

      setdate_format(e)
    }
    else {
      const value = e.target.value;
      console.log("value", value);
      setProfileinputdata({
        ...profileinputdata,
        [key]: value,
      });
    }

  };

  // const handleRadioChange = (key, e) => {

  //     if (key == "gender") {
  //         setRadio((prevData) => ({
  //             ...prevData,
  //             [key]: e.value,
  //         }));
  //     }
  // }

  const handleImageChange = (event) => {
    const image = event.target.files[0];

    console.log("image", image);
    setonImageUpload(image);
  };

  useEffect(() => {
    profileGet();
  }, []);

  const profileGet = async () => {
    const token = localStorage.getItem("token");

    try {
      const Response = await axios({
        method: "get",
        url: `${PROFILE_URL}/profiles/edit`,
        headers: {
          Authorization: token,
          "content-type": "multipart/form-data",
        },
      });

      const modifiedData = Object.fromEntries(
        Object.entries(Response.data).map(([key, value]) => [key, value === null ? '' : value])
      );

      console.log("modifiedData====>", modifiedData);
      setdate_format(Response.data.d_o_b ? new Date(Response.data.d_o_b) : "")
      setProfileinputdata(modifiedData);


    } catch (err) {
      console.log("errr====>", err);
    }
  };

  const handleSubmit = async (e) => {
    const token = localStorage.getItem("token");

    // console.log('token', token);

    // console.log('onImageUpload', onImageUpload);

    // alert("ssss")
    // e.preventDefault();

    const formData = new FormData();

    formData.append("user_name", profileinputdata.user_name);
    formData.append("d_o_b", profileinputdata.d_o_b);
    formData.append("gender", profileinputdata.gender);
    formData.append("phone_no", profileinputdata.phone_no);
    formData.append("current_company", profileinputdata.current_company);
    formData.append("current_job", profileinputdata.current_job);
    formData.append("address", profileinputdata.address);
    formData.append("email", profileinputdata.email);
    formData.append("twitter", profileinputdata.twitter);
    formData.append("facebook", profileinputdata.facebook);
    formData.append("instagram", profileinputdata.instagram);
    formData.append("linkedin", profileinputdata.linkedin);

    formData.append("image", onImageUpload);

    formData.append("_method", "PUT");

    // const token = localStorage.getItem("token");
    console.log("profileinputdata", profileinputdata)

    try {
      const Response = await axios({
        method: "post",
        url: `${PROFILE_URL}/profiles`,
        headers: {
          Authorization: token,
          "content-type": "multipart/form-data",
        },
        data: formData,
      });

      console.log("Candidate Detail Updated successfully");

      Swal.fire("Success", "Updated successfully!!");
      setError({})
      setTimeout(() => {
        Swal.close();
      }, 1500);

      profileGet();

    } catch (err) {
      console.log("errr====>", err.response.data.errors);

      setError(err.response.data.errors);
    }
  };

  return (
    <div>
      {/* <!-- Header Area Start --> */}
      <CandidateHeader />
      {/* <!-- Header Area End --> */}

      {/* <!-- Breadcromb Area Start --> */}
      <section className="jobguru-breadcromb-area">
        <div className="breadcromb-top section_100">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="breadcromb-box">
                  <h3>Profile</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="breadcromb-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="breadcromb-box-pagin">
                  <ul>
                    <li>
                      <a href="#">home</a>
                    </li>
                    <li>
                      <a href="#">candidates</a>
                    </li>
                    <li className="active-breadcromb">
                      <a href="#">Profile</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Breadcromb Area End --> */}

      {/* <!-- Candidate Dashboard Area Start --> */}
      <section className="candidate-dashboard-area section_70">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4 dashboard-left-border">
              <CandidateSidebar />
            </div>
            <div className="col-lg-9 col-md-8">
              <div className="dashboard-right">
                <div className="candidate-profile">
                  <div className="candidate-single-profile-info">
                    <div className="single-resume-feild resume-avatar">
                      <div className="resume-image1">
                        <img
                          className=""
                          // src={profileinputdata.image}
                          src={
                            onImageUpload
                              ? URL.createObjectURL(onImageUpload)
                              : profileinputdata.image
                          }
                        />
                        <div className="resume-avatar-hovers">
                          <div className="resume-avatar-uploads">
                            <p>Edit</p>

                            <input
                              onChange={(e) => handleImageChange(e)}
                              type="file"
                              accept="image/*"
                            />
                          </div>
                        </div>
                      </div>

                      {/* <div className="resume-image1">
                                                {onImageUpload ? (
                                                    <img className="" src={URL.createObjectURL(onImageUpload)} alt="Profile" />
                                                ) : (
                                                    <img className="" src={profileinputdata.image} alt="Profile" />
                                                )}
                                                <div className="resume-avatar-hovers">
                                                    <div className="resume-avatar-uploads">
                                                        <p>Edit</p>
                                                        <input onChange={(e) => handleImageChange(e)} type="file" accept="image/*" />
                                                    </div>
                                                </div>
                                            </div> */}
                    </div>
                  </div>
                  <div className="candidate-single-profile-info">
                    <form>
                      <div className="resume-box">
                        <h3>My Profile</h3>
                        <div className="single-resume-feild feild-flex-2">
                          <div className="single-input">
                            <div style={{ display: "flex" }}>
                              <label style={{ marginRight: "5px" }}>
                                Name:
                              </label>
                              <h4 className="star-button1">*</h4>
                            </div>
                            <input
                              type="text"
                              name="user_name"
                              value={profileinputdata.user_name}
                              onChange={(e) =>
                                handleInputChange("user_name", e)
                              }
                            />
                            <span style={{ color: "red", fontSize: 13 }}>
                              {error.user_name}
                            </span>
                          </div>
                          <div className="single-input">
                            <div style={{ display: "flex" }}>
                              <label>Date of Birth:</label>
                              <h4 className="star-button">*</h4>
                            </div>
                            {/* <input
                              type="date"
                              name="d_o_b"
                              placeholder="MM/DD/YYYY" 
                              value={profileinputdata.d_o_b}
                              onChange={(e) => handleInputChange("d_o_b", e)}
                              
                            /> */}
                            <DatePicker
                              selected={date_format}
                              onChange={(e) => handleInputChange("d_o_b", e)}
                              dateFormat="MM/dd/yyyy" // Set your desired date format
                              placeholderText="MM/DD/YYYY" // Placeholder text
                            />
                            <span style={{ color: "red", fontSize: 13 }}>
                              {error.d_o_b}
                            </span>
                          </div>
                        </div>

                        <div className="single-resume-feild feild-flex-2">
                          <div className="single-input">
                            <div style={{ display: "flex" }}>
                              <label>Gender:</label>
                              <h4 className="star-button">*</h4>
                            </div>
                            <div className="gender">
                              <div className="form-check gender-radio ">
                                <input
                                  type="radio"
                                  name="gender"
                                  value="male"
                                  checked={profileinputdata.gender == "male"}
                                  onChange={(e) =>
                                    handleInputChange("gender", e)
                                  }
                                />

                                <label className="inline control-label male">
                                  Male
                                </label>
                              </div>
                              <div className="form-check gender-radio">
                                <input
                                  name="gender"
                                  type="radio"
                                  value="female"
                                  checked={profileinputdata.gender === "female"}
                                  onChange={(e) =>
                                    handleInputChange("gender", e)
                                  }
                                />
                                <label className="inline control-label male">
                                  Female
                                </label>
                              </div>

                            </div>
                            <span style={{ color: "red", fontSize: 13 }}>
                              {error.gender}
                            </span>
                          </div>
                          <div className="single-input">
                            <div style={{ display: "flex" }}>
                              <label>Phone:</label>
                              <h4 className='star-button'>*</h4>
                            </div>
                            <input
                              type="text"
                              name="phone_no"
                              value={profileinputdata.phone_no}
                              onChange={(e) => handleInputChange("phone_no", e)}
                            />
                            <span style={{ color: "red", fontSize: 13 }}>
                              {error.phone_no}
                            </span>
                          </div>
                        </div>

                        {/* <div className="single-resume-feild feild-flex-2">
                                                    <div className="single-input">
                                                        <label for="Region">Languages:</label>
                                                        <select id="Region">
                                                            <option selected>English</option>
                                                            <option>French</option>
                                                            <option>Bangla</option>
                                                        </select>
                                                    </div>
                                                    <div className="single-input">
                                                        <label for="Age">Age:</label>
                                                        <input type="text" value="25" id="Age" />
                                                    </div>
                                                </div> */}
                        <div className="single-resume-feild feild-flex-2">
                          <div className="single-input">
                            <label>Current Job :</label>
                            <input
                              type="text"
                              name="current_job"
                              value={profileinputdata.current_job}
                              onChange={(e) =>
                                handleInputChange("current_job", e)
                              }
                            />
                            <span style={{ color: "red", fontSize: 13 }}>
                              {error.current_job}
                            </span>
                          </div>
                          <div className="single-input">
                            <label> Current Company Name:</label>
                            <input
                              type="text"
                              name="current_company"
                              value={profileinputdata.current_company}
                              onChange={(e) =>
                                handleInputChange("current_company", e)
                              }
                            />
                            <span style={{ color: "red", fontSize: 13 }}>
                              {error.current_company}
                            </span>
                          </div>
                        </div>
                        {/* <div className="single-resume-feild ">
                                                    <div className="single-input">
                                                        <label for="Bio">Introduce Yourself:</label>
                                                        <textarea id="Bio">Maecenas is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</textarea>
                                                    </div>
                                                </div> */}
                      </div>
                      {/* <div className="resume-box"> */}
                      {/* <h3>Contact Information</h3> */}
                      <div className="single-resume-feild feild-flex-2">
                        <div className="single-input">
                          <div style={{ display: "flex" }}>
                            <label>Email:</label>
                            <h4 className="star-button">*</h4>
                          </div>
                          <input
                            type="text"
                            name="email"
                            value={profileinputdata.email}
                            onChange={(e) => handleInputChange("email", e)}
                            required
                          />
                          <span style={{ color: "red", fontSize: 13 }}>
                            {error.email}
                          </span>
                        </div>

                        <div className="single-input">
                          <div style={{ display: "flex" }}>
                            <label>Address:</label>
                            <h4 className='star-button'>*</h4>
                          </div>
                          <input
                            type="text"
                            name="address"
                            value={profileinputdata.address}
                            onChange={(e) => handleInputChange("address", e)}
                          />
                          <span style={{ color: "red", fontSize: 13 }}>
                            {error.address}
                          </span>
                        </div>
                      </div>

                      {/* </div> */}
                      <div className="resume-box">
                        <h3>social link</h3>
                        <div className="single-resume-feild feild-flex-2">
                          <div className="single-input">
                            <label>
                              <i className="fa fa-twitter twitter"></i>
                              twitter
                            </label>
                            <input
                              type="text"
                              placeholder="e.g :https://www.twitter.com/"
                              name="twitter"
                              value={profileinputdata.twitter}
                              onChange={(e) => handleInputChange("twitter", e)}
                            />
                            <span style={{ color: "red", fontSize: 13 }}>
                              {error.twitter}
                            </span>
                          </div>
                          <div className="single-input">
                            <label>
                              <i className="fa fa-facebook facebook"></i>
                              facebook
                            </label>
                            <input
                              type="text"
                              placeholder="e.g :https://www.facebook.com/"
                              name="twitter"
                              value={profileinputdata.facebook}
                              onChange={(e) => handleInputChange("facebook", e)}
                            />
                            <span style={{ color: "red", fontSize: 13 }}>
                              {error.facebook}
                            </span>
                          </div>
                        </div>
                        <div className="single-resume-feild feild-flex-2">
                          <div className="single-input">
                            <label>
                              <i className="fa fa-google-plus google"></i>
                              Instagram
                            </label>
                            <input
                              type="text"
                              placeholder="e.g :https://www.instagram.com/"
                              name="twitter"
                              value={profileinputdata.instagram}
                              onChange={(e) =>
                                handleInputChange("instagram", e)
                              }
                            />
                            <span style={{ color: "red", fontSize: 13 }}>
                              {error.instagram}
                            </span>
                          </div>
                          <div className="single-input">
                            <label>
                              <i className="fa fa-linkedin linkedin"></i>
                              LinkedIn
                            </label>
                            <input
                              type="text"
                              placeholder="e.g :https://www.linkedin.com/"
                              name="twitter"
                              value={profileinputdata.linkedin}
                              onChange={(e) => handleInputChange("linkedin", e)}
                            />
                            <span style={{ color: "red", fontSize: 13 }}>
                              {error.linkedin}
                            </span>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div className="submit-resume">
                    <button onClick={() => handleSubmit()}>Update</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Candidate Dashboard Area End --> */}

      {/* <!-- Footer Area Start --> */}

      <Footer></Footer>
    </div>
  );
}
export default Candidate_Profile;
