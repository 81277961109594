import React from 'react';
import '../App.css'; // You can import your CSS file here
import { FaGavel, FaThumbsUp, FaUserAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';
function CandidateHeader() {
    return (
        <div className="App">
            <header class="jobguru-header-area stick-top forsticky page-header">
                <div class="menu-animation">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-2">
                                <div class="site-logo">
                                    <Link to="/">
                                        <img style={{width:"112px"}} src={require("../img/altimate_logo.png")} alt="jobguru" />
                                    </Link>
                                </div>
                                {/* <!-- Responsive Menu Start --> */}
                                <div class="jobguru-responsive-menu"></div>
                                {/* <!-- Responsive Menu Start --> */}
                            </div>
                            <div class="col-lg-6 cus_txt">
                                <div class="header-menu">
                                    <nav id="navigation">
                                        <ul id="jobguru_navigation">
                                            <li class="active has-children">
                                                {/* <Link to="/">home</Link> */}
                                             
                                            </li>
                                           
                                           
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="header-right-menu">
                                    <ul>
                                        {/* <li><a href="#" class="post-jobs">Log out</a></li> */}
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

        </div>
    )
}
export default CandidateHeader;
