import React from 'react';
import '../../App.css';
import { FaGavel, FaTachometerAlt, FaThumbsUp, FaUserAlt, FaUsers } from "react-icons/fa";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import CandidateHeader from '../../Components/candidateheader';
import CandidateSidebar from '../../Components/candidateSidebar';
import Footer from '../../Components/footer';
import { useState } from 'react';
import axios from 'axios';
import { Link, useHistory, useNavigate } from 'react-router-dom';
import { Alert } from '@mui/material';
import Swal from 'sweetalert2';
import { FORGOTPASSWORD_URL, LOGIN_URL } from '../../Config/Constants';



function ForgotPassword() {


    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const handleForgotPassword = async () => {
        try {

            const Response = await axios({
                method: "POST",
                url: `${FORGOTPASSWORD_URL}`,
                data: {
                    "email": email
                }
            })


            console.log(Response.data);


            Swal.fire({
                icon: 'success',
                title: 'Password Reset Email Sent!',
                text: 'Please check your email for further instructions.',
            });
            setEmail('')

            setTimeout(() => {
                Swal.close();
            }, 1500);


        } catch (error) {

            console.log('Forgot Password Error:', error.response.status);
            setError(error.response.data.errors && error.response.data.errors)



        }
    };




    return (

        <div>


            {/* <!-- Header Area Start --> */}
            <CandidateHeader />
            {/* <!-- Header Area End --> */}


            {/* <!-- Breadcromb Area Start --> */}
            <section className="jobguru-breadcromb-area">
                <div className="breadcromb-top section_100">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="breadcromb-box">
                                    <h3>Forgot Password</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="breadcromb-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="breadcromb-box-pagin">
                                    <ul>
                                        <li>You are Here:</li>
                                        <li><Link to="/">home</Link></li>
                                        <li><Link to="/Forgot/Forgotpass">Forgot Password</Link></li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Breadcromb Area End --> */}


            {/* <!-- Login Area Start --> */}
            <section className="jobguru-login-area section_70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 offset-lg-4 col-sm-6 offset-sm-3">
                            <div className="login-box">
                                <div className="login-title">
                                    <h3>Forgot Password</h3>
                                </div>
                                <form >
                                    <div className="single-login-field">
                                        <input
                                            type="text"
                                            name="email"
                                            placeholder="Email Address"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}

                                        />
                                        {error && (
                                            <div className="error-message">{error.email}</div>
                                        )}

                                        {/* <span style={{ color: "red", fontSize: 13 }}>{error.email}</span> */}
                                        {/* {error.email && <Alert severity="error">{error.email}</Alert>} */}

                                    </div>



                                    <div className="single-login-field">
                                        <button type='button' className='forgot-button' onClick={handleForgotPassword}>Send Me!</button>
                                    </div>



                                </form>
                                <br></br>




                                <div className="dont_have">
                                    <Link to="/login">Back to Login?</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* <!-- Footer Area Start --> */}
            <Footer></Footer>






        </div>

    )




}
export default ForgotPassword;
