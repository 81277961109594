const BASE_URL = " https://api.altimatixjobs.ifinitesolutions.com/api/"


export const LOGOUT_URL = `${BASE_URL}` + 'recruiter/logout'


export const TERMS_URL = `${BASE_URL}` + 'termsAndConditionList'

export const CONTACT_URL = `${BASE_URL}` + 'contact_users'

export const CHANGEPASSWORD_URL = `${BASE_URL}` + 'employee/changepassword'

export const CAN_DASH_URL = `${BASE_URL}` + 'employee/dashboard'

export const REC_DASH_URL = `${BASE_URL}` + 'recruiter/dashboard'

export const CHANGEPASSRECRUITER_URL = `${BASE_URL}` + 'recruiter/changepassword'

export const FORGOTPASSWORD_URL = `${BASE_URL}` + 'forgot_password'

export const REGISTER_URL = `${BASE_URL}` + 'users'
export const USERTYPE_URL = `${BASE_URL}` + 'usertypes'
export const STATE_URL = `${BASE_URL}` + 'states'


export const LOGIN_URL = `${BASE_URL}` + 'userlogin'

export const PROFILE_URL = `${BASE_URL}` + `employee`

export const UPLOADRESUME_URL = `${BASE_URL}` + `employee/informations`


export const COMPANYPROFILE_URL = `${BASE_URL}` + `recruiter/companies`


export const CITIRES_URL = `${BASE_URL}` + `states/:state_id/cities`

export const HOMEJOBLIST_URL = `${BASE_URL}` + `newJobs`

export const HOMEVIEWPROFILE_URL = `${BASE_URL}` + `newJobs/`

export const JOBSAVED_URL = `${BASE_URL}` + `employee/job_save_or_delete/`

export const POSTJOB_URL = `${BASE_URL}` + `employee/JobApplications`


export const HIREHOME_URL = `${BASE_URL}` + `homePageContent`


export const CATEGORYLIST_URL = `${BASE_URL}` + `jobList`


export const JOBCATEGORY_URL = `${BASE_URL}` + `recruiter/jobs`

export const JOB_URL = `${BASE_URL}` + `recruiter/newJobs/`


export const JOBTYPE_URL = `${BASE_URL}` + `recruiter/jobtypes`

export const EXPERIENCE_URL = `${BASE_URL}` + `recruiter/experiences`

export const SKILL_URL = `${BASE_URL}` + `skills`

export const SEARCH_URL = `${BASE_URL}` + `search_jobs?`

export const CANDISAVEDJOB_URL = `${BASE_URL}` + `employee/job_saves?`

export const SEARCHJOBLIST_URL = `${BASE_URL}` + `search_content`

export const JOB_TITLE_URL = `${BASE_URL}` + `jobs_list?`


export const CANDIAPPLICATION_URL = `${BASE_URL}` + `employee/applicationList?`


export const MANAGEJOBS_URL = `${BASE_URL}` + `recruiter/manageJob?`

export const APPLICATION_URL = `${BASE_URL}` + `recruiter/application_list?`

export const APPLICATION_UPDATE_URL = `${BASE_URL}` + `recruiter/jobApplications/`

export const SEARCH_CANDI_URL = `${BASE_URL}` + `recruiter/candidates/`

export const SEARCH_CANDI_URL1 = `${BASE_URL}` + `recruiter/search_candidates`



export const SEARCH_APPLICATION = `${BASE_URL}` + `recruiter/search_application`

export const SEARCH_CAN_URL = `${BASE_URL}` + `recruiter/candidates`



