import React, { useEffect } from 'react';
import '../../App.css';
import { FaGavel, FaTachometerAlt, FaThumbsUp, FaUserAlt, FaUsers } from "react-icons/fa";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import CandidateHeader from '../../Components/candidateheader';
import CandidateSidebar from '../../Components/candidateSidebar';
import Footer from '../../Components/footer';
import { useState } from 'react';
import axios from 'axios';
import { Link, useHistory, useNavigate } from 'react-router-dom';
import { Alert } from '@mui/material';
import Swal from 'sweetalert2';
import { CONTACT_URL, LOGIN_URL, TERMS_URL } from '../../Config/Constants';

// import { Link } from 'react-router-dom';
// 
// const history = useHistory();



function Contactus() {


    const navigate = useNavigate();



    useEffect(() => {



    }, [])




    const [formData, setFormData] = useState({
        name: '',
        phone_no: '',
        email: '',
        subject: '',
        message: '',
        captcha: true
    });

    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        // Clear the error message when the user types

    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate the form
        try {
            const Response = await axios({
                method: "post",
                url: `${CONTACT_URL}`,
                data: formData

            })

            console.log("Response", Response.data)
            Swal.fire("Success!", "Enquire sent Sucessfully!", "success")
            setFormData({
                name: '',
                phone_no: '',
                email: '',
                subject: '',
                message: '',
                captcha: true
            })
            setErrors({})

            setTimeout(() => {
                Swal.close();

            }, 1500);


        }

        catch (err) {


            console.log("errr====>", err.response.data)

            setErrors(err.response.data.errors)
        }

    };









    const FormSubmit = async (e) => {




    }





    return (

        <div>


            {/* <!-- Header Area Start --> */}
            <CandidateHeader />
            {/* <!-- Header Area End --> */}


            {/* <!-- Breadcromb Area Start --> */}
            <section className="jobguru-breadcromb-area">
                <div className="breadcromb-top section_100">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="breadcromb-box">
                                    <h3>Contactus</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="breadcromb-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="breadcromb-box-pagin">
                                    <ul>
                                        <li><Link to="/">home</Link></li>
                                        {/* <li><a href="#">pages</a></li> */}
                                        <li className="active-breadcromb"><Link >Contactus</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Breadcromb Area End --> */}


            {/* <!-- Login Area Start --> */}
            <section class="jobguru-contact-page-area section_70">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="contact-left">
                                <h3>Contact information</h3>
                                <div class="contact-details">
                                    <p><i class="fa fa-map-marker"></i> 45 Park Avenue, Apt. 303
                                        New York, NY 10016 </p>
                                    <div class="">
                                        <h6>Email Us</h6>
                                        <p href="#"  >mail@example.com</p>
                                    </div>
                                    <div class="">
                                        <h6>Call Us</h6>
                                        <p href="#" >+48 880 440 110</p>
                                    </div>
                                    <div class="">
                                        <h6>Website</h6>
                                        <p href="#" >www.example.com</p>
                                    </div>
                                    <div class="social-links-contact">
                                        {/* <h4>Follow Us:</h4>
                                        <ul>
                                            <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                            <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                            <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                            <li><a href="#"><i class="fa fa-google-plus"></i></a></li>
                                        </ul> */}
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="contact-right">
                                <h3>Feel free to contact us!</h3>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="single-contact-field">
                                                <input
                                                    type="text"
                                                    placeholder="Your Name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleInputChange}
                                                />
                                                {errors.name && <p style={{ color: "red", fontSize: 14 }}>{errors.name}</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="single-contact-field">
                                                <input
                                                    type="text"
                                                    placeholder="Phone"
                                                    name="phone_no"
                                                    value={formData.phone_no}
                                                    onChange={handleInputChange}
                                                />
                                                {errors.phone_no && <p style={{ color: "red", fontSize: 14 }}>{errors.phone_no}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="single-contact-field">
                                                <input
                                                    type="email"
                                                    placeholder="Email Address"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleInputChange}
                                                />
                                                {errors.email && <p style={{ color: "red", fontSize: 14 }}>{errors.email}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="single-contact-field">
                                                <input
                                                    type="text"
                                                    placeholder="Subject"
                                                    name="subject"
                                                    value={formData.subject}
                                                    onChange={handleInputChange}
                                                />
                                                {errors.subject && <p style={{ color: "red", fontSize: 14 }}>{errors.subject}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="single-contact-field">
                                                <textarea
                                                    placeholder="Write here your message"
                                                    name="message"
                                                    value={formData.message}
                                                    onChange={handleInputChange}
                                                ></textarea>
                                                {errors.message && <p style={{ color: "red", fontSize: 14 }}>{errors.message}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="single-contact-field">
                                                <button type="submit">
                                                    <i className="fa fa-paper-plane"></i> Send Message
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>


                    <h4>Our Office</h4>

                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d24180.860865449045!2d-73.979746!3d40.748659!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25906de83c72f%3A0x23ebdbeee99e0a3d!2s45%20Park%20Ave%2C%20New%20York%2C%20NY%2010016!5e0!3m2!1sen!2sus!4v1703135075156!5m2!1sen!2sus" style={{ border: 0, width: "100%", height: 330, paddingTop: 10 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>


                </div>
            </section>


            {/* <!-- Footer Area Start --> */}
            <Footer></Footer>






        </div>

    )




}
export default Contactus;
