import React, { useEffect, useState } from "react";
import "../../App.css"; // You can import your CSS file here
import "./home.css"; // You can import your CSS file here
import {
  FaAngleDoubleRight,
  FaAngleDoubleUp,
  FaBriefcase,
  FaBullhorn,
  FaChartBar,
  FaFacebook,
  FaGavel,
  FaGraduationCap,
  FaLinkedinIn,
  FaMapMarkerAlt,
  FaPencilAlt,
  FaRegClock,
  FaSearch,
  FaThumbsUp,
  FaTwitter,
  FaUserAlt,
  FaCalendarAlt,

} from "react-icons/fa";
import Header from "../../Components/homeheader";
import HomeHeader from "../../Components/homeheader";
import Footer from "../../Components/footer";
import { BsCheckLg, BsCodeSlash, BsPencilSquare } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { BiRestaurant } from "react-icons/bi";
import { CATEGORYLIST_URL, HIREHOME_URL, HOMEJOBLIST_URL, JOB_TITLE_URL, SEARCHJOBLIST_URL, STATE_URL } from "../../Config/Constants";
import axios from "axios";
function Home() {
  const navigate = useNavigate();

  const [jobsData, setJobsData] = useState([]);

  const [categorylist, setcategorylist] = useState([]);
  const [Statelist, setStatelist] = useState([]);
  const [SkillList, setSkillList] = useState([]);
  const [TitleList, setTitleList] = useState([]);


  useEffect(() => {
    fetchData();
    fetchData1();
    StatelistApi()
    skilllistApi()
  }, []);

  const fetchData = async () => {
    const token = localStorage.getItem("token");

    try {
      const Response = await axios({
        method: "get",
        url: `${HOMEJOBLIST_URL}`,
        headers: {
          Authorization: token,
        },
      });
      console.log("Response.data.jobLists", Response.data.jobLists);
      setJobsData(Response.data.jobLists);
    } catch (err) {
      console.log("Error fetching data:", err.response.data);
    }
  };


  const fetchData1 = async () => {
    try {
      const Response = await axios({
        method: "get",
        url: `${CATEGORYLIST_URL}`,
      });

      console.log("Response.datajobs", Response.data.jobs);

      setcategorylist(Response.data.jobs);

    } catch (err) {
      console.log("Error fetching data:", err.response.data);
    }
  };

  const StatelistApi = async () => {
    try {
      const Response = await axios({
        method: "get",
        url: `${STATE_URL}`,
      });

      console.log("states", Response.data.states);

      setStatelist(Response.data.states);

    } catch (err) {
      console.log("Error fetching data:", err.response.data);
    }
  };


  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };


  const [statename, setStatename] = useState('');
  const [jobtitlename, setJobtitlename] = useState('');
  const [skillname, setSkillname] = useState('');


  const [stateid, setstateid] = useState('');

  const [skillid, setskillid] = useState('');

  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);

  const [filterstates, setFilterstates] = useState([]);

  const [filterTitles, setFilterTitles] = useState([]);
  const [filterSkill, setFilterSkill] = useState([]);



  const skilllistApi = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios({
        method: "GET",
        url: `${SEARCHJOBLIST_URL}`,
        headers: {
          Authorization: token,
        },
      });

      // console.log("response of states success", response.data.states);
      // console.log("response of cities success", response.data.cities);
      // console.log("response of categories success", response.data.categories);
      // console.log("response of jobtypes success", response.data.jobtypes);
      // console.log("response of skills success", response.data.skills);

      // setStatee(response.data.states);
      // setCityy(response.data.cities);

      setSkillList(response.data.skills);

      // Set initial cities based on the first state (you can adjust this based on your requirements)
      // setCityy(response.data.cities[0].cities);
    } catch (error) {
      console.log("Error message access in dropdown:", error.response.data);
    }
  };
  const handleInputChange = async (event) => {

    const value = event.target.value;


    switch (event.target.name) {
      case 'job_title':
        if (value.length > 0) {

          setJobtitlename(value);


          try {
            const response = await axios({
              method: "GET",
              url: `${JOB_TITLE_URL}title=${value}`,

            });

            console.log("res", response.data)
            setFilterTitles(response.data);
            setIsOpen(true);



          } catch (error) {
            console.log("Error message access in dropdown:", error.response.data);
          }






        } else {
          setIsOpen(false);
          setJobtitlename(value);

        }
        break;
      case 'state':
        if (value.length > 0) {

          setStatename(value);
          const filtered = Statelist.filter(e =>
            e.name.toLowerCase().includes(value.toLowerCase())
          );
          console.log("filtered", filtered)

          setFilterstates(filtered);
          setIsOpen2(true);
        } else {
          setIsOpen2(false);
          setStatename(value);

        }

        break;
      case 'skill':

        if (value.length > 0) {

          setSkillname(value);
          const filtered = SkillList.filter(e =>
            e.name.toLowerCase().includes(value.toLowerCase())
          );
          console.log("filtered", filtered)

          setFilterSkill(filtered);
          setIsOpen3(true);
        } else {
          setIsOpen3(false);
          setSkillname(value);

        }

        break;
      default:
        break;
    }



  };

  const handleOptionClick = (name, type, id) => {

    console.log("type", type)

    switch (type) {
      case 'job_title':
        setIsOpen(false);
        setJobtitlename(name)

        break;
      case 'state':
        setIsOpen2(false)
        setStatename(name)
        setstateid(id)
        break;
      case 'skill':
        setIsOpen3(false)
        setSkillname(name)
        setskillid(id)
        break;
      default:
        break;
    }


  };

  const closeDropdown = () => {
    // setIsOpen(false);
    // setIsOpen2(false);
    // setIsOpen3(false);


  };


  const Searchpage = () => {

    var state = {
      title: jobtitlename,
      state_id: stateid,
      skill_id: skillid,
      category_id: ''
    }

    navigate("/search_jobs", { state });


  };

  const Searchpagewithcate = (id) => {

    var state = {
      title: '',
      state_id: '',
      skill_id: '',
      category_id: id
    }

    navigate("/search_jobs", { state });


  };


  return (
    <div className="App">
      {/* <!-- Header Area Start --> */}

      <HomeHeader />
      {/* <!-- Header Area End --> */}

      {/* <!-- Banner Area Start --> */}
      <section className="jobguru-banner-area">
        <div className="banner-slider owl-carousel">
          <div className="banner-single-slider slider-item-1">
            <div className="slider-offset"></div>
          </div>
          {/* <div className="banner-single-slider slider-item-2">
            <div className="slider-offset"></div>
          </div> */}
        </div>
        <div className="banner-text">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="banner-search">
                  <h2>Find Jobs</h2>
                  <h4>We've over 15,000 job offers for you! </h4>
                  {/* <form>
                    <div className="banner-form-box">
                      <div className="row">
                        <div className="banner-form-input col-sm-3">
                          <input
                            type="text"
                            placeholder="Job Title"
                          />
                          <ul>
                            {categorylist.map((result, index) => (
                              <li key={index}>{index}</li>
                            ))}
                          </ul>
                        </div>

                        <div className="banner-form-input2 col-sm-3">
                          <input type="text" placeholder="State" />
                        </div>
                        <div className="banner-form-input2 col-sm-3">

                          <input type="text" placeholder="Skills" />

                        </div>
                        <div className="banner-form-input2 col-sm-3">
                          <button type="submit">
                            <FaSearch fontSize={18}></FaSearch>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form> */}

                  <div className="custom-dropdown-container">
                    <input
                      type="text"
                      placeholder="Job Title"
                      name="job_title"
                      value={jobtitlename}
                      onChange={handleInputChange}
                      onBlur={closeDropdown}
                      className="dropdown-input"
                    />
                    {isOpen && (
                      <ul className={filterTitles.length < 5 ? "dropdown-list-below" : "dropdown-list"}>
                        {filterTitles.length > 0 ?

                          filterTitles.map((e, index) => (
                            <li key={index} onClick={() => handleOptionClick(e.tittle, "job_title", e.id)}>
                              {e.tittle}
                            </li>
                          ))

                          :
                          <li style={{ textAlign: "center" }}>

                            No results found
                          </li>
                        }
                      </ul>
                    )}
                  </div>

                  <div className="custom-dropdown-container">
                    <input
                      type="text"
                      name="state"
                      placeholder="States"
                      value={statename}
                      onChange={handleInputChange}
                      onBlur={closeDropdown}
                      className="dropdown-input"
                    />
                    {isOpen2 && (
                      <ul className={filterstates.length < 5 ? "dropdown-list-below" : "dropdown-list"}>
                        {filterstates.length > 0 ?

                          filterstates.map((e, index) => (
                            <li key={index} onClick={() => handleOptionClick(e.name, "state", e.id,)}>
                              {e.name}
                            </li>
                          ))
                          :
                          <li style={{ textAlign: "center" }}>

                            No results found
                          </li>
                        }
                      </ul>
                    )}
                  </div>

                  <div className="custom-dropdown-container">
                    <input
                      type="text"
                      name="skill"
                      placeholder="Skills"
                      value={skillname}
                      onChange={handleInputChange}
                      onBlur={closeDropdown}
                      className="dropdown-input"
                    />
                    {isOpen3 && (
                      <ul className={filterSkill.length < 5 ? "dropdown-list-below" : "dropdown-list"}>
                        {filterSkill.length > 0 ?

                          filterSkill.map((e, index) => (
                            <li key={index} onClick={() => handleOptionClick(e.name, "skill", e.id)}>
                              {e.name}
                            </li>
                          ))

                          :
                          <li style={{ textAlign: "center" }}>

                            No results found
                          </li>
                        }
                      </ul>
                    )}
                  </div>
                  <div className="custom-dropdown-container">
                    <button onClick={() => Searchpage()} type="submit">
                      <FaSearch fontSize={18}></FaSearch>
                    </button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
      {/* <!-- Banner Area End --> */}

      {/* <!-- Categories Area Start --> */}
      <section className="jobguru-categories-area section_70">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="site-heading">
                <h2>
                  top Trending <span>Categories</span>
                </h2>
                {/* <p>A better career is out there. We'll help you find it. We're your first step to becoming everything you want to be.</p> */}
              </div>
            </div>
          </div>
          <div className="row">
            {categorylist.map((e, i) => (
              <div onClick={() => Searchpagewithcate(e.id)} className="col-lg-3 col-md-6 col-sm-6">
                <Link className="single-category-holder account_cat">
                  <div className="works-box-icon">
                    {/* <FaBriefcase className="icons-style"></FaBriefcase> */}
                    <img
                      style={{ width: "53px" }}
                      src={e.image}
                    // alt="category"
                    />
                  </div>
                  <div className="category-holder-text">
                    <h3>{e.name}</h3>
                  </div>
                  <img
                    src={e.image}
                  // alt="category"
                  />
                </Link>
              </div>
            ))}
            {/* <div className="col-lg-3 col-md-6 col-sm-6">
              <Link to="#" className="single-category-holder design_cat">
                <div className="works-box-icon">
                  <BsPencilSquare className="icons-style"></BsPencilSquare>
                </div>

                <div className="category-holder-text">
                  <h3>Design, Art & Multimedia</h3>
                </div>
                <img src={require("../../img/design_art.jpg")} alt="category" />
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <Link to="#" className="single-category-holder restaurant_cat">
                <div className="works-box-icon">
                  <BiRestaurant className="icons-style"></BiRestaurant>
                </div>
                <div className="category-holder-text">
                  <h3>Restaurant / Food Service</h3>
                </div>
                <img src={require("../../img/restaurent.jpg")} alt="category" />
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <Link to="#" className="single-category-holder tech_cat">
                <div className="works-box-icon">
                  <BsCodeSlash className="icons-style"></BsCodeSlash>
                </div>

                <div className="category-holder-text">
                  <h3>Programming & Tech</h3>
                </div>
                <img
                  src={require("../../img/programing_cat.jpg")}
                  alt="category"
                />
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <Link to="#" className="single-category-holder data_cat">
                <div className="works-box-icon">
                  <FaChartBar className="icons-style"></FaChartBar>
                </div>
                <div className="category-holder-text">
                  <h3>Data Science & Analitycs</h3>
                </div>
                <img src={require("../../img/data_cat.png")} alt="category" />
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <Link to="#" className="single-category-holder writing_cat">
                <div className="works-box-icon">
                  <FaPencilAlt className="icons-style"></FaPencilAlt>
                </div>

                <div className="category-holder-text">
                  <h3>Writing / Translations</h3>
                </div>
                <img
                  src={require("../../img/writing_cat.jpg")}
                  alt="category"
                />
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <Link to="#" className="single-category-holder edu_cat">
                <div className="works-box-icon">
                  <FaGraduationCap className="icons-style"></FaGraduationCap>
                </div>

                <div className="category-holder-text">
                  <h3>Education / Training</h3>
                </div>
                <img src={require("../../img/edu_cat.jpg")} alt="category" />
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <Link to="#" className="single-category-holder sale_cat">
                <div className="works-box-icon">
                  <FaBullhorn className="icons-style"></FaBullhorn>
                </div>
                <div className="category-holder-text">
                  <h3>sales / marketing</h3>
                </div>
                <img src={require("../../img/sale_cat.png")} alt="category" />
              </Link>
            </div> */}
          </div>
          {/* <div className="row">
                        <div className="col-md-12">
                            <div className="load-more">
                                <a href="#" className="jobguru-btn">browse all categories</a>
                            </div>
                        </div>
                    </div> */}
        </div>
      </section>
      {/* <!-- Categories Area End --> */}

      {/* <!-- Inner Hire Area Start --> */}
      <section className="jobguru-inner-hire-area section_100">
        <div className="hire_circle"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="inner-hire-left">
                {/* {hireemp.map(hire => (
                                    <div key={hire.id}>
                                        <h3>{hire.tittle}</h3>
                                        <p>{hire.description}</p>
                                        <Link to="/register" className="jobguru-btn-3">sign up as company</Link>
                                    </div>
                                ))} */}
                <div>
                  <h3>Hire an employe</h3>
                  <p>
                    The word 'development' is widely used to refer to a
                    specified state of advancement or growth. It could also be
                    used to describe a new and advanced idea or product; or an
                    event that constitutes a new stage under changing
                    circumstances. Generally, the term development describes
                    good change.
                  </p>
                  <Link to="/register" className="jobguru-btn-3">
                    sign up as company
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Inner Hire Area End --> */}

      {/* <!-- Job Tab Area Start --> */}
      <section className="jobguru-job-tab-area section_70">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="site-heading">
                <h2>Recent Jobs</h2>
                {/* <p>It's easy. Simply post a job you need completed and receive competitive bids from freelancers within minutes</p> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {/* <div className=" job-tab">
                                <ul className="nav nav-pills job-tab-switch" id="pills-tab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="pills-companies-tab" data-bs-toggle="pill" href="#pills-companies" role="tab" aria-controls="pills-companies" aria-selected="true">top Companies</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-job-tab" data-bs-toggle="pill" href="#pills-job" role="tab" aria-controls="pills-job" aria-selected="false">job openning</a>
                                    </li>
                                </ul>
                            </div> */}
              <div
                className="tab-content"
                id="pills-tabContent"
                style={{ textAlign: "start" }}
              >
                <div
                  className="tab-pane fade show active"
                  id="pills-companies"
                  role="tabpanel"
                  aria-labelledby="pills-companies-tab"
                >
                  <div className="top-company-tab">
                    <ul>
                      {jobsData?.map((job) => (
                        <li key={job.id}>
                          <div className="top-company-list">
                            <div className="company-list-logo">
                              <Link to="#">
                                {/* <img src={job.image} /> */}
                                <img
                                  src={job.company.logo}
                                  alt={`company logo ${job.id}`}
                                />
                              </Link>
                            </div>
                            {/* alt={`company logo ${job.id}`} */}
                            <div className="company-list-details">
                              <div className="company-list-details1">
                                <Link to="#" className="black-text1">
                                  {job.tittle}
                                </Link>
                                <h4 className="Search-Jobtype">{job.job_type.type}</h4>
                              </div>

                              {/*  */}

                              <div className="company-list-details algin">

                                <p className="company-state">
                                  <FaMapMarkerAlt className="map-icon"></FaMapMarkerAlt>
                                  {job.state.name},{job.city.name}
                                </p>
                                <p className="open-icon">
                                  <FaCalendarAlt className="clock-icon"></FaCalendarAlt>
                                  {job.expire_date}
                                </p>
                                <p className="varify">
                                  <BsCheckLg className="varify-icon"></BsCheckLg>
                                  Fixed price : $1200-$2000
                                </p>


                              </div>
                              {/*  */}

                              <div>
                                <h6>{job.job_description.slice(0, 100)}</h6>
                              </div>
                            </div>
                            <div className="company-list-btn">
                              <Link
                                to={`/job_details/${job.id}`}
                                // onClick={() => viewProfile1(job.id)}
                                className="jobguru-btn"
                              >
                                View Profile
                              </Link>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-job"
                  role="tabpanel"
                  aria-labelledby="pills-job-tab"
                >
                  <div className="top-company-tab">
                    <ul>
                      <li>
                        <div className="top-company-list">
                          <div className="company-list-logo">
                            <Link to="#">
                              <img
                                src={require("../../img/company-logo-1.png")}
                                alt="company list 1"
                              />
                            </Link>
                          </div>
                          <div className="company-list-details">
                            <h3>
                              <Link to="#">Regional Sales Manager</Link>
                            </h3>
                            <p className="company-state">
                              <FaMapMarkerAlt className="map-icon"></FaMapMarkerAlt>{" "}
                              Chicago, Michigan
                            </p>
                            <p className="open-icon">
                              <i className="fa fa-clock-o"></i>2 minutes ago
                            </p>
                            <p className="varify">
                              <BsCheckLg className="varify-icon"></BsCheckLg>
                              Fixed price : $1200-$2000
                            </p>
                            <p className="rating-company">4.1</p>
                          </div>
                          <div className="company-list-btn">
                            <Link to="#" className="jobguru-btn">
                              bid now
                            </Link>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="top-company-list">
                          <div className="company-list-logo">
                            <Link to="#">
                              <img
                                src={require("../../img/company-logo-4.png")}
                                alt="company list 1"
                              />
                            </Link>
                          </div>
                          <div className="company-list-details">
                            <h3>
                              <Link to="#">C Developer (Senior) C .Net</Link>
                            </h3>
                            <p className="company-state">
                              <FaMapMarkerAlt className="map-icon"></FaMapMarkerAlt>{" "}
                              Chicago, Michigan
                            </p>
                            <p className="open-icon">
                              <i className="fa fa-clock-o"></i>2 minutes ago
                            </p>
                            <p className="varify">
                              <BsCheckLg className="varify-icon"></BsCheckLg>
                              Fixed price : $800-$1200
                            </p>
                            <p className="rating-company">3.1</p>
                          </div>
                          <div className="company-list-btn">
                            <Link to="#" className="jobguru-btn">
                              bid now
                            </Link>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="top-company-list">
                          <div className="company-list-logo">
                            <Link to="#">
                              <img
                                src={require("../../img/company-logo-3.png")}
                                alt="company list 1"
                              />
                            </Link>
                          </div>
                          <div className="company-list-details">
                            <h3>
                              <Link to="#">Asst. Teacher</Link>
                            </h3>
                            <p className="company-state">
                              <FaMapMarkerAlt className="map-icon"></FaMapMarkerAlt>{" "}
                              Chicago, Michigan
                            </p>
                            <p className="open-icon">
                              <i className="fa fa-clock-o"></i>3 minutes ago
                            </p>
                            <p className="varify">
                              <BsCheckLg className="varify-icon"></BsCheckLg>
                              Fixed price : $800-$1200
                            </p>
                            <p className="rating-company">4.3</p>
                          </div>
                          <div className="company-list-btn">
                            <Link to="#" className="jobguru-btn">
                              bid now
                            </Link>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="top-company-list">
                          <div className="company-list-logo">
                            <Link to="#">
                              <img
                                src={require("../../img/company-logo-2.png")}
                                alt="company list 1"
                              />
                            </Link>
                          </div>
                          <div className="company-list-details">
                            <h3>
                              <Link to="#">civil engineer</Link>
                            </h3>
                            <p className="company-state">
                              <FaMapMarkerAlt className="map-icon"></FaMapMarkerAlt>{" "}
                              Chicago, Michigan
                            </p>
                            <p className="open-icon">
                              <i className="fa fa-clock-o"></i>30 minutes ago
                            </p>
                            <p className="varify">
                              <BsCheckLg className="varify-icon"></BsCheckLg>
                              Fixed price : $2000-$2500
                            </p>
                            <p className="rating-company">3.7</p>
                          </div>
                          <div className="company-list-btn">
                            <Link to="#" className="jobguru-btn">
                              bid now
                            </Link>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
                        <div className="col-md-12">
                            <div className="load-more">
                                <a href="#" className="jobguru-btn">browse more listing</a>
                            </div>
                        </div>
                    </div> */}
        </div>
      </section>
      {/* <!-- Job Tab Area End --> */}

      {/* <!-- Video Area Start --> */}
      <section className="jobguru-video-area section_100">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="video-container">
                <h2>Start Building Your Own Job Board Now </h2>
                {/* <div className="video-btn">
                                    <a className="popup-youtube" href="https://www.youtube.com/watch?v=k-R6AFn9-ek">
                                        <i className="fa fa-play"></i>
                                        how it works
                                    </a>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Video Area End --> */}

      {/* <!-- How Works Area Start --> */}
      <section className="how-works-area section_70">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="site-heading">
                <h2>
                  how it <span>works</span>
                </h2>
                <p>It's easy. Simply post a job you need completed </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="how-works-box box-1">
                <img
                  src={require("../../img/arrow-right-top.png")}
                  alt="works"
                />
                <div className="works-box-icon">
                  {/* <i className="fa fa-user"></i> */}
                  {/* BsFillPersonFill */}
                  <FaUserAlt className="icons-style"></FaUserAlt>
                </div>
                <div className="works-box-text icons-text">
                  <p>sign up</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="how-works-box box-2">
                <img
                  src={require("../../img/arrow-right-bottom.png")}
                  alt="works"
                />
                <div className="works-box-icon">
                  {/* <i className="fa fa-gavel"></i> */}
                  <FaGavel className="icons-style"></FaGavel>
                </div>
                <div className="works-box-text icons-text">
                  <p>post job</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="how-works-box box-3">
                <div className="works-box-icon">
                  <FaThumbsUp className="icons-style"></FaThumbsUp>
                </div>
                <div className="works-box-text icons-text">
                  <p>choose expert</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- How Works Area End --> */}

      <Footer></Footer>

      {/* <!-- Footer Area Start --> */}

      {/* Add your other sections/components here */}
    </div >
  );
}

export default Home;
