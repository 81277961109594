import React, { useEffect, useState } from 'react';
import '../../App.css'; // You can import your CSS file here
import { FaGavel, FaTachometerAlt, FaThumbsUp, FaUserAlt, FaUsers } from "react-icons/fa";
import CandidateHeader from '../../Components/candidateheader';
import CandidateSidebar from '../../Components/candidateSidebar';
import Footer from '../../Components/footer';
import { BsCheckCircleFill } from 'react-icons/bs';
import { FcProcess } from "react-icons/fc";
import { CAN_DASH_URL, CHANGEPASSWORD_URL } from '../../Config/Constants';
import axios from 'axios';
function Candidate_Dashboard() {


    useEffect(() => {

        handleUpdatePassword()

    }, [])

    const [counts, setCounts] = useState({})

    const handleUpdatePassword = async () => {

        const token = localStorage.getItem("token");
        try {
            const Response = await axios({
                method: "get",
                url: `${CAN_DASH_URL}`,
                headers: {
                    Authorization: token,

                },

            })

            // if (Response.data.message == "Password Updated Successfully") {
            console.log('ress', Response.data);

            setCounts(Response.data)


        }

        catch (err) {

            console.log('Error updating password:', err.response.data.errors);

        }

    };


    return (

        <div>

            {/* <!-- Header Area Start --> */}
            <CandidateHeader />
            {/* <!-- Header Area End --> */}


            {/* <!-- Breadcromb Area Start --> */}
            <section class="jobguru-breadcromb-area">
                <div class="breadcromb-top section_100">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="breadcromb-box">
                                    <h3>Dashboard</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="breadcromb-bottom">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="breadcromb-box-pagin">
                                    <ul>
                                        <li><a href="#">home</a></li>
                                        <li><a href="#">candidates</a></li>
                                        <li class="active-breadcromb"><a href="#">Dashboard</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Breadcromb Area End --> */}


            {/* <!-- Candidate Dashboard Area Start --> */}
            <section class="candidate-dashboard-area section_70">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 col-md-4 dashboard-left-border">
                            <CandidateSidebar />
                        </div>
                        <div class="col-lg-9 col-md-8">
                            <div class="dashboard-right">
                                <div class="welcome-dashboard">
                                    <h3>Welcome Jhon Doe !</h3>
                                </div>
                                <div class="row">
                                    {/* <div class="col-lg-4 col-md-6">
                                        <div class="widget_card_page grid_flex widget_bg_blue">
                                            <div class="widget-icon">
                                                <i class="fa fa-gavel"></i>
                                            </div>
                                            <div class="widget-page-text">
                                                <h4>1426</h4>
                                                <h2>new orders</h2>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div class="col-lg-4 col-md-6">
                                        <div class="widget_card_page grid_flex  widget_bg_purple">
                                            <div class="widget-icon">
                                                <BsCheckCircleFill ></BsCheckCircleFill>
                                            </div>
                                            <div class="widget-page-text">
                                                <h4>{counts.appliedCount}</h4>
                                                <h2>Jobs Applied</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="widget_card_page grid_flex widget_bg_dark_red">
                                            <div class="widget-icon">
                                                <FcProcess></FcProcess>
                                            </div>
                                            <div class="widget-page-text">
                                                <h4>{counts.shortlist}</h4>
                                                <h2>Shortlist</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Candidate Dashboard Area End --> */}


            {/* <!-- Footer Area Start --> */}


            <Footer></Footer>




        </div>

    )




}
export default Candidate_Dashboard;
