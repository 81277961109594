import React, { useEffect, useState } from 'react';
import '../../App.css'; // You can import your CSS file here
import { FaBirthdayCake, FaCube, FaGavel, FaGraduationCap, FaMapMarkerAlt, FaRegCalendarAlt, FaRegClock, FaRegMoneyBillAlt, FaSearch, FaTachometerAlt, FaThumbsUp, FaUserAlt, FaUsers } from "react-icons/fa";
import { BsCheckLg, BsFillFileTextFill, BsFillTelephoneFill, BsPencilSquare, BsPersonFill, BsTrash } from "react-icons/bs";
import CandidateHeader from '../../Components/candidateheader';
import CandidateSidebar from '../../Components/candidateSidebar';
import Slider from 'react-slider';
import Footer from '../../Components/footer';
import { MdBreakfastDining, MdEmail, MdLocationOn, MdOutlineCategory } from "react-icons/md";
import { HiAcademicCap, } from "react-icons/hi";
import { HiFunnel } from "react-icons/hi2";
import { PiClockCounterClockwiseBold } from "react-icons/pi";
import { IoMdCloudDownload } from 'react-icons/io';
import ProgressBar from './progressbar';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';
import { saveAs } from 'file-saver';

import { Card, Typography } from '@mui/material';
import { SEARCH_CANDI_URL } from '../../Config/Constants';
import axios from 'axios';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

function Candidate_Details() {

    const [values, setValues] = useState([0, 100]);


    const { id } = useParams()
    const handleChange = (newValues) => {
        setValues(newValues);
    };
    useEffect(() => {


        scrollToTop()
        Getdetails()

    }, [])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const [details, setDetails] = useState({});
    const [categories, setCategories] = useState([]);

    const [progress, setProgress] = useState(65);
    const [progress2, setProgress2] = useState(70);
    const [progress3, setProgress3] = useState(75);


    // useEffect(() => {
    //   // Simulate progress changes
    //   const interval = setInterval(() => {
    //     if (progress < 100) {
    //       setProgress(progress + 10);
    //     } else {
    //       clearInterval(interval);
    //     }
    //   }, 1000);
    //   return () => clearInterval(interval);
    // }, [progress]);


    const Getdetails = async () => {


        const token = localStorage.getItem("token");


        try {
            const response = await axios({
                method: "GET",
                url: `${SEARCH_CANDI_URL}` + id,
                headers: {
                    Authorization: token,
                },


            });

            console.log("Response", response.data.categories);

            setDetails(response.data)
            setCategories(response.data.categories)

        } catch (error) {

            console.log("error", error);


        }



    }

    const ResumeDownload = async () => {

        const token = localStorage.getItem("token");
        console.log("id", id);


        try {
            const response = await axios({
                method: "GET",
                url: `${SEARCH_CANDI_URL}` + id + "/resume",
                headers: {
                    Authorization: token,
                },
                responseType: 'blob',

            });

            console.log("Response", response.data);

            saveAs(response.data, 'downloaded_resume.pdf');

            Swal.fire({
                title: "Success",
                text: "Resume Downloaded successfully",
                icon: "success",
                timer: 2000, // Time in milliseconds
                showConfirmButton: false,

            });



        } catch (error) {
            console.log("Error fetching job:", error.response.data);
            Swal.fire({
                title: "Error",
                text: "There's something wrong with this resume.",
                icon: "error",
                timer: 2000, // Time in milliseconds
                showConfirmButton: false,

            });
        }

    }

    const [Education, setEducation] = useState([


        {
            "year": "2018 - 2022",
            "college": "Vaigai College of Enggineering",
            'degree': "B.E"
        },
        {
            "year": "2017 - 2018",
            "college": "Alwin Hr Secondary School",
            'degree': "12th"
        },
        {
            "year": "2016 - 2017",
            "college": "Alwin Hr Secondary School",
            'degree': "10th"
        }


    ])

    return (

        <div>

            {/* <!-- Header Area Start --> */}
            <CandidateHeader />
            {/* <!-- Header Area End --> */}






            {/* <!-- Single Candidate Start --> */}
            <section class="single-candidate-page section_70">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="single-candidate-box">
                                <div class="single-candidate-img">
                                    <img src={details.image} alt="single candidate" />
                                </div>
                                <div class="single-candidate-box-right">
                                    <h4>{details.user_name}</h4>

                                    <p>{details.profesional_title}</p>
                                    <div class="company-list-details">
                                        <p class="company-state" >< FaMapMarkerAlt className='map-icon'></FaMapMarkerAlt> {details.state?.name},{details.city?.name}</p>
                                        <p style={{ fontFamily: "-moz-initial" }} class="open-icon"><FaBirthdayCake className='clock-icon' ></FaBirthdayCake>{details.d_o_b}</p>
                                        <p style={{ fontFamily: "-moz-initial" }} class="varify"><BsFillTelephoneFill className='varify-icon'></BsFillTelephoneFill>{details.phone_no}</p>
                                        <p style={{ fontFamily: "-moz-initial", textTransform: "-moz-initial" }} class="varify"><MdEmail className='varify-icon'></MdEmail>{details.email}</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="single-candidate-action">
                                {/* <a href="#" class="bookmarks"><i class="fa fa-star"></i>Bookmarks</a> */}
                                <Link onClick={() => ResumeDownload()} class="candidate-contact"><IoMdCloudDownload style={{ fontSize: 19, marginRight: 10 }}></IoMdCloudDownload>Download Resume</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Single Candidate End --> */}


            {/* <!-- Single Candidate Bottom Start --> */}
            <div class="single-candidate-bottom-area section_70">
                <div class="container">
                    <div class="row">
                        <div class="col-md-10 col-lg-9 mx-auto">
                            <div class="single-candidate-bottom-left">
                                <div class="single-candidate-widget">
                                    <h3>About Me</h3>
                                    <p>{details.description}</p>
                                </div>
                                <div class="single-candidate-widget">
                                    <h3>Skills</h3>
                                    <ul>
                                        {details?.skills?.map((e, i) => (
                                            <li><a> {e.name}</a></li>
                                        ))}



                                    </ul>
                                </div>



                                <div class="single-candidate-widget clearfix">
                                    <h3>Education </h3>
                                    {/* <div class="progressbar-example">
                                        
                                        <div class="progress-box">
                                            <p class="progress-title">2017 - 2019</p>
                                            <p class="progress-size" style={{ fontWeight: "bold", color: "black" }}>Nadar high sec  school </p>
                                        
                                            <ProgressBar value={progress2} max={80} />

                                        </div>
                                        <div class="progress-box">
                                            <p class="progress-title">2019 - 2014</p>
                                            <p class="progress-size" style={{ fontWeight: "bold", color: "black" }}>American college </p>
                                            <ProgressBar value={progress3} max={80} />
                                      

                                        </div>

                                    </div> */}
                                    <div style={{ backgroundColor: "white" }}>
                                        {details?.educations?.map((item, index) => (
                                            <TimelineItem>
                                                <TimelineOppositeContent
                                                    sx={{ m: "auto 0" }}
                                                    align="right"
                                                    variant="body2"
                                                    color="text.secondary"
                                                    style={{ maxWidth: "112px" }}
                                                >
                                                    {moment(item.from).format('YYYY') + "-" + moment(item.to).format('YYYY')}
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineConnector />
                                                    <TimelineDot>
                                                        {/* <FreeBreakfastIcon /> */}
                                                        <FaGraduationCap />
                                                    </TimelineDot>
                                                    <TimelineConnector />
                                                </TimelineSeparator>
                                                <TimelineContent style={{ marginTop: "48px" }}>

                                                    <Typography>{item.degree}</Typography>
                                                    <Typography color="text.secondary">{item.institude}</Typography>

                                                    <Typography color="text.secondary">{item.addtion_information}</Typography>

                                                </TimelineContent>
                                            </TimelineItem>
                                        ))
                                        }
                                    </div>
                                    {/* <TimelineItem>
                                        <TimelineOppositeContent
                                            sx={{ m: "auto 0" }}
                                            align="right"
                                            variant="body2"
                                            color="text.secondary"
                                            style={{ maxWidth: "12%" }}
                                        >
                                            2018 - 2022
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            <TimelineConnector />
                                            <TimelineDot>
                                             
                                                <FaGraduationCap />
                                            </TimelineDot>
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Typography>B.E</Typography>
                                            <Typography color="text.secondary">Vaigai College Of Enggineering</Typography>
                                        </TimelineContent>
                                    </TimelineItem> */}

                                    {/* <Timeline.Item
                                        className="w-full"
                                        media={  <MdBreakfastDining />}
                                        // {...rest}
                                    >
                                        <p className="my-1 flex items-center">
                                            <span className="font-semibold text-gray-900 dark:text-gray-100">
                                               6575757
                                            </span>
                                            <span className="mx-2">added a comment </span>
                                            <span>10</span>
                                        </p>
                                        <Card bordered className="mt-4">
                                            <p>gffg</p>
                                        </Card>
                                    </Timeline.Item> */}

                                </div>





                                <div class="single-candidate-widget">
                                    <h3>work Experience</h3>
                                    {details?.experiences?.map((item, index) => (
                                        <div class="single-work-history">
                                            <div class="single-candidate-list">
                                                <div class="main-comment">
                                                    <div class="candidate-image">
                                                        <img src={require("../../img/b_logo.png")} alt="author" />
                                                    </div>
                                                    <div class="candidate-text">
                                                        <div class="candidate-info">
                                                            <div class="candidate-title">
                                                                <h3><a>{item.company}</a></h3>
                                                            </div>
                                                            <p><i class="fa fa-calendar-check-o"></i>{moment(item.from).format('YYYY') + "-" + moment(item.to).format('YYYY')}</p>
                                                        </div>
                                                        <div class="candidate-text-inner">
                                                            <p>{item.additonal_informatiion}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                    ))}







                                    {/* 
                                    <div class="single-work-history">
                                        <div class="single-candidate-list">
                                            <div class="main-comment">
                                                <div class="candidate-image">
                                                    <img src={require("../../img/b_logo.png")} alt="author" />
                                                </div>
                                                <div class="candidate-text">
                                                    <div class="candidate-info">
                                                        <div class="candidate-title">
                                                            <h3><a href="#">Kr Infotech</a></h3>
                                                        </div>
                                                        <p><i class="fa fa-calendar-check-o"></i>209 - 2020</p>
                                                    </div>
                                                    <div class="candidate-text-inner">
                                                        <p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Etiam eu velit cursus, tempor ipsum in, tempus lectus. Nullam tempus nisi id nisl luctus, non tempor justo molestie.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>



                            </div>
                        </div>
                        <div class="col-md-10 col-lg-3 mx-auto">
                            <div class="single-candidate-bottom-right">
                                <div class="single-candidate-widget-2">
                                    <Link to="/recruiter/search_candidates" class="jobguru-btn-2">
                                        <i class="fa fa-balance-scale"></i>
                                        BACK TO SEARCH CANDIDATE
                                    </Link>
                                </div>
                                <div class="single-candidate-widget-2">
                                    <h3>General Information</h3>
                                    <ul class="job-overview" >
                                        <li style={{ fontWeight: "bold", color: "black" }}>Languages      <span style={{ marginLeft: 25, fontWeight: "normal" }}>{details.language}  </span></li>
                                        <li style={{ fontWeight: "bold", color: "black" }}>Qualification  <span style={{ marginLeft: 15, fontWeight: "normal" }}>{details.qualification}</span></li>
                                        <li style={{ fontWeight: "bold", color: "black" }}>Experience   <span style={{ marginLeft: 25, fontWeight: "normal" }}>{details.total_year_experience}</span></li>
                                        <li style={{ fontWeight: "bold", color: "black" }}>Job Category
                                            {details?.categories?.map((e, i) => (
                                                <span style={{ marginLeft: 10, fontWeight: "normal" }}>{e.name + ","}</span>
                                            ))}

                                        </li>
                                        <li style={{ fontWeight: "bold", color: "black" }}>Expected Level   <span style={{ marginLeft: 15, fontWeight: "normal" }}>{details.expected_job_level}</span></li>
                                        <li style={{ fontWeight: "bold", color: "black" }}>Job Location   <span style={{ marginLeft: 25, fontWeight: "normal" }}>{details.state?.name}, {details.city?.name}</span></li>

                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Single Candidate Bottom End --> */}









            {/* <!-- Footer Area Start --> */}


            <Footer></Footer>




        </div>

    )




}
export default Candidate_Details;
