import React, { useEffect, useState } from 'react';
import '../../App.css'; // You can import your CSS file here
import { FaCube, FaGavel, FaMapMarkerAlt, FaRegCalendarAlt, FaRegClock, FaRegMoneyBillAlt, FaSearch, FaTachometerAlt, FaThumbsUp, FaUserAlt, FaUsers } from "react-icons/fa";
import { BsCheckLg, BsFillFileTextFill, BsPencilSquare, BsPersonFill, BsTrash } from "react-icons/bs";
import CandidateHeader from '../../Components/candidateheader';
import CandidateSidebar from '../../Components/candidateSidebar';
import Slider from 'react-slider';
import Footer from '../../Components/footer';
import { MdLocationOn } from "react-icons/md";
import { HiAcademicCap, } from "react-icons/hi";
import { HiFunnel } from "react-icons/hi2";
import { PiClockCounterClockwiseBold } from "react-icons/pi";
import { CANDISAVEDJOB_URL, HOMEVIEWPROFILE_URL, JOBSAVED_URL, POSTJOB_URL } from '../../Config/Constants';
import axios from 'axios';
import { Link, useLocation, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';


function Candidate_JobDetails() {


    const { id } = useParams()

    const location = useLocation();


    // console.log("location",location)
    // const org_id = location.state.state.id;

    const [values, setValues] = useState([0, 100]);


    const token = localStorage.getItem("token");

    const user_type_name = localStorage.getItem("alti_type");


    // const handleChange = (newValues) => {
    //     setValues(newValues);
    // };
    useEffect(() => {


        scrollToTop()

    }, [])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const [jobData, setJobData] = useState({});
    // const [error, setError] = useState(null);
    const [company, setCompany] = useState({});
    const [state, setState] = useState({});
    const [city, setCity] = useState({});
    const [experiences, setExperiences] = useState({});

    const [categorylist, setCategorylist] = useState([]);
    const [skillList, setskillList] = useState([]);


    useEffect(() => {


        ApplicationData()
        fetchData();
        // fetchData1();

    }, []);

    const [isJobSaved, setIsJobSaved] = useState(false);


    const ApplicationData = async () => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios({
                method: "get",
                url: `${CANDISAVEDJOB_URL}`,
                headers: {
                    Authorization: token,
                },
            });
            console.log("response of savedjob", response.data.data);
            if (response.data.data.length > 0) {

                const isIdPresent = response.data.data.some(item => item.id === Number(id));

                // var isIdPresent = response.data.data.every(item => item.id === id);

                console.log('reeeeeeee', isIdPresent);


                if (isIdPresent) {
                    console.log('ID exists in the array.');
                    setIsJobSaved(true)
                    // If you want to get the object with the matching ID
                    // let matchingObject = dataArray.find(item => item.id === targetId);
                    // console.log(matchingObject);
                }
                else {

                    setIsJobSaved(false)
                }

            }
            

        }
        catch (err) {
            console.log("Error fetching data:", err.response.data);
        }
    };

    const fetchData = async () => {

        const token = localStorage.getItem("token");
        try {
            const Response = await axios({
                method: "get",
                url: `${HOMEVIEWPROFILE_URL}` + id,
                headers: {
                    Authorization: token
                }

            })

            console.log("response of job detais", Response.data);
            setCompany(Response.data.newJob.company);
            setState(Response.data.newJob.state);
            setCity(Response.data.newJob.city);
            setExperiences(Response.data.newJob.experience);
            setJobData(Response.data.newJob);
            setCategorylist(Response.data.newJob.new_job_category)
            setskillList(Response.data.newJob.new_job_skill)
            // new_job_category
            // setCategorylist


        } catch (err) {
            console.log('Error view profile:', err.response.data);
        }
    };




    const fetchData1 = async (type) => {
        const token = localStorage.getItem("token");

        try {
            const Response = await axios({
                method: "post",
                url: `${JOBSAVED_URL}` + id,
                headers: {
                    Authorization: token,

                },
            });


            console.log(Response.data);
            if (type == 'add') {
                setIsJobSaved(true)
                Swal.fire({
                    icon: 'success',
                    title: 'Job Saved Successfully',
                    showConfirmButton: false,
                    timer: 1500, // Close after 1.5 seconds
                });
            }
            else {
                setIsJobSaved(false)

                Swal.fire({
                    icon: 'success',
                    title: 'Job Saved Removed Successfully',
                    showConfirmButton: false,
                    timer: 1500, // Close after 1.5 seconds
                });

            }
        } catch (err) {
            console.log('Error message:', err.response.data);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: err.response.data, // Display the error message from the server
            });
        }
    };

    const ApplyJobs = async () => {
        const token = localStorage.getItem("token");

        try {
            const Response = await axios({
                method: "post",
                url: `${POSTJOB_URL}`,
                headers: {
                    Authorization: token,

                },
                data: {

                    "new_job_id": id

                }
            });

            fetchData()
            console.log(Response.data);

            Swal.fire({
                icon: 'success',
                title: 'Job Applied Successfully',
                showConfirmButton: false,
                timer: 1500, // Close after 1.5 seconds
            });

        } catch (err) {
            console.log('Error message:', err.response.data);
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Error',
            //     text: err.response.data, // Display the error message from the server
            // });
        }
    };




    return (

        <div>

            {/* <!-- Header Area Start --> */}
            <CandidateHeader />
            {/* <!-- Header Area End --> */}




            {/* <!-- Single Candidate Start --> */}
            <section className="single-candidate-page section_70">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9 col-lg-6">
                            <div className="single-candidate-box">
                                <div className="single-candidate-img">
                                    <img src={company.logo} alt="single candidate" />
                                </div>
                                <div className="single-candidate-box-right">
                                    <h4>{jobData.tittle}</h4>
                                    {/* <img src="assets/img/pl.svg" alt="region" /> */}
                                    <p>{company.website}</p>
                                    {/* <div className="job-details-meta">
                                        <p> <BsFillFileTextFill color='#25AD60' ></BsFillFileTextFill> Applications 1</p>
                                        <p>   <FaRegCalendarAlt color='#25AD60' ></FaRegCalendarAlt> July 29, 2017</p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-lg-6">
                            {token ? (

                                user_type_name == 'Employee' &&
                                < div className="single-candidate-action">
                                    {isJobSaved == true ?
                                        < Link onClick={() => fetchData1("remove")} className="candidate-unsaved"><i className="fa fa-star"></i>UnSaved Job</Link>
                                        : isJobSaved == false &&

                                        <Link onClick={() => fetchData1("add")} className="candidate-contact1"><i className="fa fa-star"></i>Saved Job</Link>
                                    }
                                    {jobData.application_status == null ?

                                        < Link onClick={() => ApplyJobs()} className="candidate-contact"><i className="fa fa-star"></i>Apply</Link>
                                        :

                                        < Link style={{ borderColor: "#d5c3c1", backgroundColor: "#d5c3c1", color: "black" }} className="candidate-contact"><i className="fa fa-star"></i>{jobData.application_status}</Link>

                                    }

                                </div>
                            )

                                :


                                <div className="single-candidate-action">

                                    < Link to="/login" className="candidate-contact"><i className="fa fa-star"></i>Apply</Link>

                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section >
            {/* <!-- Single Candidate End --> */}


            {/* <!-- Single Candidate Bottom Start --> */}
            <section className="single-candidate-bottom-area section_70">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 col-lg-9 mx-auto">
                            <div className="single-candidate-bottom-left">
                                <div className="single-candidate-widget">

                                    <h3>job Description</h3>
                                    {/* {jobData && ( */}
                                    <p>{jobData.job_description} </p>

                                    {/* )} */}
                                </div>

                                <div className="single-candidate-widget clearfix">
                                    <h3>Personality Requirement</h3>

                                    <p>{jobData.requirement}</p>

                                </div>

                                <div className="single-candidate-widget job-required">
                                    <h3>Skills</h3>
                                    <ul className="company-desc-list">
                                        {skillList.map((e, i) => (

                                            <li>* {e.name}</li>
                                        ))}

                                    </ul>
                                </div>


                            </div>
                        </div>
                        <div className="col-md-10 col-lg-3 mx-auto">
                            <div className="single-candidate-bottom-right">

                                <div className="single-candidate-widget-2">
                                    <h3>Job overview</h3>
                                    <ul className="job-overview">
                                        <li>
                                            <h4> <BsPersonFill className='iconss'></BsPersonFill>    Job Title</h4>
                                            <p>{jobData.tittle} </p>
                                        </li>
                                        <li>
                                            <h4> <MdLocationOn className='iconss'></MdLocationOn> Location</h4>
                                            <p>{city.name}, {state.name}</p>
                                        </li>
                                        <li>
                                            <h4> <FaRegClock className='iconss'></FaRegClock> Working Hours</h4>
                                            <p>{jobData.working_hour}</p>
                                        </li>
                                        <li>
                                            <h4> <FaRegMoneyBillAlt className='iconss'></FaRegMoneyBillAlt> Rate</h4>
                                            <p>$ {jobData.salary}</p>
                                        </li>
                                        <li>
                                            <h4> <HiAcademicCap className='iconss'></HiAcademicCap> Academic degree</h4>
                                            <p>{jobData.academic_degree}</p>
                                        </li>

                                        <li>
                                            <h4> <FaCube className='iconss'></FaCube> Job Category </h4>
                                            {categorylist.map((e, i) => (

                                                <p>{e.name}</p>
                                            ))}
                                        </li>

                                        <li>
                                            <h4> <HiFunnel className='iconss'></HiFunnel> Experience level </h4>
                                            <p>{jobData.experience_level}</p>
                                        </li>

                                        <li>
                                            <h4> <PiClockCounterClockwiseBold className='iconss'></PiClockCounterClockwiseBold> Total year experience </h4>
                                            <p>{experiences.experience}</p>
                                        </li>


                                        <li>
                                            <h4> <FaRegCalendarAlt className='iconss'></FaRegCalendarAlt> Expired Date </h4>
                                            <p>{jobData.expire_date}</p>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Single Candidate Bottom End --> */}









            {/* <!-- Footer Area Start --> */}


            <Footer></Footer>




        </div >

    )




}
export default Candidate_JobDetails;
