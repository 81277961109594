import React, { useEffect, useState } from 'react';
import '../App.css'; // You can import your CSS file here
import { FaGavel, FaLock, FaThumbsUp, FaUserAlt } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
function HomeHeader() {

    const navigate = useNavigate();

    const [loggedInAs, setLoggedInAs] = useState('');

    const token = localStorage.getItem("token");
    // useEffect(() => {
    //     const token = localStorage.getItem("token");
    //     if (token) {

    //         setLoggedInAs(token.includes('Employee') ? 'Employee' : 'Recruiter');
    //     }
    // }, []);
        const Storage = localStorage.getItem("alti_type")


        console.log("Storage",Storage)

    const loogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem("alti_type")

        navigate("/")

        Swal.fire("Success", "Logged Out successfully!!")

        setTimeout(() => {
            Swal.close();

        }, 1500);
    }
    return (
        <div className="App">
            {/* <!-- Header Area Start --> */}
            <header class="jobguru-header-area stick-top forsticky">
                <div class="menu-animation">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-2">
                                <div class="site-logo">
                                    <Link to="/">
                                        <img style={{ width: "112px" }} src={require("../img/altimate_logo.png")} alt="jobguru" class="non-stick-logo" />

                                        <img src={require("../img/logo-2.png")} alt="jobguru" class="stick-logo" />
                                    </Link>
                                </div>
                                {/* <!-- Responsive Menu Start --> */}
                                <div class="jobguru-responsive-menu"></div>
                                {/* <!-- Responsive Menu Start --> */}
                            </div>
                            <div class="col-lg-6 cus_txt">
                                <div class="header-menu">
                                    {/* <nav id="navigation"> */}
                                    <ul id="jobguru_navigation">
                                        {Storage == 'Employee' && (
                                            <li className="active has-children">
                                                <Link to="candidate_dashboard">Candidate</Link>
                                            </li>
                                        )}
                                        {Storage == 'Recruiter' && (
                                            <li className="active has-children">
                                                <Link to="/recruiter/dashboard">Recruiter</Link>
                                            </li>
                                        )}

                                    </ul>
                                    {/* </nav> */}
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="header-right-menu">
                                    <ul>
                                        {!token ? (
                                            <>
                                                <li><Link to="/register"><FaUserAlt className='icons-style'></FaUserAlt> Sign Up</Link></li>
                                                <li><Link to="/login"><FaLock className="tachometer"></FaLock> Login</Link></li>
                                            </>
                                        ) :
                                            // <li><Link onClick={() => loogout()}><FaLock className="tachometer"></FaLock> Logout</Link></li>
                                       null
                                       }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

        </div>
    )
}
export default HomeHeader;
