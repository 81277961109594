import React, { useEffect, useState } from 'react';
import '../../App.css'; // You can import your CSS file here
import { FaGavel, FaTachometerAlt, FaThumbsUp, FaUserAlt, FaUsers } from "react-icons/fa";
import CandidateHeader from '../../Components/candidateheader';
import CandidateSidebar from '../../Components/candidateSidebar';
import Footer from '../../Components/footer';
import { BsEyeFill, BsPencilFill, BsTrash3Fill } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import RecruiterSidebar from '../../Components/recruiterSidebr';
import { EXPERIENCE_URL, JOB_URL, MANAGEJOBS_URL } from '../../Config/Constants';
import axios from 'axios';

import Modal from 'react-bootstrap/Modal';
import { MdDelete } from 'react-icons/md';
import Swal from 'sweetalert2';
import moment from 'moment';

function Recruiter_Applications() {

    const [show, setShow] = useState(false);
    const [ApplicationList, setApplicationlist] = useState(null)
    const [page, setPage] = useState(1);


    const navigate = useNavigate();

    useEffect(() => {

        JobListApi(page);
    }, []);

    const JobListApi = async (value) => {
        const token = localStorage.getItem("token");
        try {
            const response = await axios({
                method: "get",
                url: `${MANAGEJOBS_URL}page=` + value,
                headers: {
                    Authorization: token,
                },
            });

            // setyearExperienceid(response.data.experiences);

            console.log("Response ====>", response.data.appliedList);
            setApplicationlist(response.data.appliedList)

        } catch (error) {
            console.log("Error fetching job:", error.message);
        }
    };
    const JobDeleteApi = async (id) => {


        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Handle the deletion logic here




                const token = localStorage.getItem("token");
                try {
                    const response = await axios({
                        method: "DELETE",
                        url: `${JOB_URL}` + id,
                        headers: {
                            Authorization: token,
                        },
                    });

                    // setyearExperienceid(response.data.experiences);
                    JobListApi(page);
                    Swal.fire({
                        title: "Deleted!",
                        text: "Your file has been deleted.",
                        icon: "success",
                        timer: 2000, // Time in milliseconds
                        showConfirmButton: false,

                    });
                    console.log("Response ====>", response.data);


                } catch (error) {
                    console.log("Error fetching job:", error.message);
                }
            }

        });
    };


    const Pagination = (page) => {
        JobListApi(page)
        setPage(page)
    }


    return (

        <div>

            {/* <!-- Header Area Start --> */}
            <CandidateHeader />
            {/* <!-- Header Area End --> */}


            {/* <!-- Breadcromb Area Start --> */}
            <section class="jobguru-breadcromb-area">
                <div class="breadcromb-top section_100">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="breadcromb-box">
                                    <h3>Applications</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="breadcromb-bottom">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="breadcromb-box-pagin">
                                    <ul>
                                        <li><a href="#">home</a></li>
                                        <li><a href="#">Recruiter</a></li>
                                        <li class="active-breadcromb"><a href="#">Applications</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Breadcromb Area End --> */}


            {/* <!-- Candidate Dashboard Area Start --> */}
            <section class="candidate-dashboard-area section_70">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 col-md-4 dashboard-left-border">
                            <RecruiterSidebar />
                        </div>
                        <div class="col-lg-9 col-md-8">
                            <div class="dashboard-right">
                                <div class="manage-jobs">
                                    <div class="manage-jobs-heading">
                                        <h3>Manage Job</h3>
                                    </div>
                                    <div class="single-manage-jobs table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr style={{ backgroundColor: "aliceblue" }}>
                                                    <th>  Title</th>
                                                    <th>Posted on </th>
                                                    <th>Expiring on </th>
                                                    <th style={{ textAlign: "center" }}>Applications</th>
                                                    <th style={{ textAlign: "center" }}>Status</th>
                                                    <th>action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {ApplicationList?.data.map((e, i) => (
                                                    <tr>
                                                        <td class="manage-jobs-title"><Link to={`/job_details/${e.id}`}>{e.tittle}</Link></td>
                                                        <td class="table-date">  {moment(e.created_at).format('MM-DD-YYYY')}</td>
                                                        <td class="table-date">{e.expire_date}</td>
                                                        <td style={{ textAlign: "center", color: "#1C7EDE" }} class="table-date">
                                                            <div className="application-date">
                                                                <button onClick={() =>
                                                                    navigate("/recruiter/manageapp", {
                                                                        state: {

                                                                            job_id: e.id

                                                                        }
                                                                    })



                                                                } type='button' >
                                                                    <span >   show  {e.job_applications_count}</span>
                                                                </button>
                                                            </div>

                                                            {/* [show  {e.job_applications_count}] */}
                                                        </td>
                                                        <td><span class={e.status == "Rejected" ? "application_reject" : e.status == "Shortlist" ? "application_shortlist" : "application_pending"}>{e.status.name}</span></td>
                                                        <td class="action">

                                                            <Link to={`/recruiter/editjob/${e.id}`} class="action-edit"><BsPencilFill></BsPencilFill></Link>

                                                            <Link onClick={() => JobDeleteApi(e.id)} class="action-edit"><BsTrash3Fill></BsTrash3Fill></Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>


                                        {ApplicationList?.data.length == 0 ?
                                            <tbody className="Not-found">

                                                <td className="jobdesc">No Data Found</td>
                                            </tbody>
                                            :

                                            <div className="pagination-box-row">
                                                <p>Page {ApplicationList?.from} of {ApplicationList?.to}</p>

                                                <ul className="pagination">
                                                    {Array.from({ length: Math.ceil(((ApplicationList?.total) / (10))) }, (_, index) => (
                                                        <li className={Number(ApplicationList?.current_page) == index + 1 ? "active" : ""}>
                                                            <Link
                                                                onClick={() => Pagination(index + 1)}
                                                            >{index + 1}</Link>
                                                        </li>

                                                    ))}
                                                    <li>

                                                    </li>
                                                </ul>
                                            </div>

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            {/* <!-- Candidate Dashboard Area End --> */}


            {/* <!-- Footer Area Start --> */}

            <Footer></Footer>


            {/* <Modal
                show={show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => setShow(false)}
            >
                <Modal.Header className="modal-header" closeButton >
                    <Modal.Title id="contained-modal-title-vcenter">
                        Requirement Notes
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Ipsum molestiae natus adipisci modi eligendi? Debitis amet quae unde
                        commodi aspernatur enim, consectetur. Cumque deleniti temporibus
                        ipsam atque a dolores quisquam quisquam adipisci possimus
                        laboriosam. Quibusdam facilis doloribus debitis! Sit quasi quod
                        accusamus eos quod. Ab quos consequuntur eaque quo rem! Mollitia
                        reiciendis porro quo magni incidunt dolore amet atque facilis ipsum
                        deleniti rem!



                        Ipsum molestiae natus adipisci modi eligendi? Debitis amet quae unde
                        commodi aspernatur enim, consectetur. Cumque deleniti temporibus
                        ipsam atque a dolores quisquam quisquam adipisci possimus
                        laboriosam. Quibusdam facilis doloribus debitis! Sit quasi quod
                        accusamus eos quod. Ab quos consequuntur eaque quo rem! Mollitia
                        reiciendis porro quo magni incidunt dolore amet atque facilis ipsum
                        deleniti rem!




                        Ipsum molestiae natus adipisci modi eligendi? Debitis amet quae unde
                        commodi aspernatur enim, consectetur. Cumque deleniti temporibus
                        ipsam atque a dolores quisquam quisquam adipisci possimus
                        laboriosam. Quibusdam facilis doloribus debitis! Sit quasi quod
                        accusamus eos quod. Ab quos consequuntur eaque quo rem! Mollitia
                        reiciendis porro quo magni incidunt dolore amet atque facilis ipsum
                        deleniti rem!
                    </p>
                </Modal.Body>
            </Modal> */}





        </div >

    )




}
export default Recruiter_Applications;
