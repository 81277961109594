import React, { useEffect } from 'react';
import '../../App.css';
import { FaGavel, FaTachometerAlt, FaThumbsUp, FaUserAlt, FaUsers } from "react-icons/fa";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import CandidateHeader from '../../Components/candidateheader';
import CandidateSidebar from '../../Components/candidateSidebar';
import Footer from '../../Components/footer';
import { useState } from 'react';
import axios from 'axios';
import { Link, useHistory, useNavigate } from 'react-router-dom';
import { Alert } from '@mui/material';
import Swal from 'sweetalert2';
import { LOGIN_URL, TERMS_URL } from '../../Config/Constants';

// import { Link } from 'react-router-dom';
// 
// const history = useHistory();



function TermsAndConditions() {


    const navigate = useNavigate();



    useEffect(() => {

        get_terms()


    }, [])

    const [terms, setTerms] = useState("")

    const get_terms = async (e) => {



        try {
            const Response = await axios({
                method: "get",
                url: `${TERMS_URL}`,

            })

            console.log("Response", Response.data.TermsofService)


            setTerms(Response.data.TermsofService)




        }

        catch (err) {


            console.log("errr====>", err.response.data)


        }

    }





    return (

        <div>


            {/* <!-- Header Area Start --> */}
            <CandidateHeader />
            {/* <!-- Header Area End --> */}


            {/* <!-- Breadcromb Area Start --> */}
            <section className="jobguru-breadcromb-area">
                <div className="breadcromb-top section_100">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="breadcromb-box">
                                    <h3>Terms of Service</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="breadcromb-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="breadcromb-box-pagin">
                                    <ul>
                                        <li><Link to="/">home</Link></li>
                                        {/* <li><a href="#">pages</a></li> */}
                                        <li className="active-breadcromb"><Link >Terms of Service</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Breadcromb Area End --> */}


            {/* <!-- Login Area Start --> */}
            <section className="jobguru-login-area section_70">
                <div dangerouslySetInnerHTML={{__html:terms.page_content}}  className="container breadcromb-bottom">

                    {/* {terms.page_content} */}

                </div>

               
            </section>


            {/* <!-- Footer Area Start --> */}
            <Footer></Footer>






        </div>

    )




}
export default TermsAndConditions;
