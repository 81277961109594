import React, { useEffect, useState } from "react";
import "../../App.css"; // You can import your CSS file here
import {
  FaGavel,
  FaTachometerAlt,
  FaThumbsUp,
  FaUserAlt,
  FaUsers,
} from "react-icons/fa";
import CandidateHeader from "../../Components/candidateheader";
import CandidateSidebar from "../../Components/candidateSidebar";
import Footer from "../../Components/footer";
import RecruiterSidebar from "../../Components/recruiterSidebr";
import { COMPANYPROFILE_URL, STATE_URL } from "../../Config/Constants";
import axios from "axios";
import Swal from "sweetalert2";
function Recruiter_Profile() {
  // const token = localStorage.getItem("token");

  const [error, setError] = useState({});

  const [onImageUpload, setonImageUpload] = useState("");

  // const [formData, setFormData] = useState({
  //   state_id: "",
  //   city_id: "",
  // });

  const [states, setStates] = useState([]);
  const [stateid, setStatesid] = useState("");

  const [cities, setCities] = useState([]);
  const [citieid, setCitieid] = useState("");
  const [formData, setFormData] = useState({
    state_id: "",
    city_id: "",
  });

  const [errorMessages, setErrorMessages] = useState({
    state_id: "",
    city_id: "",
  });

  const handleInputChange6 = (key, e) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  useEffect(() => {
    ComprofileGet();
  }, []);

  const ComprofileGet = async () => {
    const token = localStorage.getItem("token");

    try {
      const Response = await axios({
        method: "get",
        url: `${COMPANYPROFILE_URL}`,
        headers: {
          Authorization: token,
        },
      });

      console.log("requirements====>", Response.data);
      stateData();
      if (Object.keys(Response.data).length > 0) {
        setComProfile(Response.data);

        if (Response.data.state_id) {
          setFormData((prevData) => ({
            ...prevData,
            ["state_id"]: Response.data.state_id,
          }));

          setStatesid(Response.data.state_id)
          stateData(Response.data.state_id);

        }

        if (Response.data.city_id) {

          // setFormData((prevData) => ({
          //   ...prevData,
          //   ["city_id"]: Response.data.city_id,
          // }));
          console.log("Response.data.city_id",Response.data.city_id)
          setCitieid(Response.data.city_id)
          // setFormData(Response.data.state_id);
        }

      }
    } catch (err) {
      console.log("errr====>", err);
    }
  };


  const stateData = async (id) => {


    try {
      // Fetch states data
      const response = await axios({
        method: "get",
        url: `${STATE_URL}`,
      });

      setStates(response.data.states);
      console.log("response.data.states", response.data)
      // Set default state
      setStatesid(response.data.states[0].id);
      if (id) {
        // Fetch cities data based on the default state
        const citiesResponse = await axios({
          method: "get",
          url: ` https://api.altimatixjobs.ifinitesolutions.com/api/states/${id}/cities`,
        });
        setCities(citiesResponse.data);
      }
      // Set default city
      // setCitieid(citiesResponse.data[0].id);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  const handleInputChange1 = async (field, event) => {
    const value = event.target.value;

    console.log("caaa", value);
    setFormData((prevData) => ({ ...prevData, [field]: value }));
    setErrorMessages((prevErrors) => ({ ...prevErrors, [field]: "" }));

    if (field === "state_id") {
      setStatesid(value);

      try {
        // Fetch cities data based on selected state
        const response = await axios({
          method: "get",
          url: ` https://api.altimatixjobs.ifinitesolutions.com/api/states/${value}/cities`,
        });
        setCities(response.data);
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    } else {
      setCitieid(value);
    }
  };

  const [comProfile, setComProfile] = useState({
    name: "",
    about: "",
    website: "",
    logo: "",
    facebook: "",
    googlepuls: "",
    linkedin: "",
    state_id: "",
    city_id: "",
  });

  const handleInputChange = (key, e) => {
    const value = e.target.value;
    setComProfile((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleImageChange = (event) => {
    const image = event.target.files[0];

    console.log("image", image);
    setonImageUpload(image);
  };

  const handleSubmit = async (e) => {
    const token = localStorage.getItem("token");

    const formData = new FormData();

    console.log("stateid----", stateid);
    console.log("website", comProfile.website);
    console.log("image", onImageUpload);
    console.log("googlepuls", comProfile.googlepuls);
    console.log("linkedin", comProfile.linkedin);
    console.log("about", comProfile.about);
    console.log("citieid", comProfile.city_id);
    // console.log("instagram", comProfile.instagram);
    console.log("facebook", comProfile.facebook);

    formData.append("website", comProfile.website);
    formData.append("logo", onImageUpload);
    formData.append("state_id", stateid);
    formData.append("googlepuls", comProfile.googlepuls);
    formData.append("linkedin", comProfile.linkedin);
    formData.append("about", comProfile.about);
    formData.append("city_id", citieid);
    formData.append("name", comProfile.name);
    formData.append("facebook", comProfile.facebook);
    // formData.append("instagram", comProfile.instagram);

    // formData.append("twitter", comProfile.twitter);
    formData.append("_method", "PUT");

    try {
      const Response = await axios({
        method: "post",
        url: `${COMPANYPROFILE_URL}`,
        headers: {
          Authorization: token,
          "content-type": "multipart/form-data",
        },
        data: formData,
      });

      console.log("Recruiter Detail Updated successfully", Response.data);
      setError({})
      Swal.fire("Success", "Updated successfully!!");

      setTimeout(() => {
        Swal.close();
      }, 1500);

      ComprofileGet();
    } catch (err) {
      console.log("Updated Failed!", err.response.data);
      
      setError(err.response.data.errors);
    }
  };
  // console.log("output", comProfile);

  return (
    <div>
      {/* <!-- Header Area Start --> */}
      <CandidateHeader />
      {/* <!-- Header Area End --> */}

      {/* <!-- Breadcromb Area Start --> */}
      <section class="jobguru-breadcromb-area">
        <div class="breadcromb-top section_100">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="breadcromb-box">
                  <h3>Company Profile</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="breadcromb-bottom">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="breadcromb-box-pagin">
                  <ul>
                    <li>
                      <a href="#">home</a>
                    </li>
                    <li>
                      <a href="#">Recruiter</a>
                    </li>
                    <li class="active-breadcromb">
                      <a href="#">Company Profile</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Breadcromb Area End --> */}

      {/* <!-- Candidate Dashboard Area Start --> */}
      <section class="candidate-dashboard-area section_70">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-4 dashboard-left-border">
              <RecruiterSidebar />
            </div>
            <div class="col-lg-9 col-md-8">
              <div class="dashboard-right">
                <div class="candidate-profile">
                  <div class="candidate-single-profile-info">
                    <div class="single-resume-feild resume-avatar">
                      <div className="resume-image1">

                        <img
                          className=""
                          src={
                            onImageUpload
                              ? URL.createObjectURL(onImageUpload)
                              : comProfile.logo
                          }
                        />

                        <div className="resume-avatar-hovers">
                          <div className="resume-avatar-uploads">
                            <p>Edit</p>
                            <input
                              onChange={(e) => handleImageChange(e)}
                              type="file"
                              accept="logo/*"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <span style={{ color: "red", fontSize: 13 }}>
                        {error.logo}
                      </span>
                    </div>
                  </div>

                  <div class="candidate-single-profile-info">
                    <form>
                      <div class="resume-box">
                        <h3>Company Profile</h3>
                        <div class="single-resume-feild feild-flex-2">
                          <div class="single-input">
                            <div style={{ display: "flex" }}>
                              <label>Company Name</label>
                              <h4 className='star-button'>*</h4>
                            </div>
                            <input
                              type="text"
                              name="name"
                              value={comProfile.name}
                              onChange={(e) => handleInputChange("name", e)}
                            />

                            <span style={{ color: "red", fontSize: 13 }}>
                              {error.name}
                            </span>
                          </div>
                          <div class="single-input">
                            <div style={{ display: "flex" }}>
                              <label for="p_title">Company Website</label>
                              <h4 className='star-button'>*</h4>
                            </div>
                            <input
                              type="text"
                              name="website"
                              placeholder="eg:www.google.co.in"
                              value={comProfile.website}
                              onChange={(e) => handleInputChange("website", e)}
                            />

                            {/* <p>eg:www.google.co.in</p> */}
                            <span style={{ color: "red", fontSize: 13 }}>
                              {error.website}
                            </span>
                          </div>
                        </div>

                        <div class="single-resume-feild ">
                          <div class="single-input">
                            <div style={{ display: "flex" }}>
                              <label for="Bio">About Company</label>
                              <h4 className='star-button'>*</h4>
                            </div>
                            <textarea
                              name="about"
                              value={comProfile.about}
                              onChange={(e) => handleInputChange("about", e)}
                              placeholder="Write Your Bio Here..."
                            ></textarea>
                            <span style={{ color: "red", fontSize: 13 }}>
                              {error.about}
                            </span>
                          </div>
                        </div>

                        {/* <div class="single-resume-feild feild-flex-2">
                                                    <div class="single-input">
                                                        <label for="Region">Languages:</label>
                                                        <select id="Region">
                                                            <option selected>English</option>
                                                            <option>French</option>
                                                            <option>Bangla</option>
                                                        </select>
                                                    </div>
                                                    <div class="single-input">
                                                        <label for="Age">Age:</label>
                                                        <input type="text" value="25" id="Age" />
                                                    </div>
                                                </div> */}
                        <div class="single-resume-feild feild-flex-2">
                          <div class="single-input">
                            <div style={{ display: "flex" }}>
                              <label for="Region">Company Location</label>
                              <h4 className='star-button'>*</h4>
                            </div>
                            <select
                              class="city-style1"
                              name="state"
                              value={stateid}
                              onChange={(e) =>
                                handleInputChange1("state_id", e)
                              }
                            >
                              <option
                                value=""
                                className="state-dropdown"
                              >
                                -Select State-
                              </option>

                              {states.map((state) => (
                                <option key={state.id} value={state.id}>
                                  {state.name}
                                </option>
                              ))}
                            </select>
                            {error.state_id && (
                              <div className="error-message">
                                {error.state_id}
                              </div>
                            )}
                          </div>
                          <div class="single-input">
                            <div style={{ display: "flex" }}>
                              <label for="Region">City</label>
                              <h4 className='star-button'>*</h4>
                            </div>
                            <select
                              class="city-style1"
                              name="city"
                              value={citieid}
                              onChange={(e) => handleInputChange1("city_id", e)}
                            >
                              <option
                                value=""
                                className="state-dropdown"
                              >
                                -Select City-
                              </option>

                              {cities.map((city) => (
                                <option key={city.id} value={city.id}>
                                  {city.name}
                                </option>
                              ))}
                            </select>
                            {error.city_id && (
                              <div className="error-message">
                                {error.city_id}
                              </div>
                            )}
                          </div>
                        </div>
                        {/* <div class="single-resume-feild ">
                                                    <div class="single-input">
                                                        <label for="Bio">Introduce Yourself:</label>
                                                        <textarea id="Bio">Maecenas is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</textarea>
                                                    </div>
                                                </div> */}
                      </div>
                      {/* <div class="resume-box"> */}
                      {/* <h3>Contact Information</h3> */}

                      {/* </div> */}
                      <div class="resume-box">
                        <h3>social link</h3>
                        <div class="single-resume-feild feild-flex-2">
                          <div class="single-input">
                            <label>
                              <i class="fa fa-facebook facebook"></i>
                              facebook
                            </label>
                            <input
                              type="text"
                              name="facebook"
                              value={comProfile.facebook}
                              onChange={(e) => handleInputChange("facebook", e)}
                            />
                            <span style={{ color: "red", fontSize: 13 }}>
                              {error.facebook}
                            </span>
                          </div>
                          <div class="single-input">
                            <label>
                              <i class="fa fa-twitter twitter"></i>
                              Google Plus
                            </label>
                            <input
                              type="text"
                              name="googlepuls"
                              value={comProfile.googlepuls}
                              onChange={(e) =>
                                handleInputChange("googlepuls", e)
                              }
                            />
                            <span style={{ color: "red", fontSize: 13 }}>
                              {error.googlepuls}
                            </span>
                          </div>
                        </div>
                        {/* <div class="single-resume-feild feild-flex-2"> */}

                        <div class="single-inputs">
                          <label className="single-input-label">
                            <i class="fa fa-linkedin linkedin"></i>
                            linkedin
                          </label>
                          <input
                            className="single-input-linkin"
                            type="text"
                            name="linkedin"
                            value={comProfile.linkedin}
                            onChange={(e) => handleInputChange("linkedin", e)}
                          />
                          <span style={{ color: "red", fontSize: 13 }}>
                            {error.linkedin}
                          </span>
                        </div>
                      </div>
                      {/* </div> */}
                    </form>
                    <div class="submit-resume">
                      <button onClick={() => handleSubmit()}>Update</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Candidate Dashboard Area End --> */}

      {/* <!-- Footer Area Start --> */}

      <Footer></Footer>
    </div>
  );
}
export default Recruiter_Profile;
