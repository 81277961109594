import React, { useEffect, useState } from 'react';
import '../../App.css'; // You can import your CSS file here
import { FaGavel, FaMapMarkerAlt, FaSearch, FaTachometerAlt, FaThumbsUp, FaUserAlt, FaUsers } from "react-icons/fa";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import CandidateHeader from '../../Components/candidateheader';
import CandidateSidebar from '../../Components/candidateSidebar';
import Slider from 'react-slider';
import Footer from '../../Components/footer';
import { MdOutlineCategory } from 'react-icons/md';
import { IoMdCloudDownload } from "react-icons/io";
import { Link } from 'react-router-dom';
import RecruiterSidebar from '../../Components/recruiterSidebr';
import { SEARCHJOBLIST_URL, SEARCH_CANDI_URL, SEARCH_CANDI_URL1, SEARCH_CAN_URL, STATE_URL } from '../../Config/Constants';
import axios from 'axios';
function Recruiter_SearchJobs() {

    const [values, setValues] = useState([0, 100]);

    const handleChange = (newValues) => {
        setValues(newValues);
    };
    const [experience, setExperience] = useState([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,"Above 15"]);
    const [skill, setSkilll] = useState([]);
    const [Statelist, setStatelist] = useState([]);

    const [city, setCity] = useState([]);

    const [searchresult, setSearchresult] = useState(null);
    const [page, setPage] = useState(1);

    const [searchvalues, setSearchvalues] = useState({
        state_id: "",
        city_id: "",
        skill_id: "",
        experience_id: ""

    });


    useEffect(() => {
        searchJoblist();
        StatelistApi();
        handlesubmit(page)
    }, []);
    const StatelistApi = async () => {
        try {
            const Response = await axios({
                method: "get",
                url: `${STATE_URL}`,
            });

            console.log("states", Response.data.states);

            setStatelist(Response.data.states);

        } catch (err) {
            console.log("Error fetching data:", err.response.data);
        }
    };


    const fetchCities = async (stateid) => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios({
                method: "GET",
                url: ` https://api.altimatixjobs.ifinitesolutions.com/api/states/${stateid}/cities`,
                headers: {
                    Authorization: token,
                },
            });

            setCity(response.data); // Set the fetched cities
        } catch (error) {
            console.log("Error fetching cities:", error.response.data);
        }
    };
    const searchJoblist = async () => {
        const token = localStorage.getItem("token");

        try {
            const response = await axios({
                method: "GET",
                url: `${SEARCH_CAN_URL}`,
                headers: {
                    Authorization: token,
                },
            });

            // console.log("response of states success", response.data.states);
            // console.log("response of cities success", response.data.cities);
            // console.log("response of categories success", response.data.categories);
            // console.log("response of jobtypes success", response.data.jobtypes);
            // console.log("response of skills success", response.data.skills);

            // setStatee(response.data.states);
            // setCityy(response.data.cities);
            // setExperience(response.data.experiences);

            setSkilll(response.data.skills);

            // Set initial cities based on the first state (you can adjust this based on your requirements)
            // setCityy(response.data.cities[0].cities);
        } catch (error) {
            console.log("Error message access in dropdown:", error.response);
        }
    };

    const handlesubmit = async (value) => {
        const token = localStorage.getItem("token");

        console.log("searchvalues", searchvalues.experience_id);

        try {
            const response = await axios({
                method: "GET",
                // url: `${SEARCH_URL}` + "search=" + searchlist,
                url:
                    `${SEARCH_CANDI_URL1}` +
                    "?filters[state.id]=" +
                    searchvalues.state_id +
                    "&filters[city.id]=" +
                    searchvalues.city_id +
                    "&filters[total_year_experience]=" +
                    searchvalues.experience_id +
                    "&filters[skills.id]=" +
                    searchvalues.skill_id +
                    "&page=" + value
                ,
                headers: {
                    Authorization: token,
                },
            });

            console.log("response of search title", response.data);
            setSearchresult(response.data);
        } catch (err) {
            console.log("error meassage of tittle", err.response.data);
        }
    };


    const onchangeFunc = (type, value) => {

        setSearchvalues((prevData) => ({
            ...prevData,
            [type]: value,
        }));

        if (type == "state_id") {
            fetchCities(value)
        }

    }

    const Pagination = (page) => {
        handlesubmit(page)
        setPage(page)
    }

    return (

        <div>

            {/* <!-- Header Area Start --> */}
            <CandidateHeader />
            {/* <!-- Header Area End --> */}


            {/* <!-- Breadcromb Area Start --> */}
            <section class="jobguru-breadcromb-area">
                <div class="breadcromb-top section_100">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="breadcromb-box">
                                    <h3>Search Candidates</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="breadcromb-bottom">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="breadcromb-box-pagin">
                                    <ul>
                                        <li><a href="#">home</a></li>
                                        <li><a href="#">Recruiter</a></li>
                                        <li class="active-breadcromb"><a href="#">Search Candidates</a></li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Breadcromb Area End --> */}


            {/* <!-- Candidate Dashboard Area Start --> */}
            <section class="candidate-dashboard-area section_70">


                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 col-md-4 dashboard-left-border">
                            <RecruiterSidebar />
                        </div>
                        <div class="col-lg-9 col-md-8">
                            <div class="job-grid-right">
                                <div class="browse-job-head-option">

                                    <div className='row'>
                                        <div class="single-input col-sm-3 pdtop">

                                            <select
                                                id="Region"
                                                value={searchvalues.state_id}
                                                onChange={(e) => onchangeFunc("state_id", e.target.value)}
                                            >
                                                <option value=""> - Select State -</option>
                                                {Statelist.map((e) => (
                                                    <option value={e.id}>{e.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div class="single-input col-sm-3 pdtop customwith">
                                            <select id="Region"
                                                value={searchvalues.city_id}
                                                onChange={(e) => onchangeFunc("city_id", e.target.value)}

                                            >
                                                <option value=""> - Select City -</option>
                                                {city.map((e) => (
                                                    <option value={e.id}>{e.name}</option>
                                                ))}
                                            </select>
                                        </div>

                                        <div class="single-input col-sm-3 pdtop">

                                            <select id="Region"
                                                value={searchvalues.experience_id}
                                                onChange={(e) => onchangeFunc("experience_id", e.target.value)}
                                            >
                                                <option value="">- Experience -</option>
                                                {/* <option value="Chennai">Chennai</option>
                                                <option value="Coimbatore">Coimbatore</option> */}
                                                {experience.map((e) => (
                                                    <option value={e}>{e}</option>
                                                ))}

                                            </select>
                                        </div>
                                        <div class="single-input col-sm-2 pdtop">

                                            <select id="Region"
                                                value={searchvalues.skill_id}
                                                onChange={(e) => onchangeFunc("skill_id", e.target.value)}
                                            >
                                                <option value="">- Skill -</option>
                                                {skill.map((e) => (
                                                    <option value={e.id}>{e.name}</option>
                                                ))}
                                            </select>
                                        </div>


                                        <div class="single-input col-sm-1 pdtop">
                                            <button className='search-btn-rec' onClick={() => handlesubmit(page)} >

                                                {/* <FaSearch /> */}
                                                Go
                                            </button>
                                        </div>

                                        {/* <div style={{ display: "flex", justifyContent: "end" }} className='col pdtop'>
                                            <button className='search-btn' >
                                                Go
                                            </button>
                                      
                                        </div> */}
                                    </div>



                                    {/* <div class="job-browse-search">
                                        <form>
                                            <input type="search" placeholder="Search Jobs Here..." />
                                            <button type="submit"><FaSearch /> </button>
                                        </form>
                                    </div>
                                    <div class="job-browse-action">
                                        <div class="email-alerts">
                                            <input type="checkbox" class="styled" id="b_1" />
                                            <label class="styled" for="b_1">email alerts for this search</label>
                                        </div>
                                        <div class="dropdown">
                                            <button class="btn-dropdown dropdown-toggle" type="button" id="dropdowncur" data-bs-toggle="dropdown" aria-haspopup="true">Sort By</button>
                                            <ul class="dropdown-menu" aria-labelledby="dropdowncur">
                                                <li>Newest</li>
                                                <li>Oldest</li>
                                                <li>Random</li>
                                            </ul>
                                        </div>
                                    </div> */}
                                </div>
                                {/* <!-- end job head --> */}
                                <div class="job-sidebar-list-single">

                                    {searchresult?.data.map((e, i) => (
                                        <div class="sidebar-list-single">
                                            <div class="top-company-list">
                                                <div class="company-list-logo">
                                                    <a href="#">
                                                        {e.image ?
                                                            <img src={e.image} />
                                                            :
                                                            <img src={require("../../img/noimage.jpg")} />
                                                        }
                                                    </a>
                                                </div>
                                                <div class="company-list-details">
                                                    <h3><a href="#">{e.user_name}</a></h3>
                                                    <p class="company-state" >< FaMapMarkerAlt className='map-icon'></FaMapMarkerAlt> {e.state.name}</p>
                                                    {e.categories.length > 0 ?
                                                        <p class="open-icon"><MdOutlineCategory className='clock-icon' ></MdOutlineCategory>{
                                                            e.categories.map((e, i) => (

                                                                e.name + ","
                                                            ))


                                                        }</p>
                                                        : null}

                                                    <p class="varify"><IoMdCloudDownload className='varify-icon'></IoMdCloudDownload>Resume</p>

                                                </div>
                                                <div class="company-list-btn">
                                                    <Link to={`/recruiter_candidatedetails/${e.id}`} class="jobguru-btn1">View More</Link>

                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    }

                                </div>

                                {searchresult?.data.length == 0 ?
                                    <tbody className="Not-found">

                                        <td className="jobdesc">No Data Found</td>
                                    </tbody>
                                    :

                                    <div className="pagination-box-row">
                                        <p>Page {searchresult?.from} of {searchresult?.to}</p>

                                        <ul className="pagination">
                                            {Array.from({ length: Math.ceil(((searchresult?.total) / (10))) }, (_, index) => (
                                                <li className={Number(searchresult?.current_page) == index + 1 ? "active" : ""}>
                                                    <Link
                                                        onClick={() => Pagination(index + 1)}
                                                    >{index + 1}</Link>
                                                </li>

                                            ))}
                                            <li>

                                            </li>
                                        </ul>
                                    </div>

                                }

                            </div>
                        </div>
                    </div>
                </div>



            </section>
            {/* <!-- Candidate Dashboard Area End --> */}


            {/* <!-- Footer Area Start --> */}


            <Footer></Footer>






        </div>

    )




}
export default Recruiter_SearchJobs;
