import React, { useEffect, useState } from 'react';

import './App.css'; // You can import your CSS file here
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import Home from './Pages/Home';
import Candidate_Dashboard from './Pages/Candidate/dashboard';
import Candidate_Profile from './Pages/Candidate/profile';
import Candidate_UpResume from './Pages/Candidate/uploadresume';
import Candidate_Applications from './Pages/Candidate/applications';
import Candidate_ChangePassword from './Pages/Candidate/changepassword';
import Candidate_SearchJobs from './Pages/Candidate/searchjobs';
import Candidate_SavedJobs from './Pages/Candidate/savedjobs';
import Candidate_JobDetails from './Pages/Candidate/job_details';
import Register from './Pages/Register';
import Login from './Pages/Login';
import TermsAndConditions from './Pages/TermsAndConditions';

import { FaAngleDoubleUp } from 'react-icons/fa';
import Recruiter_Dashboard from './Pages/Recruiter/dashboard';
import Recruiter_Profile from './Pages/Recruiter/profile';
import Recruiter_Applications from './Pages/Recruiter/applications';
import Recruiter_PostJobs from './Pages/Recruiter/postjobs';
import Recruiter_SearchJobs from './Pages/Recruiter/searchcandidates';
import Recruiter_ManageApp from './Pages/Recruiter/manageappplications';
import Recruiter_ChangePassword from './Pages/Recruiter/changepassword';
import Candidate_Details from './Pages/Recruiter/candidate_details';
import ForgotPassword from './Pages/Forgot/Forgotpass';
import JobEdit from './Pages/Recruiter/JobEdit';
import Contactus from './Pages/Contactus';
import Policy from './Pages/Policy';
import Aboutus from './Pages/Aboutus';


function App() {

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };



  const token = localStorage.getItem("token");
  return (

    <div>

      <BrowserRouter>




        <Routes>



          {/* <Route  path="/" element={token ? <Home /> : <Login />} /> */}
          <Route path='/' element={<Home />} />

          <Route path='/login' element={token ? <Navigate to="/" /> : <Login />} />
          <Route path='/register' element={<Register />} />


          <Route path='/terms_and_conditions' element={<TermsAndConditions />} />

          <Route path='/contact_us' element={<Contactus />} />
          <Route path='/policy' element={<Policy />} />

          <Route path='/about_us' element={<Aboutus />} />

          <Route path='/Forgot/Forgotpass' element={<ForgotPassword />} />
          <Route path='/candidate_dashboard' element={<Candidate_Dashboard />} />
          <Route path='/candidate_profile' element={<Candidate_Profile />} />
          <Route path='/candidate_upload_resume' element={<Candidate_UpResume />} />
          <Route path='/candidate_applications' element={<Candidate_Applications />} />
          <Route path='/candidate_changepassword' element={<Candidate_ChangePassword />} />

          <Route path='/search_jobs' element={<Candidate_SearchJobs />} />
          <Route path='/candidate_saved_jobs' element={<Candidate_SavedJobs />} />
          <Route path='/job_details/:id' element={<Candidate_JobDetails />} />






          <Route path='/recruiter/dashboard' element={<Recruiter_Dashboard />} />
          <Route path='/recruiter/profile' element={<Recruiter_Profile />} />
          <Route path='/recruiter/applications' element={<Recruiter_Applications />} />
          <Route path='/recruiter/postjobs' element={<Recruiter_PostJobs />} />
          <Route path='/recruiter/editjob/:id' element={<JobEdit />} />


          <Route path='/recruiter/search_candidates' element={<Recruiter_SearchJobs />} />
          <Route path='/recruiter/manageapp' element={<Recruiter_ManageApp />} />

          <Route path='/recruiter_changepassword' element={<Recruiter_ChangePassword />} />
          <Route path='/recruiter_candidatedetails/:id' element={<Candidate_Details />} />



        </Routes>

        {showButton && (
          <div className="fixing" onClick={scrollToTop}>
            <FaAngleDoubleUp></FaAngleDoubleUp>
          </div>
        )}
      </BrowserRouter>


    </div>
  );
}

export default App;


